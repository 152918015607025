import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {
token=localStorage.getItem('token')
userid=localStorage.getItem('userid')
username=localStorage.getItem('username')
role_name=localStorage.getItem('role_name')
role_id=localStorage.getItem('role_id')

  ngOnInit() {
  }

}
