
import { NgForm } from '@angular/forms';


import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { NewentryService } from 'src/app/shared/newentry.service';

import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';

declare let $ :any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class CompanyComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  companyedit:boolean=false
  loading:boolean=false
  term2:string;
  Page:number=1
  dropdownval:boolean=true
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  approvalbtn:boolean=false
  updated_by={
    updated_by:this.global.userid
  }
  approvalloading:boolean=false
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,
     private router: Router,private global: GlobalComponent) { }

  ngOnInit() {
    this.resetform()
    //get company list for table
    this.service.getcompany()
  }
  //reset func for company form
  resetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.companydata = {

      company_id : null,
     flag:null,
     password:null,
      company_name : null,
      email : null,
      contact_person : null,
      license_no: null,
      remark : null,
      phone_number : null,
      address_1 : null,
      address_2 : null,
      address_3 : null,
      created_by : null,
    }
  }
  //cancel and reset func
  onagentcancel(companyform){
this.resetform(companyform)
this.loading=false
this.companyedit=false
this.approvalbtn=false
this.approvalloading=false
  }
   //when click edit icon on the table at that time this func trigger
  //values were populated to forms field from the table
  Populated(det){
    this.companyedit=true
    if(det.approval_flag===true){
      this.approvalbtn=false
    }
    else{
      this.approvalbtn=true
    }
this.service.companydata = Object.assign({},det)
  }
  //insert and update func
  oncompanysubmit(companyform){
this.loading=true
//insert func
if(companyform.value.company_id == null){
  companyform.value.created_by=this.global.userid
  companyform.value.flag=true
  companyform.value.password=companyform.value.password.toLowerCase()
this.service.postcompany(companyform.value).subscribe(res=>{
  var status = res['error_status']
  var message = res['error_message']
  if (status == 1) {
   this.successmessage = message;
     $(this.modal1.nativeElement).modal('show');
    this.resetform(companyform)
    this.loading=false
    this.companyedit=false
    this.service.getcompany()

}
else {

 this.errormessage = message;
   $(this.modal2.nativeElement).modal('show');
  this.loading = false;
}
})
}
//update func
else{
  //set aproval flag and flag value
  if(this.approvalbtn==false){
    companyform.value.approval_flag=true
  }
  else if(this.approvalbtn==true){
    companyform.value.approval_flag=false
  }
  if( companyform.value.flag==true){
    companyform.value.flag=true
  }
  else if(companyform.value.flag==false){
    companyform.value.flag=false
  }
  companyform.value.updated_by=this.global.userid

  //update func
  this.service.putcompany(companyform.value).subscribe(res=>{
    var status = res['error_status']
    var message = res['error_message']
    if (status == 1) {

      this.resetform(companyform)
      this.loading=false
      this.companyedit=false
      this.approvalbtn=false
      this.successmessage = message;
        $(this.modal1.nativeElement).modal('show');
      this.service.getcompany()

  }
  else {
   this.errormessage = message;
     $(this.modal2.nativeElement).modal('show');
    this.loading = false;
  }
  })
}
  }
    //update flag value in company table
  //when click on/off switch in the table at that time this func trigger
  Flagchange(det){
    det.updated_by=this.global.userid
    this.service.putcompany(det).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = message;
       $(this.modal1.nativeElement).modal('show');
        this.resetform(det)

        this.service.getcompany()

    }
    else {
      this.errormessage = message;
        $(this.modal2.nativeElement).modal('show');
      this.loading = false;
    }
    })
  }

  //when click aproval in the table at that time this func trigger
  //in this func we approval the company details
  approval(form){
    this.approvalloading=true
   console.log(this.updated_by,'updated_by')
    this.service.companyapproval(this.updated_by,form.value.company_id).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        this.loading=false
        this.companyedit=false
        this.approvalbtn=false
        this.approvalloading=false
        this.successmessage = message;
        this.resetform(form)
        this.service.getcompany()
          $(this.modal1.nativeElement).modal('show');



       }
       else {
         this.approvalloading=false
        this.errormessage = message;
          $(this.modal2.nativeElement).modal('show');


       }

    })
  }


//master menu list hide and show
dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}


//property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}

//logout click func
//remove values from local storage and redirect to login page
public logout(): void {

  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
