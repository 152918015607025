
import { NgForm } from '@angular/forms';


import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { NewentryService } from 'src/app/shared/newentry.service';

import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';
declare let $ : any

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class LanguageComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  Languageterm2: string;
  languagePage: number = 1
  showedit: boolean = false
  languageedit: boolean = false
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=true
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,
    private router: Router,private global: GlobalComponent) { }

  ngOnInit() {

    this.languageresetForm()
     //get language main list
    this.service.getmultiselectlanguage()
    this.service.getlanguage()

  }
  //reset func for language form
  languageresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.languagedata = {
      language_id: null,
      language_code: null,
      language_name: null,
      native: null,
      created_by: null,

      created_date: null,
      updated_by: null,

      updated_date: null,


      flag: null
    }
  }

  //cancel and reset func
  oncancel(languageform) {
    this.languageresetForm(languageform)
    this.loading2 = false
    this.languageedit = false
  }
  //update flag value in language main list when click the on/off switch in the main list table
  changeLanguagelag(det) {
    det.updated_by=this.global.userid
    this.service.putlanguage(det).subscribe(res => {

      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
       // this.pagesparent.successModal('Update successfully');
       this.successmessage = message;
       //  $(this.modal1.nativeElement).modal('show');
         $(this.modal1.nativeElement).modal('show');

        this.service.getlanguage()
        this.service.getmultiselectlanguage()

      }
      else {
      //  this.pagesparent.errorModal(message);

      this.errormessage = message;
      //  $(this.modal1.nativeElement).modal('show');
        $(this.modal2.nativeElement).modal('show');
      }
    })
  }
  //insert and update func for language master
  languagesubmit(languageform) {
    this.loading2 = true
    console.log(languageform.value, "language")
    //insert func
    if (languageform.value.language_id == null) {
      languageform.value.created_by=this.global.userid
      languageform.value.flag = true
      this.service.postlanguage(languageform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.loading2 = false
          this.service.getlanguage()
          this.service.getmultiselectlanguage()
          this.languageresetForm(languageform)
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
        }
        else {
         // this.pagesparent.errorModal(message);
         this.errormessage = message;
         //  $(this.modal1.nativeElement).modal('show');
           $(this.modal2.nativeElement).modal('show');
          this.loading2 = false
        }
      })
    }
    //update func
    else {
      languageform.value.updated_by=this.global.userid
      if(languageform.value.flag==true){
        languageform.value.flag=true
      }
      else if(languageform.value.flag==false){
        languageform.value.flag=false
      }
      this.service.putlanguage(languageform.value).subscribe(res => {
        this.languageedit = false
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.loading2 = false
          this.service.getlanguage()
          this.service.getmultiselectlanguage()
          this.languageresetForm(languageform)
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading2 = false;
        }
      })
    }
  }
  //when click the edit icon the table at that time this func trigger
  //populate the value of form fields from the main table
  languagePopulated(det) {
    this.languageedit = true
    this.loading2 = false
    this.service.languagedata = Object.assign({}, det)
  }
//master menu list hide and show
dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}

//property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}
//logout func
//remove values from local storage
//redirect to login page
public logout(): void {
  // alert('ddd');
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
