import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timershow',
  templateUrl: './timershow.component.html',
  styleUrls: ['./timershow.component.scss']
})
export class TimershowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
