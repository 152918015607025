
import { NgForm } from '@angular/forms';


import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { NewentryService } from 'src/app/shared/newentry.service';

import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';

declare let $ :any;
@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class CurrencyComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=true
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,
    private router: Router,private global: GlobalComponent) { }

  ngOnInit() {
    this.currencyresetForm()
    //get currency main list
    this.service.getcurrencies()
    this.service.getcurrencydropdown()

  }
  //reset func for currency form
  currencyresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.currencydata = {
      currency_id: null,
      currency_code: null,
      currency_name: null,
      currency_symbol: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,

      flag: null
    }
  }



  toUnicode(x) {
    var result = "";
    for (var i = 0; i < x.length; i++) {
      result += "\\u" + ("000" + x.charCodeAt(0).toString(16)).substr(-4);

    }
    return result;
  };
  //cancel and reset func
  oncurrencycancel(currencyform) {
    this.currencyresetForm(currencyform)
    this.loading1 = false
    this.showedit = false
  }
  //insert and update func
  oncurrecysubmit(currencyform) {
    this.loading1 = true


    console.log(currencyform.value, "currency")
    //insert func
    if (currencyform.value.currency_id == null) {
      currencyform.value.created_by=this.global.userid
      currencyform.value.flag = true
      this.service.postcurrencies(currencyform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.loading1 = false
          this.service.getcurrencies()
          this.service.getcurrencydropdown()
          this.service.getcountrydropdown()
          this.service.getlanguagedropdown()
          this.service.getstatedropdown()
          this.service.getcitydropdown()
              this.service.getcountrydropdown()
          this.currencyresetForm(currencyform)
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading1 = false
        }
      })
    }
    //update func
    else {
      currencyform.value.updated_by=this.global.userid
      if(  currencyform.value.flag==true){
        currencyform.value.flag=true
      }
      else if( currencyform.value.flag==false){
        currencyform.value.flag=false
      }
      this.service.putcurrencies(currencyform.value).subscribe(res => {
        this.showedit = false
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message
           $(this.modal1.nativeElement).modal('show');
          this.loading1 = false
          this.service.getcurrencies()
          this.service.getcurrencydropdown()
          this.service.getcountrydropdown()
          this.service.getlanguagedropdown()
          this.service.getstatedropdown()
          this.service.getcitydropdown()
              this.service.getcountrydropdown()
          this.currencyresetForm(currencyform)
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading1 = false
        }
      })
    }
  }
        //update flag value in company table
  //when click on/off switch in the table at that time this func trigger
  changeCurrencyflag(det) {
   det.updated_by=this.global.userid
    this.service.putcurrencies(det).subscribe(res => {

      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');
        this.service.getcurrencies()
        this.service.getcurrencydropdown()
        this.service.getcurrencydropdown()
        this.service.getcountrydropdown()
        this.service.getlanguagedropdown()
        this.service.getstatedropdown()
        this.service.getcitydropdown()
            this.service.getcountrydropdown()
      }
      else {
        this.errormessage = message;
          $(this.modal2.nativeElement).modal('show');
      }
    })

  }
  //when click edit icon on the table at that time this func trigger
  //values were populated to forms field from the table
  currencyPopulated(det) {

    this.showedit = true
    this.loading1 = false

    this.service.currencydata = Object.assign({}, det)
    console.log(det, "det")
  }
  unicodeToChar(text) {
    return text.replace(/\\u[\dA-F]{4}/gi,
      function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
      });
  }

  //master menu list hide and show
  dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}


//property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}




//logout click func
//remove values from local storage and redirect to login page
public logout(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
