import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CompleterService } from 'ng2-completer';
import { NewentryService } from '../shared/newentry.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GlobalComponent } from '../global/global.component';
declare let $:any

@Component({
  selector: 'app-postproperty',
  templateUrl: './postproperty.component.html',
  styleUrls: ['./postproperty.component.css'],
  providers:[GlobalComponent]
})
export class PostpropertyComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  username
  signup:any
  signin:any
  hidelogin:boolean=false
  propertypostbtnshow:boolean=false
  agentsignup:any
  agent:boolean
  single
  public images: string[] = [];
  file
  image11
  productdata:any
  category_id
  showlogout:boolean=false
  iscoverimg
  mainfile
  mainimage:any[]=[]
  mainthumbimage
  loginbtn:boolean
  phone= localStorage.getItem('phone')
  registershow:boolean
  loading:boolean
  public errormessage;
  public successmessage;
  public warningmessage;
  uploadmainimage;
  uploadsubimage;
  uploadbtn
  delete:boolean
  category_title="RENT"
  size:boolean
  ondate:boolean
  intialCtegory:boolean=true
  imgloading:boolean=false
  img2loading:boolean=false
  todayDate:Date = new Date();
  minDate

  constructor(public service: NewentryService,private router:Router , private http:HttpClient,private global: GlobalComponent,
    private ng2ImgMax: Ng2ImgMaxService,private completerService: CompleterService,private config: NgbDatepickerConfig) {
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
      this.productdata={
        product_id: null,
        product_code: null,
        process_status_id:null,
        approval_flag:null,
        location_id:null,
        product_title: null,
        product_description: null,
        short_description: null,
        category_id: "1" ,
        subcategory_id:null,
        sub_category_id: null,
        availablity_status:null,
        address_1:null,
        address_2: null,
        address_3: null,
        area_id: null,
        city_id: null,
        size_of_property: null,
        unit_id: null,
        price:null,
        company_id: null,
        phone: localStorage.getItem('phone') !== null? this.phone : '',
        product_by: null,
        agent_id: null,
        nearby_location: null,
        facilities: null,
        created_by: null,
        created_date: null,
        updated_by:null,
        updated_date:null,
        flag:null,
        is_deleted:null,
        deleted_by:null,
        deleted_date:null,
        area_name: null,
        city_name: null,
        category_name: null,
        subcategory_name: null,
        unit_name:null,
        agent_name: null,
        company_name: null,
        bedroom_count: null,
        bathroom_count: null,
        property_facing:null,
        remarks:null,
        dwelling_type_id: null,
        pet_friendly:null,
        avilable_date: null,
      }
    }

  ngOnInit() {
    window.scrollTo(0,0);
    console.log( localStorage.getItem('phone'),"phone")
    this.username=localStorage.getItem('username')
    //delete temp record
    this.service.deleteTemp(this.global.userid)
    this.category_title="RENT"
    this.productdata.category_id="1"
    //get dwelling type list
     this.service.getdwellingtype()
     //get subcategory list
     this.service.getsubcategory(1)
     //get area list
    this.service.getarea()

    this.agentsignup={
      user_name:null,
email:null,
password:null,
confirmpassword:null,
role_name:null,
login_users:null,
user_count:null,
agent_company_id:null
    }

    this.signin={
      email:null,
      password:null
     }
    this.resetform()
    this.productresetForm()
    this.signupresetform()

    //show or hide logout btn and login btn condition
    if(localStorage.getItem('username')){
      this.showlogout=true
      this.loginbtn=false
    }
    else{
      this.showlogout=false
      this.loginbtn=true
    }
  }
  //reset func for product form
  productresetForm(form?:NgForm){
    if(form != null)
    form.resetForm()
      this.productdata={
        product_id: null,
        product_code: null,
        process_status_id:null,
        approval_flag:null,
        location_id:null,
        product_title: null,
        product_description: null,
        short_description: null,
        category_id: "1" ,
        subcategory_id:null,
        sub_category_id: null,
        availablity_status:null,
        address_1:null,
        address_2: null,
        address_3: null,
        area_id: null,
        city_id: null,
        size_of_property: null,
        unit_id: null,
        price:null,
        company_id: null,
        phone:  localStorage.getItem('phone') !== "null"? this.phone : '',
        product_by: null,
        agent_id: null,
        nearby_location: null,
        facilities: null,
        created_by: null,
        created_date: null,
        updated_by:null,
        updated_date:null,
        flag:null,
        is_deleted:null,
        deleted_by:null,
        deleted_date:null,
        area_name: null,
        city_name: null,
        category_name: null,
        subcategory_name: null,
        unit_name:null,
        agent_name: null,
        company_name: null,
        bedroom_count: null,
        bathroom_count: null,
        property_facing:null,
        remarks:null,
        dwelling_type_id: null,
        pet_friendly:null,
        avilable_date: null,
      }
}
  resetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.signup={
      user_name:null,
      email:null,
      password:null,
      confirmpassword:null,

      created_by:null
    }
  }
  //reset fun for signup form
  signupresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.signup={
      user_name:null,
      email:null,
      password:null,
      confirmpassword:null,

    }
  }
 //reset func for signin form
  signinresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.signup={
      email:null,
      password:null
    }
  }


  ///register form submit func
  onregistersubmit(registerform){
    this.service.postregister(registerform.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        alert("Register Successful")
     document.getElementById("signupPopupForm").click();
      }
      else {

        alert(message)
      }


    })
  }
  //signin form submit func
  //after successful sigin redirect to home page
  onsigninsubmit(signin){
    this.service.postsignin(signin.value).subscribe((res:any)=>{
      console.log(res,"signindetails")
      var status = res['error_status'];
      var message = res['error_message'];

      if(status == 1){
        localStorage.setItem('token',res.token)
        localStorage.setItem('userid',res.user_id)
        localStorage.setItem('username', res.user_name);
        localStorage.setItem('role_name', res.role_name);
        localStorage.setItem('role_id',res.role_id)
        localStorage.setItem('user_email',res.user_email)
      this.username=localStorage.getItem('username')
     this.signinresetform(signin)
     this.showlogout=true
     this.loginbtn=false
        this.router.navigateByUrl('/home')
        document.getElementById("loginPopupForm").click();
      }
      else{
        alert('Incorrect username or password.');

      }
    })
  }


readFile(file, reader, callback){
    reader.onload = () => {
        callback(reader.result);
    }
    reader.readAsDataURL(file);
}

readFiles(files, index=0){
    let reader = new FileReader();

    if (index in files){
        this.readFile(files[index], reader, (result) =>{
            this.images.push(result);
            this.readFiles(files, index+1);
        });
    }else{

    }
}
///submit func for post property
postsubmit(form){
  this.loading=true

  form.value.bathroom_count =parseInt(form.value.bathroom_count)
  form.value.bedroom_count=parseInt(form.value.bedroom_count)
  form.value.price=parseFloat(form.value.price)
  if(this. intialCtegory== true){
    form.value.category_id="1"
  }
  else{
    form.value.category_id=this.category_id
  }

  form.value.created_by=localStorage.getItem('userid')

  if(localStorage.getItem('role_name')=="agent"){
    form.value.agent_id=localStorage.getItem('userid')
  }
  if(localStorage.getItem('role_name')=="company"){
    form.value.company_id=localStorage.getItem('userid')
  }

 form.value.product_by=localStorage.getItem('role_name')
 form.value.unit_id="7361ABDC-851D-4F97-9BAA-95741C226777"
 form.value.city_id="2C697687-21E4-4A04-9388-53773E0E45F6"
   form.value.avilable_date=this.toDateJs(form.value.avilable_date)
   console.log(form.value.avilable_date,"avilable_date")
  this.service.postproduct(form.value).subscribe(res=>{
    var status = res['error_status']
    var message = res['error_message']
    if (status == 1) {
     this.loading=false
      this.service.getproduct()
      this.productresetForm(form)
      this.successmessage = message;
        $(this.modal1.nativeElement).modal('show');
      this.images.length=0
     this.images.length=0
      this.mainimage.length=0

    }
    else{
     this.errormessage=message
       $(this.modal2.nativeElement).modal('show');
      this.loading=false
    }
  }

)
}

//when sale or rend radio btn click at that time tyhis func trigger
//in this func we get subcategory list by choosen category
category(e){

  if(e==1){
    this.category_id="1"
    this.category_title="RENT"
    this.size=false
    this. intialCtegory=false
    this.service.getsubcategory(this.category_id)
  }
  else{
    this.category_id="2"
    this. intialCtegory=false
    this.category_title="SALE"
    this.size=true
    this.service.getsubcategory(this.category_id)
  }
}




//////////////////////////////////////////main image//////////////////////////////

//file uploaded func
fileChangemain(input){
  this.imgloading=true
  const reader = new FileReader();
  if (input.files.length) {
    this.mainfile = input.files[0];
    reader.onload = () => {

  }
  reader.readAsDataURL(this.mainfile);
    console.log(this.mainfile, "f2")
    //checking file size higher than 4mb
    if (input.files[0].size/1024/1024 > 4) {
      console.log('file is bigger than 4MB');
      //file compress func by using ng2imgmax external js libirary
      this.ng2ImgMax.resizeImage(this.mainfile, 2000, 1000).subscribe(res=>{

        this.uploadmainimage=new File([res], res.name)
   })
     }
     else{
       console.log(input.files[0].size/1024/1024 + ' MB');
       this.uploadmainimage=this.mainfile
     }
    //file compress func by using ng2imgmax external js libirary
    this.ng2ImgMax.resizeImage(this.mainfile, 400, 224).subscribe(
      result => {
        console.log(result,"resultimg")

       this.mainthumbimage =  new File([result], result.name);
        console.log(this.mainthumbimage,"img11")


//set formData
  const formData = new FormData();
  this.iscoverimg=true
  formData.append('file', this.uploadmainimage)
  formData.append('created_by', this.global.userid)
  formData.append('thumbnail_file',this.mainthumbimage)
  formData.append('is_cover_image',this.iscoverimg)
  console.log(formData.get('thumbnail_file'), "formdata")
  console.log(formData.get('file'), "formdata")

  //image uploaded func
  this.service.postimage(formData).subscribe(res => {
    var error_status = res['error_status'];
    var error_message = res['error_message'];
    if (error_status == "1") {
      console.log(res);
      this.uploadbtn=true
      this.imgloading=false

      //get uploaded image list
     this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{

       this.mainimage=res as any[]
     })
    }
    else {
      this.errormessage = error_message;
      this.imgloading=false
       $(this.modal2.nativeElement).modal('show');

    }
  })


      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );


  }
}

//remove image
removemainImage(id){
  this.service.deletesinglemainimage(id).toPromise().then(res=>{
    console.log('delete')
  })
  this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{
   this.mainimage=res as any[]
  })
}

////////////////////////////////////////////sub image//////////////////////

//file uploaded func
fileChange(input){
  this.img2loading=true
  this.readFiles(input.files);
  if (input.files.length) {
      this.file = input.files[0];
      console.log(this.file, "f2")
       //checking file size higher than 4mb
      if (input.files[0].size/1024/1024 > 4) {
        console.log('file is bigger than 4MB');
         //file compress func by using ng2imgmax external js libirary
        this.ng2ImgMax.resizeImage(this.file, 2000, 1000).subscribe(res=>{
          console.log(res,"mainimageresize")
          this.uploadsubimage=new File([res], res.name)
     })
       }
       else{
         console.log(input.files[0].size/1024/1024 + ' MB');
         this.uploadsubimage=this.file
       }
      //file compress func by using ng2imgmax external js libirary
      this.ng2ImgMax.resizeImage(this.file, 400, 224).subscribe(
        result => {
          console.log(result,"resultimg")

         this.image11 =  new File([result], result.name);
          console.log(this.image11,"img11")


    const formData = new FormData();
    formData.append('file', this.uploadsubimage)
    formData.append('created_by', this.global.userid)
    formData.append('thumbnail_file',this.image11)
    console.log(formData.get('thumbnail_file'), "formdata")
    console.log(formData.get('file'), "formdata")

    //image uploaded func
    this.service.postimage(formData).subscribe(res => {
      var error_status = res['error_status'];
      var error_message = res['error_message'];
      if (error_status == "1") {
        console.log(res);
        this.img2loading=false

        //get uploaded  image list
        this.service.getuploadimage(this.global.userid).toPromise().then(res=>{
          console.log(res,"img")
          this.images=res as any[]
        })
      }
      else {
      this.img2loading=false
      this.errormessage = error_message;
        $(this.modal2.nativeElement).modal('show');
      }
    })


        },
        error => {
          console.log('😢 Oh no!', error);
        }
      );


    }
}

//remove image

removeImage(id):void{

  this.service.deletesingle(id).toPromise().then(res=>{
    console.log('delete')
  })
  this.service.getuploadimage(this.global.userid).toPromise().then(res=>{
    this.images=res as any[]
  })
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//convert to UTC date format
toDateJs(date: NgbDateStruct): Date {
  return date ?
      new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
}
//reset func and cancel func
postclear(postform){
  this.loading=false
  this.productresetForm(postform)
  this.images.length=0
  this.imgloading=false
  this.img2loading=false

}
//reset and cancel func and redirect to home page
postcancel(postform){
  this.loading=false
  this.productresetForm(postform)
 this.imgloading=false
 this.img2loading=false
 this.images.length=0
  this.router.navigateByUrl('/home')
}


///when Available Status dropdown value changed at that time this func trigger
//show availability field when select immediate status
changeavailable(e){
  if(e != "immediate"){
  this.ondate=true
  }
  else{
    this.ondate=false
  }
}
    }



