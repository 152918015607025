import { NgForm } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';
import { HttpClient } from '@angular/common/http';
declare let $ :any;

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class SubcategoryComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=false
  dropdownval2:boolean=true
  property:boolean=true
  propertyval2:boolean=true
  onlysubcategoryList :any[]=[]
  constructor(public service: NewentryService,
    private pagesparent: PagesParentComponent,
    private global: GlobalComponent, private http:HttpClient,
    private router: Router) { }

  ngOnInit() {
    this.subcategoryresetForm()
    //get categories list for dropdown
    this.service.getcategories()
    //get all subcategories
    this.http.get(this.service.rootURL+'/subcategories/getallsubcategories').toPromise().then(res=>{
      this.onlysubcategoryList=res as any[]
    })
  }
  //reset func for subcategory
  subcategoryresetForm(form?:NgForm){
    if (form != null)
    form.resetForm()
    this.service.subcategoryData = {
      subcategory_id: null,
      subcategory_code:null,
      subcategory_name:null,
      subcategory_description: null,
      category_id: null,
      category_name:null,
      created_by:null,
      created_date:null,
      updated_by:null,
      updated_date: null,
      flag: null
    }
  }
//cancel and reset func
  subcategorycancel(subcategoryform){
    this.subcategoryresetForm(subcategoryform)
    this.subcategoryedit=false
    this.loading10=false
      }
    //insert and update func
      subcategorysubmit(subcategoryform){
        console.log(subcategoryform.value,"subform")
        this.loading10=true;
       //insert func
        if(subcategoryform.value.subcategory_id === null){
          subcategoryform.value.flag=true
          subcategoryform.value.created_by=this.global.userid
      this.service.postsubcategory(subcategoryform.value).subscribe(res=>{
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          this.successmessage = message;
            $(this.modal1.nativeElement).modal('show');
          this.service.getonlysubcategory()
          this.subcategoryresetForm(subcategoryform)
          this.subcategoryedit=false
          this.loading10=false
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading10=false

        }
      })
        }
        //update func
        else{
          subcategoryform.value.updated_by=this.global.userid
          if(subcategoryform.value.flag==true){
            subcategoryform.value.flag=true
          }
          else if(subcategoryform.value.flag==false){
            subcategoryform.value.flag=false
          }
          this.service.putsubcategory(subcategoryform.value).subscribe(res=>{
            var status = res['error_status']
            var message = res['error_message']
            if (status == 1) {
             this.successmessage = message;
               $(this.modal1.nativeElement).modal('show');
              this.service.getonlysubcategory()
              this.subcategoryresetForm(subcategoryform)
              this.subcategoryedit=false
              this.loading10=false
            }
            else {
             this.errormessage = message;
               $(this.modal2.nativeElement).modal('show');
              this.loading10=false

            }
          })
        }
      }
      //update flag value in main list by using on/off switc in the table
      changesubcategoryflag(det){
        det.updated_by=this.global.userid
        this.service.putsubcategory(det).subscribe(res=>{
          var status = res['error_status']
          var message = res['error_message']
          if (status == 1) {
           this.successmessage = message;
             $(this.modal1.nativeElement).modal('show');
            this.service.getonlysubcategory()
            this.loading10=false
          }
          else {
            this.errormessage = message;
              $(this.modal2.nativeElement).modal('show');
            this.loading10=false

          }
        })
      }
      //when click edit icon on the table at that time this func trigger
  //in this func we populated the value of state form fields from the table list
      subcategoryPopulated(det){
        this.subcategoryedit=true
        this.service.subcategoryData = Object.assign({},det)
      }

  //master menu list hide and show
      dropdown1(){
        this.property=false
        this.propertyval2=true
        this.dropdownval=true
    this.dropdownval2=false
    }
    dropdown2(){
      this.dropdownval=false
      this.dropdownval2=true
    }

  //property menu list hide and show
    property1(){
      console.log("property")
      this.dropdownval=false
      this.property=true
      this.dropdownval2=true
      this.propertyval2=false
    }
    property2(){
      console.log("property")
      this.dropdownval=false
      this.property=false
      this.propertyval2=true
    }
    //logout click func
//remove values from local storage and redirect to login page
public logout(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}


}
