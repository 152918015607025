import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { RoomDetailComponent } from './room-detail/room-detail.component';
import { NewpropertyComponent } from './newproperty/newproperty.component';
import { PostaddComponent } from './postadd/postadd.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2CompleterModule } from "ng2-completer";
import { PostpropertyComponent } from './postproperty/postproperty.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MyNgbDateParserFormatter } from './ngb-date-fr-parser-formatter';
import { PostadvertisementComponent } from './postadvertisement/postadvertisement.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { NewentryService } from './shared/newentry.service';

import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PagesParentComponent } from './pages-parent/pages-parent.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PropertynewComponent } from './propertynew/propertynew.component';
import { CarouselModule } from '../../node_modules/ngx-bootstrap/carousel';
import { GlobalComponent } from './global/global.component';
import { ForgetComponent } from './forget/forget.component';
import { TimershowComponent } from './timershow/timershow.component';
import { TimerComponent } from './timer/timer.component';
import { ListyourplaceComponent } from './listyourplace/listyourplace.component';
import { RedstarPipe } from './redstar.pipe';
import { StrpricePipe } from './strprice.pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { SuperAdminModule } from './super-admin/super-admin.module';
import { MaterialModule } from './material';
import { LoginModule } from './login/login.module';


@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    PropertyListComponent,
    RoomDetailComponent,
    NewpropertyComponent,
    PostaddComponent,
    PostpropertyComponent,
    PostadvertisementComponent,
    MyprofileComponent,
    PagesParentComponent,
    ChangepasswordComponent,
    UserMenuComponent,
    HeaderComponent,
    FooterComponent,
    PropertynewComponent,
    GlobalComponent,
    ForgetComponent,
    TimershowComponent,
    TimerComponent,
    ListyourplaceComponent,
    RedstarPipe,
    StrpricePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    Ng2CompleterModule,
    Ng2ImgMaxModule,
    NgxGalleryModule,
    MaterialModule,
    NgbModule,
    CarouselModule.forRoot(),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    SuperAdminModule,
    MultiselectDropdownModule,
    LoginModule,
    UiSwitchModule.forRoot({
      size: 'medium',
      color: '#287dfa',
      //switchColor: '#80FFA2',
      defaultBgColor: '#dc3545',
      //defaultBoColor : 'red',
      checkedLabel: 'On',
      uncheckedLabel: 'Off'
    }),
    ToastrModule.forRoot(
      {  
        positionClass: 'toast-top-right',
        preventDuplicates: true,
          closeButton:true
      }  
    ),
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
     AuthGuard,
    PagesParentComponent, {provide: NgbDateParserFormatter, useFactory: () => new MyNgbDateParserFormatter('dd/MM/yyyy')},NewentryService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
