import { Component, OnInit, ɵConsole, NgZone, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NewentryService } from '../shared/newentry.service';
import { HttpClient } from '@angular/common/http';
import { CompleterService } from 'ng2-completer';
import { NgForm } from '@angular/forms';
import 'src/assets/js/main.js';
import { Product } from '../shared/product.model';
import { Subcategory } from '../shared/subcategory.model';
import { NgxSpinnerService } from "ngx-spinner";

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

declare let $: any
@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.css'],
  providers:[NgxSpinnerService]

})
export class PropertyListComponent implements OnInit {
  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

  locationvalue
  category_id
  username: any
  //auto complete list
  List: any[]

  searchstr
  value
  min
  max
  nameFormArray: any[] =[]
  rentbyarray: any[] = []
  namebedroomArray: any[] = []
  namebathroomArray: any[] = []
  pricefilter
  page_size = 10
  rangeSliderAmountTwo = $('#amount2');
  rentby: any[] = [
    { name: "agent" }, { name: "owner" }
  ]
  bedroom: any[] = [
    { name: "1", name2: "bedroom" }, { name: "2", name2: "bedrooms" }, { name: "3", name2: "bedrooms" }, { name: "4", name2: "bedrooms" }, { name: "5", name2: "bedrooms" },
  ]
  bathroom: any[] = [
    { name: "1", name2: "bathroom" }, { name: "2", name2: "bathrooms" }, { name: "3", name2: "bathrooms" }, { name: "4", name2: "bathrooms" }, { name: "5", name2: "bathrooms" },
  ]
  single: boolean = false
  searchform

  str
  agentsignup: any
  agent: boolean
  dataService
  signup: any
  signin: any
  hidelogin: boolean = true
  propertypostbtnshow: boolean = false
  showlogout: boolean = false
  loginbtn: boolean
  result_count
  registershow: boolean
  data
  ipAddress: string;
  loadAPI
  routerSubscription: any;
  url = "/assets/js/price-slider.js";
  url2 = "/assets/js/select-menu.js";
  location
  input: boolean = true
  radio: boolean = true
  category: boolean
  subcategoryList
  subcategory
  productdata
  productdata2
  sub_category_id
  onlysubcategoryList
  categorysale: boolean
  spin: boolean = false
  sorting
  lid;
  catid;
  subid;
  locationtest
  subcategoryListForRent
  subcategoryListForSale
  rent:boolean
  sale:boolean
  intialSelect:boolean=false
  homeloader:boolean

  constructor(public service: NewentryService, private router: Router, private http: HttpClient,
    private zone: NgZone, private completerService: CompleterService, private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {
       //get subcategory list for rent
      this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+1).toPromise().then(res=>{
        console.log(res,"subc")
        this.subcategoryListForRent=res as any[]
        this.loadScript2()
      })
      //get subcategory list for sale
      this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+2).toPromise().then(res=>{
        console.log(res,"subc")
        this.subcategoryListForSale=res as any[]
        this.loadScript2()
      })

      this.productdata2 = {
        location_id: localStorage.getItem('location'),
        category_id: null,
        sub_category_id: null,
        subcategory_id: null,

      }

    this.signin = {
      email: null,
      password: null
    }

    this.agentsignup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,
      role_name: null,
      login_users: null,
      user_count: null,
      agent_company_id: null
    }
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

    }
    this.loadScripts();
    ///get auto complete list for area field
    this.service.getautocompletearea().toPromise().then(res => {
      this.List = res as any[]
      console.log(this.List)
      this.dataService = completerService.local(this.List, 'area_name', 'area_name');
    })

  }

  ngOnInit() {
    //get sub category list for rent
    this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+1).toPromise().then(res=>{
      console.log(res,"subc")
      this.subcategoryListForRent=res as any[]
      this.loadScript2()
    })
     //get sub category list for sale
    this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+2).toPromise().then(res=>{
      console.log(res,"subc")
      this.subcategoryListForSale=res as any[]
      this.loadScript2()
    })

   //router params
    this.route.params.subscribe(params => {
      this.spinner.show();

      this.productdata2.location_id = atob(decodeURIComponent(params['id']));
      this.productdata2.category_id = atob(decodeURIComponent(params['id1']));
      this.productdata2.sub_category_id = atob(decodeURIComponent(params['id2']));
      this.location = atob(decodeURIComponent(params['id3']));

      if(  this.location ==="null" || this.location === undefined){
        this.location =null
      }
      if( this.productdata2.location_id ==="null"){
        this.productdata2.location_id =null
      }
      if( this.productdata2.category_id ==="null"){
        this.productdata2.category_id=null
      }
      if( this.productdata2.sub_category_id  ==="null" || this.productdata2.sub_category_id === undefined ){
        this.productdata2.sub_category_id  =null
      }
        //we push the sub castegory id from prouter params
      this.nameFormArray.push(this.productdata2.sub_category_id);
           var y2 = this.nameFormArray.reduce((acc,currentvalue)=>{
       //avoid dublicate
         if(acc.indexOf(currentvalue) == -1){acc.push(currentvalue)}

       return acc
          },[])
        this.nameFormArray=y2
        console.log(this.nameFormArray,"nameformarray")
///search form data
       this. searchform = {
        location_id:this.productdata2.location_id ,
        sub_category_id: this.productdata2.sub_category_id,
        category_id: this.category_id,
        product_by: null,

        min_price: null,
        max_price: null,
        bedroom_count: null,
        bathroom_count: null
      }
      console.log(this.productdata2,"dataonint")

/// server side home page search list
      this.http.post(this.service.rootURL+'/products/homepagesearch/10',this.productdata2).toPromise().then(res=>{
        console.log(res,'homesearchlist')
        this.spinner.hide();
        this.service.homesearchlist=res as any[]
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            //set max value
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
        this.result_count = null
        //set max value
        localStorage.removeItem('max_value')
          localStorage.setItem('max_value','2000')
          this.loadScript()

       }
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
      })

    });
  //////////////////////////////////////if category not selected in home page .....below value loaded in subcategory////////////////////////////////////////////////////////////////
    if (this.productdata2.category_id == null) {
      this.service.getonlysubcategory().toPromise().then(res => {
        console.log(res, "subc")
        this.service.subcategoryList = res as Subcategory[]
      })

    }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    this.productdata = {
      location_id: this.location,
      category_id: null,
      sub_category_id: this.productdata2.sub_category_id,
      subcategory_id:  this.productdata2.sub_category_id,

    }
    //category_id from router params
    this.category_id = this.productdata2.category_id

    this.locationvalue = this.productdata2.location_id
    this.str = this.location

    window.scrollTo(0, 0);
    this.loadScript()
    this.loadScript2()

    ///show active radio btn based on category
    if (this.category_id === "1") {
      this.category = true
      this.categorysale = false
    }
    if (this.category_id === "2") {
      this.category = false
      this.categorysale = true
    }
    if (this.category_id === null) {
      this.category = false
      this.categorysale = false
    }


    ///get sub category list based on category id
    if (this.productdata2.category_id != null) {
      this.service.getsubcategory2(this.productdata2.category_id).toPromise().then(res => {
        console.log(res, "subc")
        this.onlysubcategoryList = res as Subcategory[]
      })
    }
    //with out category selection
    else {
      this.service.getonlysubcategory().toPromise().then(res => {
        console.log(res, "subc")
        this.onlysubcategoryList = res as Subcategory[]
      })
    }

    this.username = localStorage.getItem('username')
    this.service.getcompany()


    //login and logout btn show and hide
    if (localStorage.getItem('username')) {
      this.showlogout = true
      this.loginbtn = false
    }
    else {
      this.showlogout = false
      this.loginbtn = true
    }
 ///intialize data
    this.data = {
      product_id: null,
      is_property_view: null,
      is_property_phone_number_view: null,
      user_id: null,
      ip_address: null
    }
    if(this.productdata2.category_id  === "1"){

      this.loadScript2()
    this.category_id = "1"
    this.category = true
    this.categorysale = false
    this.sale=false
    this.rent=true
    this.intialSelect=false

    console.log(this.category_id, 'category')
    //get subcategory list
    this.service.getsubcategory(this.category_id)

    this.loadScript2()

       console.log(this.productdata2.category_id,this.intialSelect,"this.productdata2.category_id")
     }
     else if(this.productdata2.category_id  === "2"){
      this.loadScript2()
    this.category_id = "2"
    this.categorysale = true
    this.category = false
    this.sale=true
    this.rent=false
    this.intialSelect=false
    //get subcategory list
    this.service.getsubcategory(this.category_id)
    this.loadScript2()
       console.log(this.productdata2.category_id,this.intialSelect,"this.productdata2.category_id")
     }
     else{
       this.intialSelect=true
       this.sale=false
       this.rent=false
       console.log("call intial select")
     }
  }

 //fetch the details to the data global variable by click on the property image and name
  propertylist(det) {
    console.log(det, "productdet")
    this.service.getroomdetails(det.product_id)

    this.data.product_id = det.product_id
    this.data.is_property_view = true
    this.data.is_property_phone_number_view = false
    this.data.user_id = localStorage.getItem('userid')
    this.data.ip_address = this.ipAddress
    this.service.propertyclickevent(this.data).subscribe(res => {
      console.log(res)
    })

  }
  //when select auto complete location input field at that time this func trigger
  onclickautocomp(event) {
      console.log(this.str,"str")
    // create loop for autocomplete list
    for (var i = 0; i <= this.List.length; i++) {
      //checking null or not
      if (this.List[i] != null) {
        //checking the area name from loop is equal to the selected area name
        if (this.List[i].area_name == event) {
          var areaid = this.List[i].area_id
          this.searchform.location_id = areaid
          //serverside pagination home search list
          this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
            console.log(res)
            this.service.homesearchlist = res as any[]
            this.shortby(this.sorting)
            if(this.service.homesearchlist.length != 0){
              this.result_count = this.service.homesearchlist[0].result_count
              //set max value
              this.service.homesearchlist.map(data=>{
                localStorage.setItem('max_value',data.max_value)
                this.loadScript()
              })
            }
           else{
            this.result_count = null
            localStorage.removeItem('max_value')
            localStorage.setItem('max_value','2000')
            this.loadScript()
           }
           //finout low price  from search list
           const closest =  this.service.homesearchlist.reduce(
            (acc, loc) =>
              acc.price < loc.price
                ? acc
                : loc
          )
          console.log(closest.price,"price-minimum")
          //set low price
           localStorage.setItem('lowprice',closest.price)
            this.shortby(this.sorting)
          })
        }

      }



    }
  }
  //search the home list based on rent by
  onowner(name: string, isChecked: boolean) {
   //checking  the value of rent by (check box) is checked or not
    if (isChecked) {
      this.rentbyarray.push(name)
      var str = this.rentbyarray.join()
      console.log(str)
      //assign the selected name
      this.searchform.product_by = str
      //server side pagination search list created based on selected rent by
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res, "alist")
        this.service.homesearchlist = res as any[]
        console.log(this.sorting)
        this.shortby(this.sorting)
        //set max value and result count
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //fin out min value from the list
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      //set low price
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
    //its unchecked
    else {
      //remove the unchecked name from renbtarr list
      let index = this.rentbyarray.indexOf(name);
      this.rentbyarray.splice(index, 1);
      ///rentbyarr convert to str
      var str = this.rentbyarray.join()
      console.log(str)
      //assign value to product by
      this.searchform.product_by = str
      //server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res)
        this.service.homesearchlist = res as any[]
        //get max vlue and result count
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{

        //set max value 2000
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }

      //findout min value from list
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      //set min value
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
  }
 //search the home list based on property by
  onChange(name: string, isChecked: boolean) {
    //checking  the value of rent by (check box) is checked or not
    if (isChecked) {

      this.nameFormArray.push(name);
      //avoid dublicate
 var y2 = this.nameFormArray.reduce((acc,currentvalue)=>{

      if(acc.indexOf(currentvalue) == -1){acc.push(currentvalue)}

    return acc
       },[])
     this.nameFormArray=y2
   console.log(this.nameFormArray,"this.nameFormArray")
   //avoid empty value
      var str = this.nameFormArray.filter(e=>{return e !=null && e != ''}).join()
      console.log(str)
      if( this.searchform){ this.searchform.sub_category_id = str}
     //server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res)
        this.service.homesearchlist = res as any[]
        //set max value and result count
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
        // set max value 2000
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //finout min value
       const closest =  this.service.homesearchlist.reduce(
        (acc, currentvalue) =>
          acc.price < currentvalue.price
            ? acc
            : currentvalue
      )
      //set min value
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
    //unchecked condition
    else {
      //removed unchecked name from namefromarray list
      let index = this.nameFormArray.indexOf(name);
      this.nameFormArray.splice(index, 1);
      var str = this.nameFormArray.join()
      console.log(str)
      this.searchform.sub_category_id = str

//server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res)
        this.service.homesearchlist = res as any[]
        // set max value and result count
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
         //set max value
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //finout min value

       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      //set min value
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
  }
   //search the home list based on bedroom
  bedroomchange(name: string, isChecked: boolean) {
//checking  the value of rent by (check box) is checked or not
    if (isChecked) {
      this.namebedroomArray.push(name);

      var str = this.namebedroomArray.join()
      console.log(str)
      this.searchform.bedroom_count = str

 //server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res, "bedroom")
        this.service.homesearchlist = res as any[]
      //result count and max value
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }

       else{
         //set max value
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //finout min value
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      //set min value
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
    //unchecked
    else {
      //remove the unchecked name from namebedroomarray
      let index = this.namebedroomArray.indexOf(name);
      this.namebedroomArray.splice(index, 1);
      var str = this.namebedroomArray.join()
      console.log(str)
      this.searchform.bedroom_count = str

    //server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res)
        this.service.homesearchlist = res as any[]
        ///set max value and result count
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
         //set max value 2000
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //finout min value
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      //set min value
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
  }
//search the home list based on bathroom
  bathroomchange(name: string, isChecked: boolean) {
    //checking  the value of rent by (check box) is checked or not
    if (isChecked) {
      this.namebathroomArray.push(name);

      var str = this.namebathroomArray.join()
      console.log(str)
      this.searchform.bathroom_count = str


 //server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res, "bathroom")
        this.service.homesearchlist = res as any[]
        //set max value and result count
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
         //set max value 2000
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //findout min value
       //set min value
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
    //unchecked condition
    else {
      let index = this.namebathroomArray.indexOf(name);
      this.namebathroomArray.splice(index, 1);
      var str = this.namebathroomArray.join()
      console.log(str)
      this.searchform.bathroom_count = str


 //server side pagination of home search list
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
        console.log(res)
        this.service.homesearchlist = res as any[]
        //set result count and max value
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
         //set max value 2000
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
       //finout min value
       const closest =  this.service.homesearchlist.reduce(
        (acc, loc) =>
          acc.price < loc.price
            ? acc
            : loc
      )
      //set min value
      console.log(closest.price,"price-minimum")
       localStorage.setItem('lowprice',closest.price)
        this.shortby(this.sorting)
      })
    }
  }
  //search the home list based on filter by price
  onfilterbyprice(pricefilter) {
    console.log(pricefilter, "price")

 //server side pagination of home search list
    this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
      console.log(res)
      this.service.homesearchlist = res as any[]
      if(this.service.homesearchlist.length != 0){
        this.result_count = this.service.homesearchlist[0].result_count
        this.service.homesearchlist.map(data=>{
          localStorage.setItem('max_value',data.max_value)
          this.loadScript()
        })
      }
     else{
      this.result_count = null
      localStorage.removeItem('max_value')
      localStorage.setItem('max_value','2000')
      this.loadScript()
     }
     const closest =  this.service.homesearchlist.reduce(
      (acc, loc) =>
        acc.price < loc.price
          ? acc
          : loc
    )
    console.log(closest.price,"price-minimum")
     localStorage.setItem('lowprice',closest.price)
      this.shortby(this.sorting)
    })
  }
  //search the home list based on price value change
  pricechange(e) {
    console.log((<HTMLInputElement>document.getElementById("amount2")).value, "price")
    var price = (<HTMLInputElement>document.getElementById("amount2")).value;
    var lowprice = price.replace("-", ",").split(',')

    this.searchform.min_price = lowprice[0].toString().replace("Rs.", "")
    console.log(this.searchform.min_price, "price")
    //set max min price to searchform from price slider
    this.searchform.min_price = parseFloat(this.searchform.min_price)
    this.searchform.max_price = lowprice[1].toString().replace("Rs.", "")
    this.searchform.max_price = parseFloat(this.searchform.max_price)

 //server side pagination of home search list
    this.http.post(this.service.rootURL + '/products/homepagesearch/10', this.searchform).subscribe(res => {
      console.log(res)
      this.service.homesearchlist = res as any[]
      if(this.service.homesearchlist.length != 0){
        this.result_count = this.service.homesearchlist[0].result_count

      }
     else{
      this.result_count = null
      localStorage.removeItem('max_value')
      localStorage.setItem('max_value','2000')
      this.loadScript()
     }

      this.shortby(this.sorting)
    })


  }
  //search the home list based on filter by price
  filterprice() {
    var price = (<HTMLInputElement>document.getElementById("amount2")).value;
    var lowprice = price.replace("-", ",").split(',')
 //server side pagination of home search list
    this.searchform.min_price = lowprice[0].toString().replace("Rs.", "")
    console.log(this.searchform.min_price, "price")
    this.searchform.min_price = parseFloat(this.searchform.min_price)
    this.searchform.max_price = lowprice[1].toString().replace("Rs.", "")
    this.searchform.max_price = parseFloat(this.searchform.max_price)

    this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).subscribe(res => {
      console.log(res)
      this.service.homesearchlist = res as any[]
      if(this.service.homesearchlist.length != 0){
        this.result_count = this.service.homesearchlist[0].result_count
        this.service.homesearchlist.map(data=>{
          localStorage.setItem('max_value',data.max_value)
          this.loadScript()
        })
      }
     else{
      this.result_count = null
      localStorage.removeItem('max_value')
      localStorage.setItem('max_value','2000')
      this.loadScript()
     }
     const closest =  this.service.homesearchlist.reduce(
      (acc, loc) =>
        acc.price < loc.price
          ? acc
          : loc
    )
    console.log(closest.price,"price-minimum")
     localStorage.setItem('lowprice',closest.price)
      this.shortby(this.sorting)
    })


  }

  //custom js file preloading
  loadScripts() {


    const dynamicScripts = [
      'assets/js/main.js',

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

 //for rent radio btn click at that time this func trigger
  category1() {
    this.loadScript2()
    this.category_id = "1"
    this.category = true
    this.categorysale = false
    this.sale=false
    this.rent=true
    this.intialSelect=false
    this.nameFormArray=[]
    console.log(this.category_id, 'category')
    this.service.getsubcategory(this.category_id)
    this.productdata.sub_category_id=null
    this.searchform.sub_category_id=null
    this.loadScript2()


  }
  //for sale radio btn click at that time this func trigger
  category2() {
    this.loadScript2()
    this.category_id = "2"
    this.categorysale = true
    this.category = false
    this.sale=true
    this.rent=false
    this.intialSelect=false
     this.nameFormArray=[]
    this.searchform.sub_category_id=null
    this.productdata.sub_category_id=null
    this.service.getsubcategory(this.category_id)
    this.loadScript2()
  }
   //search the home list based on category and location and subcategory
  onsubmit(form: NgForm) {
   this.homeloader=true

    form.value.category_id = this.category_id
    form.value.location_id = this.locationvalue

    this.searchform.category_id = form.value.category_id
    //avoid dublicate
   this.nameFormArray.push(form.value.sub_category_id);
 var y2 = this.nameFormArray.reduce((acc,currentvalue)=>{

      if(acc.indexOf(currentvalue) == -1){acc.push(currentvalue)}

    return acc
       },[])

     this.nameFormArray=y2
    console.log(form.value)
    if (form.value.category_id === undefined) {
      form.value.category_id = null
    }
    if (form.value.location_id === undefined) {
      form.value.location_id = null
    }

    //search list func
    this.service.posthomesearchonly(form.value).subscribe(res => {
      console.log(res, 'homesearchlist')
      this.service.homesearchlist = res as any[]
      this.homeloader=false
      //set max value and result count
      if(this.service.homesearchlist.length != 0){
        this.result_count = this.service.homesearchlist[0].result_count
        this.service.homesearchlist.map(data=>{
          localStorage.setItem('max_value',data.max_value)
          this.loadScript()
        })
        console.log(this.result_count,"resultcount")

      }
      else{
        //set max value 2000
        this.result_count=null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
      }


    })

  }
  //when location autocomplete value change at thattime this func  trigger
  //in this func we get area name and area id
  locationchange(e) {

    for (var i = 0; i <= this.List.length; i++) {
      if (this.List[i] != null) {
        if (this.List[i].area_name == e) {
          var areaid = this.List[i].area_id
          this.locationvalue = areaid
        this.locationtest =  this.List[i].area_name
          console.log(e)
        }
      }

    }

  }
  areachange(){

    this.str = this.locationtest

  }
  /**custom js file preloading */
  public loadScript() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  public loadScript2() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url2;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

//load more search list
//when click load more btn at that time this fun trigger
  loadmore() {
  //load next 10 search list
    if (this.result_count > this.page_size) {
      console.log("pz", this.page_size)
      this.spin = true
      this.page_size = this.page_size + 10
      console.log("pz", this.page_size)
      console.log(this.searchform, "searchform")
      //search list func
      this.http.post(this.service.rootURL + '/products/homepagesearch/' + this.page_size, this.searchform).toPromise().then(res => {
        console.log(res, 'homesearchlist')
        this.service.homesearchlist = res as any[]
        this.shortby(this.sorting)
        if(this.service.homesearchlist.length != 0){
          this.result_count = this.service.homesearchlist[0].result_count
          this.service.homesearchlist.map(data=>{
            localStorage.setItem('max_value',data.max_value)
            this.loadScript()
          })
        }
       else{
        this.result_count = null
        localStorage.removeItem('max_value')
        localStorage.setItem('max_value','2000')
        this.loadScript()
       }
        this.spin = false
        this.shortby(this.sorting)
      })
    }

  }
  //sort by the search list
  //sortby a2z and max,min price
  shortby(e) {
    this.sorting = e
    console.log("e", e)
    if (e === "1" || e === "2") {
      this.service.homesearchlist = this.service.homesearchlist
    }
    //max price
    if (e === "3") {
      console.log("high")
      this.service.homesearchlist = this.service.homesearchlist.sort((a, b) => {
        return a.price - b.price
      })
    }
    //min price
    if (e === "4") {
      console.log("low")
      this.service.homesearchlist = this.service.homesearchlist.sort((a, b) => {
        return b.price - a.price
      })
    }
    //a2z
    if (e === "5") {

      this.service.homesearchlist = this.service.homesearchlist.sort((a, b) => {
        // return a.product_title-b.product_title
        var A = a.product_title.toUpperCase();
        var B = b.product_title.toUpperCase();
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        else {
          return 0
        }
      })
    }
  }
  //get area list for auto complete
  locationintialize() {
    this.service.getautocompletearea().toPromise().then(res => {
      this.List = res as any[]
      console.log(this.List)
      this.dataService = this.completerService.local(this.List, 'area_name', 'area_name');
    })
  }


}






