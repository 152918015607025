import { NgForm } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';
declare let $:any

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class StateComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=true
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,private global: GlobalComponent,
    private router: Router) { }

  ngOnInit() {
    this.stateresetForm()
    //get country list
    this.service.getcountry()
    //get state list
    this.service.getstate()

    //get country list for dropdown
    this.service.getcountrydropdown()
    //get state list for dropdown
    this.service.getstatedropdown()

  }
  //reset func for state
  stateresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.statedata = {
      state_id: null,
      country_id: null,
      state_code: null,
      state_name: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,
      flag: null,
    }
  }
 //cancel and reset func
  onstatecancel(stateform) {
    this.stateresetForm(stateform)
    this.loading4 = false;
    this.stateedit = false
  }
  //when click edit icon on the table at that time this func trigger
  //in this func we populated the value of state form fields from the table list
  statepopulated(det) {
    this.stateedit = true
    this.service.statedata = Object.assign({}, det)
    this.loading4 = false;
  }
  //when click on/off switch  on the table at that time this func trigger
  //in this func we update the flag value of state form fields from the table list
  changeStateflag(det) {
    det.updated_by=this.global.userid
    this.service.putstate(det).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

       this.successmessage = message;
         $(this.modal1.nativeElement).modal('show');
        this.service.getstate()
        this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
        this.service.getstatedropdown()
      }
      else {
        this.errormessage = message;

       $(this.modal1.nativeElement).modal('show');
      }
    })
  }
  //insert and update func
  onstatesubmit(stateform) {
    console.log(stateform.value, "state")

    this.loading4 = true;
    //insert func
    if (stateform.value.state_id == null) {
      stateform.value.created_by=this.global.userid
      stateform.value.flag = true
      this.service.poststate(stateform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
        this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');
          this.stateresetForm(stateform)
          this.service.getstate()
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.stateedit = false
          this.loading4 = false;
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading4 = false;
        }
      })
    }
    //update func
    else {
      console.log(stateform.value,"state")
      stateform.value.updated_by=this.global.userid
      if( stateform.value.flag==true){
        stateform.value.flag=true
      }
      else if( stateform.value.flag==false){
        stateform.value.flag=false
      }
      this.service.putstate(stateform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.stateresetForm(stateform)
          this.service.getstate()
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.loading4 = false;
          this.stateedit = false
        }
        else {

         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading4 = false;
        }
      })
    }
  }

  //master menu list hide and show
  dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}

//property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}


//logout click func
//remove values from local storage and redirect to login page
public logout(): void {

  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
