
import { Router } from '@angular/router';

import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { HttpClient } from '@angular/common/http';
declare  let $:any

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],


})
export class ChangepasswordComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  public userid = localStorage.getItem('userid')
  private role_id = localStorage.getItem('role_id')
  changepwd: any
  dropdownval:boolean=false
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  constructor(private service: NewentryService, private router: Router,
    private http: HttpClient) { }

  ngOnInit() {
    this.resetForm()
  }
 // reset func for changepassword form
  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this.changepwd = {
      userid: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    }
  }
  //change password func
  onSubmit(form: NgForm) {
    form.value.created_by = localStorage.getItem('userid')
    form.value.userid=localStorage.getItem('userid')

    this.http.post(this.service.rootURL + '/users/changepassword', form.value).subscribe(res => {
      var error_status = res['error_status'];
      var error_message = res['error_message'];
      if (error_status == "1") {
        this.resetForm(form);
        this.successmessage = error_message;
          $(this.modal1.nativeElement).modal('show');

      }
      else {
        this.errormessage = error_message;
           $(this.modal2.nativeElement).modal('show');
      }
      console.log(res);
    });
  }
  //redirect to currency master page
  cancelClick() {
    this.router.navigateByUrl('/admin_page/currency');
  }
//master menu list hide and show
  dropdown1(){
    this.property=false
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}
///property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}


}
