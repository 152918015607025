import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { NewentryService } from 'src/app/shared/newentry.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PagesParentComponent } from 'src/app/pages-parent/pages-parent.component';
import { GlobalComponent } from 'src/app/global/global.component';
declare let $ : any


@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css'],
  providers:[PagesParentComponent,GlobalComponent]

})
export class CountryComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  Countryterm2: string;
  countryPage: number = 1
  countryedit: boolean = false
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;
  dropdownval2:boolean=true
  dropdownval:boolean=true
  arr: any[]
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,
     private router: Router,private global: GlobalComponent) { }
  ngOnInit() {

    this.countryresetForm()
    //get language list for multiselect dropdown
    this.service.getmultiselectlanguage()
    //get language list
    this.service.getlanguage()
    //get currencies list
    this.service.getcurrencies()
    //get country main list
    this.service.getcountry()
    this.service.getcurrencydropdown()
    //get currency list for dropdown
    this.service.getcountrydropdown()
     //get language list for dropdown
    this.service.getlanguagedropdown()

  }
 //reset func for country form
  countryresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.countrydata = {
      country_id: null,
      country_code: null,
      country_name: null,
      telephone_prefix: null,
      currency_id: null,
      currency_code: null,
      language_names: null,
      time_zones: null,
      created_by: null,
      language_id:null,
      created_date: null,
      updated_by: null,

      updated_date: null,


      flag: null,
    }
  }
  //////////////////////////////////////////////////////////multiselect dropdown////////////////////////////////////////////////////////////////

  /**multiselect settings */
  public thirdControlSettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-danger',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: false,

  };


  /**multiselect text settings */
  public RoleControlTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find...',
    defaultTitle: 'Select Language',
    allSelected: 'All selected',
  };

  /**multiselect options for list  */
  public fourthControlOptions: IMultiSelectOption[] = [
    { id: 1, name: 'Thamizhl' },
    { id: 2, name: 'English' },
    { id: 3, name: 'French' },
    { id: 4, name: 'Chinese' }
  ];
 //cancel and reset func
  oncountrycancel(countryform) {
    this.countryresetForm(countryform)
    this.loading3 = false
    this.countryedit = false
  }
      //update flag value in company table
  //when click on/off switch in the table at that time this func trigger
  changeCountryflag(det) {
    console.log(det)
    var x
    x = Object.assign({}, det)
    console.log(x, "x")
    x.language_names = x.language_id
    det.updated_by=this.global.userid
    this.service.putcountry(x).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
       this.successmessage = message;
         $(this.modal1.nativeElement).modal('show');
        this.service.getcountry()
        this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
        this.service.getcountrydropdown()

      }
      else {

        this.errormessage = message;
        //  $(this.modal1.nativeElement).modal('show');
          $(this.modal2.nativeElement).modal('show');
      }
    })

  }
     //when click edit icon on the table at that time this func trigger
  //values were populated to forms field from the table
  countryPopulated(det) {
    console.log(det, "country")
    this.countryedit = true
    det.language_id = det.language_id.split(",")
    this.service.countrydata = Object.assign({}, det)
    this.countryedit = true
    this.loading3 = false
  }
  //insert and update func
  oncountrysubmit(countryform) {
    this.loading3 = true;

    countryform.value.language_id = countryform.value.language_id.join();
    countryform.value.language_names = countryform.value.language_id
    console.log(countryform.value, "country")
    //insert func
    if (countryform.value.country_id == null) {
      countryform.value.created_by=this.global.userid
      countryform.value.flag = true
      this.service.postcountry(countryform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.countryresetForm(countryform)
          this.service.getcountry()
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.countryedit = false
          this.loading3 = false;
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading3 = false;
        }
      })
    }
    //update func
    else {
      countryform.value.updated_by=this.global.userid
      if(  countryform.value.flag==true){
        countryform.value.flag=true
      }
      else if( countryform.value.flag==false){
        countryform.value.flag=false
      }
      this.service.putcountry(countryform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.countryresetForm(countryform)
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.service.getcountry()
          this.countryedit = false
          this.loading3 = false;
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading3 = false;
        }
      })

    }
  }

  //master menu list hide and show
  dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}

//property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}

//logout click func
//remove values from local storage and redirect to login page
public logout(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
