import { NgForm } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/global/global.component';
import { PagesParentComponent } from 'src/app/pages-parent/pages-parent.component';
declare let $ :any;

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class UnitComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=false
  dropdownval2:boolean=true
  property:boolean=true
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,
    private router: Router,private global: GlobalComponent) { }

  ngOnInit() {
    this.unitresetForm()
    //get unit list
    this.service.getunit()
  }
//reset func
  unitresetForm(form?:NgForm){
    if (form != null)
    form.resetForm()
    this.service.unitdata = {
      unit_id:null,
      unit_name:null,
      unit_description: null,
      created_by:null,
      updated_by: null,
      updated_date: null,
      created_date: null,
      flag: null
    }
  }
  //cancel and reset func
  unitcancel(unitform){
    this.unitresetForm(unitform)
    this.unitedit=false
  }
  //insert and update func
  unitsubmit(unitform:NgForm){
    console.log(unitform,"uform")
    this.loading11=true;
    //insert func
    if(unitform.value.unit_id===null){
      unitform.value.flag=true
      unitform.value.created_by=this.global.userid
      this.service.postunit(unitform.value).subscribe(res=>{
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.service.getunit()
          this.unitresetForm(unitform)
          this.unitedit=false
          this.loading11=false
        }
        else {
         // this.pagesparent.errorModal(message);
         this.errormessage = message;
         //  $(this.modal1.nativeElement).modal('show');
           $(this.modal2.nativeElement).modal('show');
          this.loading11=false

        }
      })
    }
    //update func
    else{
      unitform.value.updated_by=this.global.userid
      if(unitform.value.flag==true){
        unitform.value.flag=true
      }
      else if(unitform.value.flag==false){
        unitform.value.flag=false
      }
      this.service.putunit(unitform.value).subscribe(res=>{
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.service.getunit()
          this.unitresetForm(unitform)
          this.unitedit=false
          this.loading11=false
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading11=false

        }
      })
    }
  }
        //when click edit icon on the table at that time this func trigger
  //in this func we populated the value of state form fields from the table list
  unitPopulated(det){
    this.unitedit=true
    this.service.unitdata=Object.assign({},det)
  }
   //update flag value in main list by using on/off switc in the table
  changeunitflag(det){
    det.updated_by=this.global.userid
    this.service.putunit(det).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');
        this.service.getunit()
      }
      else {
       this.errormessage = message;
         $(this.modal2.nativeElement).modal('show');

      }
    })
  }


  //master menu list hide and show
  dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}


  //property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}

   //logout click func
//remove values from local storage and redirect to login page
public logout(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}


}
