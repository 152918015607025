import { NgForm } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/global/global.component';
import { PagesParentComponent } from 'src/app/pages-parent/pages-parent.component';

declare let $: any;

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css'],
  providers: [PagesParentComponent, GlobalComponent]
})
export class PropertyComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string

  public images: any[] = []

  p: number = 1

  edit: boolean = false

  loading: boolean = false
  agentby: boolean = false
  companyby: boolean = false
  image
  image11
  file: File
  mainfile: File
  arr: any[]
  imglist
  mainthumbimage
  mainimage: any[] = []
  productId
  iscoverimg
  delete: boolean = false
  uploadbtn: boolean = false
  housebedroomshow: boolean = false
  date
  dropdownval: boolean = false
  dropdownval2: boolean = true
  property: boolean = true
  propertyval2: boolean = true
  approvalbtn: boolean = false
  uploadmainimage;
  uploadsubimage;
  category_title;
  ondate: boolean
  size: boolean
  loadingapproval: boolean
  imgloading: boolean = false
  img2loading: boolean = false
  minDate
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent, private changeDetectorRef: ChangeDetectorRef,
    private ng2ImgMax: Ng2ImgMaxService, private router: Router, private global: GlobalComponent) {
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
     }
  ngOnInit() {
    this.productresetForm()
    //delete temp record
    this.service.deleteTemp(this.global.userid)
    //get product list
    this.service.getproduct()
    //get categories list
    this.service.getcategories()
    //get sub categories list
    this.service.getonlysubcategory()
    //get city list
    this.service.getcitydropdown()
    //get dwelling type list
    this.service.getdwellingtype()
    //get company list
    this.service.getcompany()
    //get unit list
    this.service.getunit()
    //get agent list
    this.service.getagent()
    //get area list
    this.service.getarea()



  }
  //reset func for product form
  productresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.productdata = {
      product_id: null,
      approval_flag: null,
      product_code: null,
      availablity_status: null,
      product_title: null,
      product_description: null,
      short_description: null,
      category_id: null,
      location_id: null,
      sub_category_id: null,
      subcategory_id: null,
      address_1: null,
      address_2: null,
      address_3: null,
      area_id: null,
      city_id: null,
      size_of_property: null,
      unit_id: null,
      price: null,
      company_id: null,
      phone: null,
      product_by: null,
      process_status_id: null,
      agent_id: null,
      nearby_location: null,
      facilities: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,
      flag: null,
      is_deleted: null,
      deleted_by: null,
      deleted_date: null,
      area_name: null,
      city_name: null,
      category_name: null,
      subcategory_name: null,
      unit_name: null,
      agent_name: null,
      company_name: null,
      bedroom_count: null,
      bathroom_count: null,
      property_facing: null,
      remarks: null,
      dwelling_type_id: null,
      pet_friendly: null,
      avilable_date: null,
    }
  }
//cancel and reset func
  oncancel(form) {
    this.productresetForm(form)
    this.loading = false
    this.edit = false
    this.approvalbtn = false
    this.imgloading = false
    this.img2loading = false
    this.loadingapproval = false
  }
  //update flag change in main list
  Flagchange(det) {
    det.updated_by = this.global.userid
    this.service.putproduct(det).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = message;
        $(this.modal1.nativeElement).modal('show');
        this.loading = false
        this.service.getproduct()


      }
      else {
        this.errormessage = message;
        $(this.modal2.nativeElement).modal('show');
        this.loading = false
      }
    })
  }
   //when click edit icon on the table at that time this func trigger
  //values were populated to forms field from the table
  Populated(det) {
    console.log(det, "det")
    this.edit = true
    this.changeavailable(det.availablity_status)
    this.productChange(det.product_by)
    console.log(det.product_id)
    this.productId = det.product_id
    if (det.approval_flag === true) {
      this.approvalbtn = false
    }
    else {
      this.approvalbtn = true
    }
    //get uploaded image list
    this.service.getimage(det.product_id)
    //get uploaded main image
    this.service.getmainimage(det.product_id)
    if (this.service.mainimglist != null) {
      if (this.service.mainimglist.length === 0) {
        this.delete = true
      }
    }
    this.service.productdata = Object.assign({}, det)

    //pet friendly radio btn
    if (det.pet_friendly == true) {
      this.service.productdata.pet_friendly = "1"

    }
    else {
      this.service.productdata.pet_friendly = "0"
    }
    this.date = this.toDate(this.service.productdata.avilable_date)
    this.service.productdata.avilable_date = this.date
    this.subcategorybasedcategory(this.service.productdata.category_id)
    this.subcatchange(this.service.productdata.subcategory_id)

  }

  //insert and update func
  onsubmit(form) {


    this.loading = true

    if (form.value.pet_friendly == '1') {
      form.value.pet_friendly = true;
    } else {
      form.value.pet_friendly = false;
    }
    console.log(form.value, "product")
    form.value.bathroom_count = parseInt(form.value.bathroom_count)
    form.value.bedroom_count = parseInt(form.value.bedroom_count)
    form.value.price = parseFloat(form.value.price)
    form.value.avilable_date = this.toDateJs(form.value.avilable_date)
    console.log(form.value, "property")

    //insert func
    if (form.value.product_id == null) {
      form.value.approval_flag = true
      form.value.flag = true
      form.value.created_by = this.global.userid
      this.service.postproduct(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');
          this.images.length = 0
          this.mainimage.length = 0
          this.loading = false
          this.service.getproduct()
          this.productresetForm(form)
          this.edit = false

        }
        else {
          this.errormessage = message;
          $(this.modal2.nativeElement).modal('show');
          this.loading = false
        }
      })
    }
    //update func
    else {
      form.value.updated_by = this.global.userid
      if (this.approvalbtn == false) {
        form.value.approval_flag = true
      }
      else if (this.approvalbtn == true) {
        form.value.approval_flag = false
      }
      if (form.value.flag == true) {
        form.value.flag = true
      }
      else if (form.value.flag == false) {
        form.value.flag = false
      }

      console.log(form.value, "productedit")

      this.service.putproduct(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');

          this.service.mainimglist.length = 0
          this.service.imglist.length = 0
          this.approvalbtn = false
          this.loading = false
          this.edit = false
          this.service.getproduct()
          this.productresetForm(form)

        }
        else {
          this.errormessage = message;
          $(this.modal2.nativeElement).modal('show');
          this.loading = false
        }
      })
    }
  }

  ///in this func we make approval to property
  approval(form) {
    this.loadingapproval = true
    form.value.flag = true
    form.value.approval_flag = true
    if (form.value.pet_friendly == '1') {
      form.value.pet_friendly = true;
    } else {
      form.value.pet_friendly = false;
    }
    form.value.updated_by = this.global.userid
    //convert UTC date
    form.value.avilable_date = this.toDateJs(form.value.avilable_date)
    console.log(form.value, "productedit")
//approval func
    this.service.putproduct(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = "Approved Successfully";

        this.loading = false
        this.edit = false
        this.approvalbtn = false
        this.imgloading = false
        this.img2loading = false
        this.loadingapproval = false
        this.productresetForm(form)
        $(this.modal1.nativeElement).modal('show');


        this.service.getproduct()


      }
      else {
        this.errormessage = message;;
        $(this.modal2.nativeElement).modal('show');

      }
    })
  }
 //when product by select box value change at that time this func trigger
 // in this func we hide or show agent name and company name field
  productChange(e) {

    //hide company name field
    //show agent name field
    if (e === "agent") {
      this.agentby = true
      this.companyby = false
    }
     //hide agent name field
    //show company name field
    if (e === "company") {
      this.agentby = false
      this.companyby = true
    }

     //hide company name field
    //hide agent name field
    if (e === "owner") {
      this.agentby = false
      this.companyby = false
    }
  }


  //file uploaded func for main image uploaded
  fileChangemain(input) {
    this.imgloading = true
    const reader = new FileReader();
    if (input.files.length) {
      this.mainfile = input.files[0];
      reader.onload = () => {
      }
      reader.readAsDataURL(this.mainfile);
      console.log(this.mainfile, "f2")
      console.log(input.files[0].size + ' Bytes');
    //checking file size higher than 4mb
      if (input.files[0].size / 1024 / 1024 > 4) {
        console.log('file is bigger than 4MB');
         //file compress func by using ng2imgmax external js libirary
        this.ng2ImgMax.resizeImage(this.mainfile, 2000, 1000).subscribe(res => {
          console.log(res, "mainimageresize")
          this.uploadmainimage = new File([res], res.name)
        })
      }
      else {
        console.log(input.files[0].size / 1024 / 1024 + ' MB');
        this.uploadmainimage = this.mainfile
      }
 //file compress func by using ng2imgmax external js libirary
      this.ng2ImgMax.resizeImage(this.mainfile, 400, 224).subscribe(
        result => {
          console.log(result, "resultimg")

          this.mainthumbimage = new File([result], result.name);
          console.log(this.mainthumbimage, "img11")
         //edit mode
          if (this.edit === true) {

            //set formData
            const formData = new FormData();
            this.iscoverimg = true
            formData.append('file', this.uploadmainimage)
            formData.append('created_by', this.global.userid)
            formData.append('thumbnail_file', this.mainthumbimage)
            formData.append('product_id', this.productId)
            formData.append('is_cover_image', this.iscoverimg)
            console.log(formData.get('thumbnail_file'), "formdata")
            console.log(formData.get('file'), "formdata")

             //image uploaded func
            this.service.postimage(formData).subscribe(res => {
              var error_status = res['error_status'];
              var error_message = res['error_message'];
              if (error_status == "1") {
                console.log(res);
                this.imgloading = false
                //get main image list
                this.service.getmainimage(this.productId)
              }
              else {
                this.errormessage = error_message;
                this.imgloading = false
                $(this.modal2.nativeElement).modal('show');

              }
            })
          }
          //entry mode
          else {

            //set formData
            const formData = new FormData();
            this.iscoverimg = true
            formData.append('file', this.uploadmainimage)
            formData.append('created_by', this.global.userid)
            formData.append('thumbnail_file', this.mainthumbimage)
            formData.append('is_cover_image', this.iscoverimg)
            console.log(formData.get('thumbnail_file'), "formdata")
            console.log(formData.get('file'), "formdata")
            this.service.postimage(formData).subscribe(res => {
              var error_status = res['error_status'];
              var error_message = res['error_message'];
              if (error_status == "1") {
                console.log(res);
                this.uploadbtn = true
                this.imgloading = false
                //get uploaded main image
                this.service.getuploadmainimg(this.global.userid).toPromise().then(res => {
                  this.mainimage = res as any[]
                })
              }
              else {
                this.errormessage = error_message;
                $(this.modal2.nativeElement).modal('show');
                this.imgloading = false

              }
            })
          }

        },
        error => {
          console.log('😢 Oh no!', error);
        }
      );


    }
  }


//sub image uploaded func
  fileChange(input) {
    this.img2loading = true
    this.readFiles(input.files);
    if (input.files.length) {
      this.file = input.files[0];
      console.log(this.file, "f2")
      //checking file size higher than 4mb
      if (input.files[0].size / 1024 / 1024 > 4) {
        console.log('file is bigger than 4MB');
         //file compress func by using ng2imgmax external js libirary
        this.ng2ImgMax.resizeImage(this.file, 2000, 1000).subscribe(res => {
          console.log(res, "mainimageresize")
          this.uploadsubimage = new File([res], res.name)
        })
      }
      else {
        console.log(input.files[0].size / 1024 / 1024 + ' MB');
        this.uploadsubimage = this.file
      }
 //file compress func by using ng2imgmax external js libirary
      this.ng2ImgMax.resizeImage(this.file, 400, 224).subscribe(
        result => {
          console.log(result, "resultimg")

          this.image11 = new File([result], result.name);
          console.log(this.image11, "img11")
          //edit mode
          if (this.edit === true) {
            //set formData
            const formData = new FormData();
            this.iscoverimg = false
            formData.append('file', this.uploadsubimage)
            formData.append('created_by', this.global.userid)
            formData.append('product_id', this.productId)
            formData.append('thumbnail_file', this.image11)
            formData.append('is_cover_image', this.iscoverimg)
            console.log(formData.get('thumbnail_file'), "formdata")
            console.log(formData.get('file'), "formdata")

            //sub image uploaded func
            this.service.postimage(formData).subscribe(res => {
              var error_status = res['error_status'];
              var error_message = res['error_message'];
              if (error_status == "1") {
                console.log(res, "update");
                this.img2loading = false
                //get sub uploaded image list
                this.service.getimage(this.productId)
              }
              else {
                this.errormessage = error_message;
                this.img2loading = false
                $(this.modal2.nativeElement).modal('show');
              }
            })

          }
          //entry mode
          else {
            //set formData
            const formData = new FormData();
            formData.append('file', this.uploadsubimage)
            formData.append('created_by', this.global.userid)
            formData.append('thumbnail_file', this.image11)
            console.log(formData.get('thumbnail_file'), "formdata")
            console.log(formData.get('file'), "formdata")

            //image uploaded func
            this.service.postimage(formData).subscribe(res => {
              var error_status = res['error_status'];
              var error_message = res['error_message'];
              if (error_status == "1") {
                console.log(res);
                this.img2loading = false
                //get sub uploaded image
                this.service.getuploadimage(this.global.userid).toPromise().then(res => {
                  console.log(res, "img")
                  this.images = res as any[]
                })
              }
              else {
                this.errormessage = error_message;
                this.img2loading = false
                $(this.modal2.nativeElement).modal('show');
              }
            })
          }

        },
        error => {
          console.log('😢 Oh no!', error);
        }
      );


    }
  }

  readFile(file, reader, callback) {
    reader.onload = () => {
      callback(reader.result);
    }
    reader.readAsDataURL(file);
  }

  readFiles(files, index = 0) {
    let reader = new FileReader();

    if (index in files) {
      this.readFile(files[index], reader, (result) => {
        this.readFiles(files, index + 1);
      });
    } else {
      this.changeDetectorRef.detectChanges();
    }
  }

  //entry mode delete sub image
  removeImage(id): void {

    this.service.deletesingle(id).toPromise().then(res => {
      console.log('delete')
    })
    this.service.getuploadimage(this.global.userid).toPromise().then(res => {
      this.images = res as any[]
    })
  }

  //edit mode delete sub image
  deleteImage(imgdetails) {
    this.service.deleteimage(imgdetails.upload_image_id).subscribe(res => {
      console.log(imgdetails, "imsd")
      this.service.getimage(imgdetails.product_id)
    })
  }
  //entry mode delete main image
  removemainImage(id) {
    this.service.deletesinglemainimage(id).toPromise().then(res => {
      console.log('delete')
    })
    this.service.getuploadmainimg(this.global.userid).toPromise().then(res => {
      this.mainimage = res as any[]
    })
  }
  //edit mode delete main image
  deletemainImage(imgdetails) {
    console.log(imgdetails, "deleteMainimg")
    this.service.deleteimage(imgdetails.upload_image_id).subscribe(res => {
      this.service.getmainimage(imgdetails.product_id)
      this.delete = true
    })
  }

  subcatchange(e) {
    if (e === "2") {
      this.housebedroomshow = true
    }
  }
  //get subcategoru list based on category
  subcategorybasedcategory(e) {
    if (e == "1") {
      this.service.getsubcategorybasedcategory(e)
      this.category_title = "RENT"
      this.size = false
      $('#product_title').attr('placeholder', 'Ex : Apartment For RENT');
    }
    else {
      this.service.getsubcategorybasedcategory(e)
      this.category_title = "SALE"
      this.size = true
      $('#product_title').attr('placeholder', 'Ex : Apartment For SALE');
    }
  }
  //convert to UTC date format
  toDateJs(date: NgbDateStruct): Date {
    return date ?
      new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
  }
  //convert date to ngb date format
  toDate(dob) {
    if (dob) {
      const [year, month, day] = dob.split('-');
      const obj = {
        year: parseInt(year), month: parseInt(month), day:
          parseInt(day.split(' ')[0].trim())
      };
      return obj;
    }
  }


  //master menu list hide and show
  dropdown1() {
    this.property = false
    this.dropdownval = true
    this.dropdownval2 = false
  }
  dropdown2() {
    this.dropdownval = false
    this.dropdownval2 = true
  }


  //property menu list hide and show
  property1() {
    console.log("property")
    this.dropdownval = false
    this.property = true
    this.propertyval2 = false
  }
  property2() {
    console.log("property")
    this.dropdownval = false
    this.property = false
    this.propertyval2 = true
  }

  //logout func
  //remove values from local storage and redirect to login page
  public logout(): void {
    // alert('ddd');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    localStorage.removeItem('role_id');
    localStorage.removeItem('role_name');

    this.router.navigateByUrl('/admin');
  }

///when Available Status dropdown value changed at that time this func trigger
//show availability field when select immediate status
  changeavailable(e) {
    if (e != "immediate") {
      this.ondate = true
    }
    else {
      this.ondate = false
    }
  }
}
