import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleterService } from 'ng2-completer';
import { NewentryService } from '../shared/newentry.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { GlobalComponent } from '../global/global.component';
import { HeaderComponent } from '../header/header.component';

declare let $:any

@Component({
  selector: 'app-postadvertisement',
  templateUrl: './postadvertisement.component.html',
  styleUrls: ['./postadvertisement.component.css'],
  providers:[GlobalComponent,HeaderComponent]
})
export class PostadvertisementComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  username
  signup: any
  signin: any
  hidelogin: boolean = false
  propertypostbtnshow: boolean = false
  agentsignup: any
  agent: boolean
  single
  public images: any[] = [];
  file
  image11
  productdata: any
  category_id
  showlogout: boolean = false
  iscoverimg
  mainfile
  mainimage:any[]=[]
  mainthumbimage
  loginbtn: boolean
 postad:boolean=false
 registershow:boolean=false
 ad
 image
 edit
 adid
  changeDetectorRef: any;
  loading:boolean
  createacc:boolean
  public errormessage;
  public successmessage;
  public warningmessage;
  listplace:boolean=true
  postadshow:boolean=false
  uploadmainimage
  uploadsubimage
  imgloading:boolean=false
  img2loading:boolean=false
  constructor(public service: NewentryService, private router: Router, private http: HttpClient,private route: ActivatedRoute,
    private ng2ImgMax: Ng2ImgMaxService, private completerService: CompleterService,private global: GlobalComponent,
    private header:HeaderComponent) { }

  ngOnInit() {

    this.header.listplacestr="true"
    window.scrollTo(0,0);
    if( localStorage.getItem('role_id') != "SADM"){
      this.username=this.global.username
    }
    this.service.getcategories()
this.registershow=false
this.service.deleteTemp(this.global.userid)
//get subcategory list
    this.service.getonlysubcategory()
//get city list for dropdown
    this.service.getcitydropdown()
    //get dwelling type list for dropdown
    this.service.getdwellingtype()
    //get company list for dropdown
    this.service.getcompany()
    //get unit list for dropdown
    this.service.getunit()
    //get agent list for dropdown
    this.service.getagent()
    //get area list for dropdown
    this.service.getarea()
    //get product list
    this.service.getproduct()
    this.agentsignup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,
      role_name: null,
      login_users: null,
      user_count: null,
      agent_company_id: null
    }

    this.signin = {
      email: null,
      password: null
    }
    this.resetform()
    this.productresetForm()
    this.signupresetform()

    //logout btn and login btn and create account btn show or hide condition for only admin user
    if (this.global.username && localStorage.getItem('role_id') != "SADM") {

        this.showlogout=true
        this.createacc=false
        this.loginbtn=false


    }
    else {
      this.showlogout = false
      this.loginbtn = true
      this.createacc=true
    }
  }
  //reset func for product form
  productresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.postaddata = {
      category_id: null,
      subcategory_id: null,
      area_id: null,
      flag:null,
      apporval_flag:null,
      city_id: null,
      advertisement_title: null,
      advertisement_id:null,
      advertisement_image: null,
      advertisement_description: null,
      advertiser_name: null,
      advertiser_phone: null,
      advertising_period: null,
      advertiser_email: null,
      created_by: null,
    }
  }
  resetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

      created_by: null
    }
  }
  //reset func for signup form
  signupresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

    }
  }

  //reset func for signin form
  signinresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      email: null,
      password: null
    }
  }
  //redirect to post property page
  postpropertyagent() {
    if (this.username != null) {
      this.hidelogin = true
      this.router.navigateByUrl('/propertypost')
    }
    this.agent = true
    this.propertypostbtnshow = true
  }
  postpropertyowner() {
    if (this.username != null) {
      this.hidelogin = true
      this.router.navigateByUrl('/propertypost')
    }
    this.agent = false
    this.propertypostbtnshow = true
  }
  //submit fun for register form
  //after successful register user can signain
  onregistersubmit(registerform) {
    registerform.value.role_name="user"
    this.service.postregister(registerform.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        document.getElementById("signupPopupForm2").click();
      }
      else {

        alert(message)
      }


    })
  }
  //submit fun for login form
  //after successful login redirect to home page
  onsigninsubmit(signin) {
    this.service.postsignin(signin.value).subscribe((res: any) => {
      console.log(res, "signindetails")
      var status = res['error_status'];
      var message = res['error_message'];

      if (status == 1) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('userid', res.user_id)
        localStorage.setItem('username', res.user_name);
        localStorage.setItem('role_name', res.role_name);
        localStorage.setItem('role_id', res.role_id)
        localStorage.setItem('user_email', res.user_email)
        this.username = this.global.username
        this.signinresetform(signin)
        this.showlogout = true
        this.loginbtn = false
        this.router.navigateByUrl('/home')
        document.getElementById("loginPopupForm2").click();
      }
      else {
        alert('Incorrect username or password.');

      }
    })
  }


  //main image file uploaded func
fileChangemain(input) {
  this.imgloading=true
  const reader = new FileReader();
  this.ad=true
  if (input.files.length) {
    this.mainfile = input.files[0];
    reader.onload = () => {
     this.image = reader.result;
    }
    reader.readAsDataURL(this.mainfile);
    console.log(this.mainfile, "f2")
     //checking file size is higher than 4mb
    if (input.files[0].size/1024/1024 > 4) {
      console.log('file is bigger than 4MB');
      //compress the file size by using ng2ImgMax external js libirary
      this.ng2ImgMax.resizeImage(this.mainfile, 2000, 1000).subscribe(res=>{

        this.uploadmainimage=new File([res], res.name)
   })
     }
     else{
       console.log(input.files[0].size/1024/1024 + ' MB');
       this.uploadmainimage=this.mainfile
     }
     //compress the file size by using ng2ImgMax external js libirary
    this.ng2ImgMax.resizeImage(this.mainfile, 400, 224).subscribe(
      result => {
        console.log(result, "resultimg")

        this.mainthumbimage = new File([result], result.name);
        console.log(this.mainthumbimage, "img11")

          //set formdata
          const formData = new FormData();
          this.iscoverimg = true
          this.ad=true
          formData.append('is_advertisement_image',this.ad)
          formData.append('file', this.uploadmainimage)
          formData.append('created_by', this.global.userid)
          formData.append('thumbnail_file', this.mainthumbimage)
          formData.append('is_cover_image', this.iscoverimg)

          console.log(formData.get('thumbnail_file'), "formdata")
          console.log(formData.get('file'), "formdata")

          //image uploaded func
          this.service.postimage(formData).subscribe(res => {
            var error_status = res['error_status'];
            var error_message = res['error_message'];
            if (error_status == "1") {
              console.log(res);
              this.imgloading=false
              //get uploaded image list
              this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{
                this.mainimage=res as any[]
              })
              this.service.getadmainimage(this.adid)

            }
            else {
              alert(error_message)
              this.imgloading=false
            }
          })



      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );


  }
}

//if user is signin then redirect to list your place page other wise show login popup form
listyourplace(){
  if (localStorage.getItem('role_name') === "agent" || localStorage.getItem('role_name') === "owner") {
    this.registershow = false
    console.log('hi')
   document.getElementById("loginPopupForm2").click()
    $("#loginPopupForm2").modal('hide')
    this.router.navigateByUrl('/propertypost')
    this.postad = false
    this.listplace=false
  }
  else {
    console.log('hi2')
    $("#loginPopupForm0").modal('show')

  }
}
//sub image file uploaded func
fileChange(input){
  this.img2loading=true
  this.readFiles(input.files);
  if (input.files.length) {
      this.file = input.files[0];
      console.log(this.file, "f2")
      //checking file size is higher than 4mb
      if (input.files[0].size/1024/1024 > 4) {
        console.log('file is bigger than 4MB');

        //compress the file size by using ng2ImgMax external js libirary
        this.ng2ImgMax.resizeImage(this.file, 2000, 1000).subscribe(res=>{

          this.uploadsubimage=new File([res], res.name)
     })
       }
       else{
         console.log(input.files[0].size/1024/1024 + ' MB');
         this.uploadsubimage=this.file
       }
        //compress the file size by using ng2ImgMax external js libirary
      this.ng2ImgMax.resizeImage(this.file, 400, 224).subscribe(
        result => {
          console.log(result,"resultimg")

         this.image11 =  new File([result], result.name);
          console.log(this.image11,"img11")

            //set formData
            const formData = new FormData();
            formData.append('file',this.uploadsubimage)
            this.ad=true
            formData.append('is_advertisement_image',this.ad)
            formData.append('created_by', this.global.userid)
            formData.append('thumbnail_file',this.image11)
            console.log(formData.get('thumbnail_file'), "formdata")
            console.log(formData.get('file'), "formdata")

            //image uploaded func
            this.service.postimage(formData).subscribe(res => {
              var error_status = res['error_status'];
              var error_message = res['error_message'];
              if (error_status == "1") {
                console.log(res);
                this.img2loading=false
                //get uploaded image list
                this.service.getuploadimage(this.global.userid).toPromise().then(res=>{
                  console.log(res,"img")
                  this.images=res as any[]
                })

              }
              else {
                this.img2loading=false
             alert(error_message)
              }
            })

        },
        error => {
          console.log('😢 Oh no!', error);
        }
      );
    }
}

readFile(file, reader, callback){
  reader.onload = () => {
      callback(reader.result);
  }
  reader.readAsDataURL(file);
}

readFiles(files, index=0){
  let reader = new FileReader();

  if (index in files){
      this.readFile(files[index], reader, (result) =>{

          this.readFiles(files, index+1);
      });
  }else{
      this.changeDetectorRef.detectChanges();
  }
}
//remove sub image
removeImage(id):void{

  this.service.deletesingle(id).toPromise().then(res=>{
    console.log('delete')
  })
  //get sub image main list
  this.service.getuploadimage(this.global.userid).toPromise().then(res=>{
    this.images=res as any[]
  })
}

//remove main image
removemainImage(id){

 this.service.deletesinglemainimage(id).toPromise().then(res=>{
   console.log('delete')
 })
 //get uploaded main image list
 this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{
  this.mainimage=res as any[]
 })
}

//submit the post free advertistment formvalue
  postsubmit(form) {
    this.loading=true
    form.value.approval_flag=false
    form.value.category_id = this.category_id
    form.value.created_by = this.global.userid
    form.value.advertising_period=parseInt(form.value.advertising_period)
    form.value.unit_id = "7361ABDC-851D-4F97-9BAA-95741C226777"
    form.value.city_id = "2C697687-21E4-4A04-9388-53773E0E45F6"


    this.service.postfreead(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.loading=false
        this.service.getproduct()
        this.productresetForm(form)
        this.images.length = 0
        this.mainimage.length = 0
        this.successmessage=message
        $(this.modal1.nativeElement).modal('show');

      }
      else {
        this.errormessage=message
        $(this.modal2.nativeElement).modal('show');
        this.loading=false
      }
    }

    )
  }
//when click rent and sale radio btn at tha time this func trigger
//in this func we load the sub categoru list based on the choosen categoy
  category(e) {

    if (e == 1) {
      this.category_id = "1"
      this.service.getsubcategory(this.category_id)
    }
    else {
      this.category_id = "2"
      this.service.getsubcategory(this.category_id)
    }
  }


  ///convert date to UTC format
  toDateJs(date: NgbDateStruct): Date {
    return date ?
      new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
  }
  postclear(postform){
    this.productresetForm(postform)
    this.loading=false
    this.images.length=0
    this.mainimage.length=0
    this.imgloading=false
    this.img2loading=false
  }
  //cancel fun for post advertis ment form
  postcancel(postform){
    this.productresetForm(postform)
    this.loading=false
    this.mainimage.length=0
    this.images.length=0
   this.postad=false
   this.imgloading=false
   this.img2loading=false
    this.router.navigateByUrl('/home')
  }
  //redirect to profile page
  myprofile(){
    this.router.navigateByUrl('/myprofile')
  }
  register(){
    this.registershow=true
  }
}
