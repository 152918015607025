import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs'
import { timer } from 'rxjs/observable/timer'
import 'rxjs/add/observable/range'

import 'rxjs/add/observable/timer'
import "rxjs/add/operator/map";
import "rxjs/add/operator/zip";

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() value: number
  @Output('onComplete') timerOver: EventEmitter<any> = new EventEmitter<any>();
  timerValue
  areTenSecsRemainings:boolean=false;
  source
  constructor() { }

  ngOnInit() {
    if(this.value > 0){
      let source$ = Observable.range(0, this.value).zip(
        Observable.timer(0, 1000),
        (x) => { return x }
      ).map(x => {
        return this.value - x
  
      })
      
  
    this.source = source$
   
  
    }
 
    this.source.subscribe(seconds => {
      // console.log(seconds)
      let mins = parseInt("" + seconds / 60);
      let secs = seconds % 60;
      let hrs = parseInt("" + mins / 60);
      mins = mins % 60
      if(secs<11) this.areTenSecsRemainings=true
      let res = {
        'hours': hrs,
        'minutes': mins,
        'seconds': secs
      }

      this.timerValue=res;
    }, () => this.timerOver.emit('TIMER ERROR'), () => this.timerOver.emit('TIMER OVER'))
  }


}
