import { NgForm } from '@angular/forms'
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/global/global.component';
declare let $:any

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css'],
  providers:[ GlobalComponent]
})
export class AgentComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=true
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  approvalbtn:boolean=false
  updated_by={
    updated_by:this.global.userid
  }
  approvalloading:boolean=false
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent, private router: Router,
    private global: GlobalComponent) { }

  ngOnInit() {

    this.agentresetForm()
    //get company list
  this.service.getcompany()
  //get language list for multiselect
    this.service.getmultiselectlanguage()
     //get language list for dropdown
    this.service.getlanguage()
     //get currency list for dropdown
    this.service.getcurrencies()
     //get country list for dropdown
    this.service.getcountry()
     //get state list for dropdown
    this.service.getstate()
     //get city list for dropdown
    this.service.getcities()
     //get area list for dropdown
    this.service.getarea()
     //get categories list for dropdown
    this.service.getcategories()
     //get agent list for dropdown
    this.service.getagent()
     //get group list for dropdown
    this.service.getgroup()
     //get subcategory list for dropdown
    this.service.getonlysubcategory()
     //get unit list for dropdown
    this.service.getunit()
     //get currency list for dropdown
    this.service.getcurrencydropdown()
     //get country list for dropdown
    this.service.getcountrydropdown()
     //get language list for dropdown
    this.service.getlanguagedropdown()
     //get state list for dropdown
    this.service.getstatedropdown()
     //get city list for dropdown
    this.service.getcitydropdown()
  }
  //reset func for agent form
  agentresetForm(form?:NgForm){
    if(form != null)
    form.resetForm()
      this.service.agentdata={
        agent_id :null,
        password:null,
        city_id :null,
        agent_name :null,
        agent_companyname :null,
        agent_company_id:null,
        email :null,
        phone :null,
        agent_address :null,
        license_no :null,
        remarks :null,
        created_by :null,
        created_date :null,
        updated_by :null,
        updated_date :null,
        flag :null
      }
}

//insert and update func agent form
onagentsubmit(form:NgForm){
  console.log(form.value,"form")
  this.loading8=true
 //insert func
  if(form.value.agent_id===null){
    form.value.flag=true
    form.value.created_by=this.global.userid
    form.value.password=form.value.password.toLowerCase()
    this.service.postagent(form.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');
        this.agentresetForm(form)

        this.service.getagent()
        this.agentedit = false
        this.loading8 = false;
      }
      else {
        this.errormessage = message;
          $(this.modal2.nativeElement).modal('show');
        this.loading8 = false;

      }
    })
  }
  //update func
  else{
    form.value.updated_by=this.global.userid
    if( form.value.flag==true){
      form.value.flag=true
    }
    else if( form.value.flag==false){
      form.value.flag=false
    }
    this.service.putagent(form.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
       this.successmessage = message;
         $(this.modal1.nativeElement).modal('show');

       this.approvalbtn=false
       this.agentedit = false
       this.loading8 = false;
       this.agentresetForm(form)
        this.service.getagent()

      }
      else {
       this.errormessage = message;
         $(this.modal2.nativeElement).modal('show');
        this.loading8 = false;

      }
    })
  }
}
//cancel and reset func
onagentcancel(form){
  this.agentedit = false
  this.agentresetForm(form)
  this.loading8=false
  this.approvalbtn=false
  this.approvalloading=false
}
//update flag value in agent master table
agentFlagchange(det){
  det.updated_by=this.global.userid
  this.service.putagent(det).subscribe(res=>{
    var status = res['error_status']
    var message = res['error_message']
    if (status == 1) {
     this.successmessage = message;
       $(this.modal1.nativeElement).modal('show');
      this.service.getagent()
      this.agentedit = false
      this.loading8 = false;
    }
    else {
     this.errormessage = message;
       $(this.modal2.nativeElement).modal('show');
      this.loading8 = false;

    }
  })
}
//agent form values were populated when click edit icon on the table list
agentPopulated(det){
  this.agentedit=true
  if(det.approval_flag===true){
    this.approvalbtn=false
  }
  else{
    this.approvalbtn=true
  }
  this.service.agentdata=Object.assign({},det)
}
//in this func used to approval the agent
approval(form){
this.approvalloading=true
  this.service.agentapproval(this.updated_by,form.value.agent_id).subscribe(res=>{
    var status = res['error_status']
    var message = res['error_message']
    if (status == 1) {
      this.successmessage = message;
      this.approvalbtn=false
      this.approvalloading=false
      this.agentedit = false
      this.loading8=false
      this.agentresetForm(form)
        $(this.modal1.nativeElement).modal('show');
       this.service.getagent()

     }
     else {
      this.errormessage = message;
        $(this.modal2.nativeElement).modal('show');

 this.approvalloading=false
     }
  })
}
//master menu list hide and show
dropdown1(){
  this.property=false
  this.propertyval2=true
  this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
this.dropdownval=false
this.dropdownval2=true
}
///property menu list hide and show
property1(){
console.log("property")
this.dropdownval=false
this.property=true
this.dropdownval2=true
this.propertyval2=false
}
property2(){
console.log("property")
this.dropdownval=false
this.property=false
this.propertyval2=true
}
//logout click func
public logoutclick(): void {

  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
