import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalComponent } from '../global/global.component';
import { NewentryService } from '../shared/newentry.service';
declare let $:any

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css'],
  providers:[GlobalComponent]
})
export class MyprofileComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
profilelist
username
companyshow:boolean
showlogout:boolean
loginbtn:boolean
registershow:boolean
disable:boolean = false
profiledata
loading:boolean

signicon:boolean=true
data={
  updated_by:this.global.userid
}
id=localStorage.getItem('userid')
  constructor(public service: NewentryService,private router:Router,private http:HttpClient
    ,private global: GlobalComponent) {

      this.profilelist = {
        agent_name:null,
        company_name:null,
        contact_person:null,
        agent_address:null,
        address_1:null,
        address_2:null,
        address_3:null,
        phone:null,
        license_no:null,

        remarks:null,
        email:null
      }
     }

  ngOnInit() {
    window.scrollTo(0,0);

    this.username=localStorage.getItem('username')
    if(localStorage.getItem('role_name')==="agent"){
      this.companyshow=false
     }
     else if(localStorage.getItem('role_name')==="owner"){
       this.companyshow=true
     }
     //fetch the data to profile form
     //get the profile data by using userId
    this.service.getmyprofile(this.global.userid).toPromise().then(res=>{
      console.log(res,"profile")
     this.profilelist = res
     console.log(this.profilelist.contact_person,"contactperson")
     if(localStorage.getItem('role_name')==="owner"){

      this.profiledata={

        company_name:this.profilelist.company_name,
        contact_person:this.profilelist.contact_person,

        address_1:this.profilelist.address_1,
        address_2:this.profilelist.address_2,
        address_3:this.profilelist.address_3,
        phone:this.profilelist.phone,
      license_no:this.profilelist.license_no,
      phone_number:this.profilelist.phone_number,
      remarks:null,
      email:this.profilelist.email
      }
     }
     else if(localStorage.getItem('role_name')==="agent"){

      this.profiledata={
        agent_name:this.profilelist.agent_name,

        agent_address:this.profilelist.agent_address,
        phone:this.profilelist.phone,
      license_no:this.profilelist.license_no,
      phone_number:this.profilelist.phone_number,
      remarks:null,
      email:this.profilelist.email
      }
     }

    })

    this.profiledata =Object.assign({},this.profilelist)
    console.log( this.profiledata,"this.profiledata")

  }
  //logout func
  //remove values from local storage and redirect to login page
  logout(){
    localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');
this.username=localStorage.getItem('username')
  this.router.navigateByUrl('/home');
  this.showlogout=false
  this.loginbtn=true
  }
  //redirect to post free ad page
  postfreead(){
    if(this.username != null){
      this.registershow=false
      this.router.navigateByUrl('/postadvertisement')
    }
    else{
     this.registershow=true
    }
  }

  //rent and sold btn func
  //update the process status
  updateprosess(profilelistails){

    this.service.updateprocessstatus(profilelistails,this.data).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.disable=true
        this.successmessage = message;
          $(this.modal1.nativeElement).modal('show');
     this.service.getmyprofile(this.global.userid).toPromise().then(res=>{
      console.log(res,"profile")
      this.profilelist = res

    })
      }
      else{
        this.errormessage = message;
          $("#errormodal").modal('show');
      }

    })
  }


///submit func for profile form
//in this func proceed based on the role name
postsubmit(){
  this.loading=true
  console.log(this.profiledata,"profileform")

  //for agent
  if(localStorage.getItem('role_name')==="agent"){
    this.http.put(this.service.rootURL+'/users/myprofile_agent_edit/'+this.id,this.profiledata).subscribe(res=>{
     var status = res['error_status']
     var message = res['error_message']
     if(status == 1){
      this.successmessage = message;
        $(this.modal1.nativeElement).modal('show');

       this.loading=false
     }
 else{

  this.errormessage = message;
    $(this.modal2.nativeElement).modal('show');
     this.loading=false
 }
    })
   }
   //for ownner
   else if(localStorage.getItem('role_name')==="owner"){
    this.http.put(this.service.rootURL+'/users/myprofile_company_edit/'+this.id,this.profiledata).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if(status == 1){
        this.successmessage = message;
          $("#successmodal").modal('show');

        this.loading=false
      }
  else{

    this.errormessage = message;
        $(this.modal2.nativeElement).modal('show');
      this.loading=false
  }
    })
   }
}
}
