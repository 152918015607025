import { NgForm } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { GlobalComponent } from 'src/app/global/global.component';

declare let $:any

@Component({
  selector: 'app-advertisement-master',
  templateUrl: './advertisement-master.component.html',
  styleUrls: ['./advertisement-master.component.css'],
  providers:[ GlobalComponent]
})
export class AdvertisementMasterComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  edit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;

  arr: any[]
  dropdownval:boolean=false
  dropdownval2:boolean=true
  property:boolean=true
  propertyval2:boolean=true
  category_id
  images:any[]=[]
  mainimage:any[]=[]
  mainfile
  approvalbtn:boolean=false
  mainthumbimage
  iscoverimg
  adid
  image
  ad
  file
  image11
  changeDetectorRef: any;
  uploadsubimage
  uploadmainimage
  approvalloading:boolean=false
  imgloading:boolean=false
  img2loading:boolean=false
  constructor(public service: NewentryService,  private ng2ImgMax: Ng2ImgMaxService,
    private pagesparent: PagesParentComponent, private router: Router,private global: GlobalComponent) { }

  ngOnInit() {
    console.log(this.global.userid,"userid")
    //delete temp record
    this.service.deleteTemp(this.global.userid)
    this.productresetForm()
    //get company list for dropdown
  this.service.getcompany()
      //get free ad list for main table
  this.service.getfreead()
      //get area list for dropdown
    this.service.getarea()
        //get categories list for dropdown
    this.service.getcategories()
        //get agent list for dropdown
    this.service.getagent()
        //get group list for dropdown
    this.service.getgroup()
        //get active subcategory list for dropdown
    this.service.getonlysubcategory()
       //get city list for dropdown
    this.service.getcitydropdown()
  }
  //ad data reset func
  productresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.postaddata = {
      category_id: null,
      subcategory_id: null,
      area_id: null,
      city_id: null,
      apporval_flag:null,
      flag:null,
      advertisement_title: null,
      advertisement_image: null,
      advertisement_description: null,
      advertisement_id:null,
      advertiser_name: null,
      advertiser_phone: null,
      advertising_period: null,
      advertiser_email: null,
      created_by: null,
    }
  }
   //insert and update func for advertisement master
postsubmit(form) {
this.loading8=true
  form.value.category_id = this.category_id
  form.value.created_by = this.global.userid
  form.value.advertising_period=parseInt(form.value.advertising_period)
  form.value.unit_id = "7361ABDC-851D-4F97-9BAA-95741C226777"
  form.value.city_id = "2C697687-21E4-4A04-9388-53773E0E45F6"
  //insert func for advertisement master
   if(form.value.advertisement_id === null){
    form.value.created_by = this.global.userid
    form.value.apporval_flag=true
    form.value.flag=true
    this.service.postfreead(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        this.service.getfreead()
        this.productresetForm(form)
       // this.images.length = 0
        // this.images.splice(this.images.length)
       this.image = ''
        //this.allImageremove()
        this.successmessage = message;
        //  $(this.modal1.nativeElement).modal('show');
          $(this.modal1.nativeElement).modal('show');
        this.edit = false
        this.loading8 = false;
        this.images.length=0
        this.mainimage.length=0
      }
      else {
        this.errormessage = message;
        //  $(this.modal1.nativeElement).modal('show');
          $(this.modal2.nativeElement).modal('show');
      }
    }

    )
   }
  //update func for advertisement master
  else{
    if(this.approvalbtn==false){
      console.log(this.approvalbtn,"btn")
      form.value.apporval_flag=true
    }
    else if(this.approvalbtn==true){
      console.log(this.approvalbtn,"btn")
      form.value.apporval_flag=false
    }

    if( form.value.flag==true){
      form.value.flag=true
    }
    else if( form.value.flag==false){
      form.value.flag=false
    }
    form.value.updated_by = this.global.userid
    console.log(form.value,"edit")
    this.service.putfreead(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.edit = false
        this.loading8 = false;
        this.approvalbtn=false
        this.service.adimglist.length=0
        this.service.admainimglist.length=0
        this.successmessage = message;
        //  $(this.modal1.nativeElement).modal('show');
          $(this.modal1.nativeElement).modal('show');
        this.service.getfreead()
        this.productresetForm(form)
       // this.images.length = 0
        // this.images.splice(this.images.length)
       this.image = ''

        //this.allImageremove()


      }
      else {
        this.errormessage = message;
        //  $(this.modal1.nativeElement).modal('show');
          $(this.modal2.nativeElement).modal('show');
      }
    }

    )
  }

}
//when category dropdown value change at that time this func trigger
category(e) {
  //get subcatergory list based on the category
  if (e == 1) {
    this.category_id = "1"

    this.service.getsubcategory(this.category_id)
  }
  else {
    this.category_id = "2"
    this.service.getsubcategory(this.category_id)
  }
}
//logout fun to remove value from local storage and redirectto login page
logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}
//cancel and reset func
cancel(form){
  this.edit = false
  this.approvalbtn=false
  this.approvalloading=false
  this.loading8=false

  this.productresetForm(form)
  this.service.adimglist.length=0
  this.service.admainimglist.length=0
 // this.images.splice(0,this.images.length)
 this.images.length=0
  this.mainimage.length=0


}
//update flag value in advertisement master list
//when click the on/off switch on the table at that time this func trigger
Flagchange(det){
  det.updated_by=this.global.userid
  this.service.putfreead(det).subscribe(res=>{
    var status = res['error_status']
    var message = res['error_message']
    if (status == 1) {
     this.successmessage = message;
       $(this.modal1.nativeElement).modal('show');
      this.service.getfreead()
      this.edit = false
      this.loading8 = false;
    }
    else {
     this.errormessage = message;
       $(this.modal2.nativeElement).modal('show');
      this.loading8 = false;

    }
  })
}
//populate the value to advertisement form from the table  when click the edit icon on the table at that time this func trigger
Populated(det){
  console.log(det,"det")
  this.edit=true
  if(det.apporval_flag===true){
    this.approvalbtn=false
  }
  else{
    this.approvalbtn=true
  }
  this.image=det.upload_image_name
  console.log(this.image,"img")
 this.adid=det.advertisement_id
 this.service.getadimage(det.advertisement_id)
  this.service.getadmainimage(det.advertisement_id)

  this.service.postaddata=Object.assign({},det)
  this.category(this.service.postaddata.category_id)
}
//in this func we approval the advertisement
approval(form){
  this.approvalloading=true
 var updated_by=Object.assign({},this.global.userid)
 form.value.apporval_flag=true
  this.service.putfreead(form.value).subscribe(res=>{
    var status = res['error_status']
    var message = res['error_message']
    if (status == 1) {
      this.successmessage = message;
      this.edit = false
  this.approvalbtn=false
  this.approvalloading=false
  this.loading8=false

  this.service.adimglist.length=0
  this.service.admainimglist.length=0
 this.images.length=0
  this.mainimage.length=0
  this.productresetForm(form)
      //  $(this.modal1.nativeElement).modal('show');
        $(this.modal1.nativeElement).modal('show');
       this.service.getfreead()
       //this.approvalloading=false
     }
     else {
      // this.pagesparent.errorModal(message);
      this.errormessage = message;
      //  $(this.modal1.nativeElement).modal('show');
        $(this.modal2.nativeElement).modal('show');
        this.approvalloading=false

     }
  })
}
//master menu list hide and show
dropdown1(){
  this.property=false
  this.propertyval2=true
  this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
this.dropdownval=false
this.dropdownval2=true
}
///property menu list hide and show
property1(){
console.log("property")
this.dropdownval=false
this.property=true
this.dropdownval2=true
this.propertyval2=false
}
property2(){
console.log("property")
this.dropdownval=false
this.property=false
this.propertyval2=true
}
//logout click func
public logoutclick(): void {
  // alert('ddd');
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}
//convert UTC date format
toDateJs(date: NgbDateStruct): Date {
  return date ?
    new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
}
//main file uloader funcv
fileChangemain(input) {
  this.imgloading=true
  const reader = new FileReader();
  this.ad=true
  if (input.files.length) {
    this.mainfile = input.files[0];
    reader.onload = () => {
     this.image = reader.result;
    }
    reader.readAsDataURL(this.mainfile);
    console.log(this.mainfile, "f2")
    if (input.files[0].size/1024/1024 > 4) {
      console.log('file is bigger than 4MB');
      this.ng2ImgMax.resizeImage(this.mainfile, 2000, 1000).subscribe(res=>{
       console.log(res,"mainimageresize")
       this.uploadmainimage=new File([res], res.name)
   })
     }
     else{
       console.log(input.files[0].size/1024/1024 + ' MB');
       this.uploadmainimage=this.mainfile
     }
    this.ng2ImgMax.resizeImage(this.mainfile, 400, 224).subscribe(
      result => {
        console.log(result, "resultimg")

        this.mainthumbimage = new File([result], result.name);
        console.log(this.mainthumbimage, "img11")
        if(this.edit===false){
          const formData = new FormData();
          this.iscoverimg = true
          this.ad=true
          formData.append('is_advertisement_image',this.ad)
          formData.append('file', this.uploadmainimage)
          formData.append('created_by', this.global.userid)
          formData.append('thumbnail_file', this.mainthumbimage)
          formData.append('is_cover_image', this.iscoverimg)
          //formData.append('file',this.file2)
          console.log(formData.get('thumbnail_file'), "formdata")
          console.log(formData.get('file'), "formdata")
          this.service.postimage(formData).subscribe(res => {
            var error_status = res['error_status'];
            var error_message = res['error_message'];
            if (error_status == "1") {
              console.log(res);
              this.imgloading=false
              this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{
                this.mainimage=res as any[]
              })
              this.service.getadmainimage(this.adid)
              // this.service.getmainimage(this.productId)
            }
            else {
              alert(error_message)
              this.imgloading=false
            }
          })

        }
        else{
          const formData = new FormData();
          this.iscoverimg = true
          this.ad=true
          formData.append('is_advertisement_image',this.ad)
          formData.append('file', this.uploadmainimage)
          formData.append('created_by', this.global.userid)
          formData.append('thumbnail_file', this.mainthumbimage)
          formData.append('advertisement_id',this.adid)
          formData.append('is_cover_image', this.iscoverimg)
          //formData.append('file',this.file2)
          console.log(formData.get('thumbnail_file'), "formdata")
          console.log(formData.get('file'), "formdata")
          this.service.postimage(formData).subscribe(res => {
            var error_status = res['error_status'];
            var error_message = res['error_message'];
            if (error_status == "1") {
              console.log(res,'edit');
              this.imgloading=false
              this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{
                this.mainimage=res as any[]
              })
            }
            else {
              alert(error_message)
              this.imgloading=false
            }
          })
        }

      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );


  }
}

//sub file uploader func
fileChange(input){
  this.img2loading=true
  this.readFiles(input.files);
  if (input.files.length) {
      this.file = input.files[0];
      console.log(this.file, "f2")
      if (input.files[0].size/1024/1024 > 4) {
        console.log('file is bigger than 4MB');
        this.ng2ImgMax.resizeImage(this.file, 2000, 1000).subscribe(res=>{

          this.uploadsubimage=new File([res], res.name)
     })
       }
       else{
         console.log(input.files[0].size/1024/1024 + ' MB');
         this.uploadsubimage=this.file
       }
      this.ng2ImgMax.resizeImage(this.file, 400, 224).subscribe(
        result => {
          console.log(result,"resultimg")

         this.image11 =  new File([result], result.name);
          console.log(this.image11,"img11")

  if(this.edit===true){
    const formData = new FormData();
    this.iscoverimg=false
    this.ad=true
    formData.append('is_advertisement_image',this.ad)
    formData.append('file',  this.uploadsubimage)
    formData.append('created_by', this.global.userid)
    formData.append('advertisement_id',this.adid)
    formData.append('thumbnail_file',this.image11)
    formData.append('is_cover_image',this.iscoverimg)
    //formData.append('file',this.file2)
    console.log(formData.get('thumbnail_file'), "formdata")
    console.log(formData.get('file'), "formdata")
    this.service.postimage(formData).subscribe(res => {
      var error_status = res['error_status'];
      var error_message = res['error_message'];
      if (error_status == "1") {
        console.log(res,"update");
        this.img2loading=false
      this.service.getadimage(this.adid)
       // this.pagesparent.successModal('Saved successfully');
      }
      else {
      // this.pagesparent.errorModal(error_message);
      this.errormessage = error_message;
      this.img2loading=false
      //  $(this.modal1.nativeElement).modal('show');
        $(this.modal2.nativeElement).modal('show');
      }
    })

  }
  else{
    const formData = new FormData();
    formData.append('file',  this.uploadsubimage)
    this.ad=true
    formData.append('is_advertisement_image',this.ad)
    formData.append('created_by', this.global.userid)
    formData.append('thumbnail_file',this.image11)
    //formData.append('file',this.file2)
    console.log(formData.get('thumbnail_file'), "formdata")
    console.log(formData.get('file'), "formdata")
    this.service.postimage(formData).subscribe(res => {
      var error_status = res['error_status'];
      var error_message = res['error_message'];
      if (error_status == "1") {
        console.log(res);
        this.img2loading=false
        this.service.getuploadimage(this.global.userid).toPromise().then(res=>{
          console.log(res,"img")
          this.images=res as any[]
        })

       // this.pagesparent.successModal('Saved successfully');
      }
      else {
      // this.pagesparent.errorModal(error_message);
      this.errormessage = error_message;
      this.img2loading=false
      //  $(this.modal1.nativeElement).modal('show');
        $(this.modal2.nativeElement).modal('show');
      }
    })
  }

        },
        error => {
          console.log('😢 Oh no!', error);
        }
      );


    }
}
//previev main file func
readFile(file, reader, callback){
  reader.onload = () => {
      callback(reader.result);
  }
  reader.readAsDataURL(file);
}
//read the uploaded files
readFiles(files, index=0){
  let reader = new FileReader();

  if (index in files){
      this.readFile(files[index], reader, (result) =>{

          this.readFiles(files, index+1);
      });
  }else{
      this.changeDetectorRef.detectChanges();
  }
}
//remove sub image in edit mode
removeImage(id):void{

  this.service.deletesingle(id).toPromise().then(res=>{
    console.log('delete')
  })
  //get uploaded sub images
  this.service.getuploadimage(this.global.userid).toPromise().then(res=>{
    this.images=res as any[]
  })
}
//remove all images
allImageremove(){
console.log("allimg_delete_call")
}
//delete sub image in edit mode
deleteImage(imgdetails){
this.service.deleteimage(imgdetails.upload_image_id).subscribe(res=>{
  console.log(imgdetails,"imsd")
  this.service.getadimage(this.adid)

})
}
//remove main image in entry mode
removemainImage(id){

 this.service.deletesinglemainimage(id).toPromise().then(res=>{
   console.log('delete')
 })
 this.service.getuploadmainimg(this.global.userid).toPromise().then(res=>{
  this.mainimage=res as any[]
 })
}
//delete main image in edit mode
deletemainImage(imgdetails){
console.log(imgdetails,"deleteMainimg")
this.service.deleteimage(imgdetails.upload_image_id).subscribe(res=>{
  //getmain image
  this.service.getadmainimage(this.adid)
})
}
}
