import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newproperty',
  templateUrl: './newproperty.component.html',
  styleUrls: ['./newproperty.component.css']
})
export class NewpropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
