import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    console.log(localStorage.getItem('token'));
    if (localStorage.getItem('token') != null) {
      return true;
    }else{ 
      if(localStorage.getItem('role_id')==="SADM"){
        this.router.navigate(['/admin']);
        return false;
      }
      else{
        alert('Section is experied please relogin!')
      }
     }
  }
  
}
