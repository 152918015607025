
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Currencies } from './currencies.model';
import { Languages } from './languages.model';
import { Countries } from './countries.model';
import { States } from './states.model';
import { Cities } from './cities.model';
import { Areas } from './areas.model';
import { Categories } from './categories.model';
import { CountryLanguages } from './country-languages.model';
import { User } from './user.model';
import { Agent } from './agent.model';
import { Group } from './group.model';
import { Subcategory } from './subcategory.model';
import { Unit } from './unit.model';
import { Company } from './company.model';
import { Product } from './product.model';
import { Router } from '@angular/router';
import { Identifiers } from '@angular/compiler';
import { PostAd } from './post-ad.model';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class NewentryService {


//readonly rootURL = "http://aaadmin.myappss.net/api"
// readonly rootURL = "https://localhost:5001/api"
// readonly imgURL = "https://localhost:5001/"
readonly rootURL = environment.baseUrls
//readonly rootURL = "https://localhost:44310/api"
 //readonly imgURL = "https://localhost:44310/"
 // readonly rootURLSignalr = "https://localhost:44314/"


  // securitymaster devadmin.netcore.adwayit.in

  currencydata:Currencies;
  currencylist:Currencies[]
  languagedata:Languages
  languagelist:Languages[]
  countrydata:Countries
  countrylist:Countries[]
  statedata:States
  statelist:States[]
  citiesdata:Cities
  citieslist:Cities[]
  areadata:Areas
  arealist:Areas[]
  categoriesdata:Categories
  categorieslist:Categories[]
  countryLanguageslist:CountryLanguages[]
  userdata:User
  userlist:User[]
  agentdata:Agent;
  agentlist:Agent[]
  groupdata:Group
  groupList:Group[]
  subcategoryData:Subcategory
  subcategoryList:Subcategory[]
  unitdata:Unit
  unitList:Unit[]
  multiselectlanguageList
  dropdowncurrencyList
  dropdownlanguageList
  dropdowncountryList
  dropdownstateList
  dropdowncityList
  companydata:Company
  companyList:Company[]
  productdata:Product
  productlist:Product[]
  imglist
  mainimglist
  dwellingtypelist
  particularcategorieslist
  homesearchlist:any[]
  roomdetailslist
  roomimglist
  homepagecategoriesviewlist
  homesearchdata
  onlysubcategoryList
  home:boolean=false
 postaddata:PostAd
 freeadlist
 subcategorybasedcategoryList
 ipAddress
 freeadlistrecommented:any
 adimglist
 admainimglist
 rolelist
 url3 = "/assets/js/select-menu.js"
 subcategoryListForRent:any[]
 subcategoryListForSale:any[]
  constructor(private http: HttpClient,private router:Router,private ng2ImgMax: Ng2ImgMaxService) { }


  getuser() {
    this.http.get(this.rootURL + '/users/getalluserslist')
      .toPromise().then(res => this.userlist = res as User[]);
  }

  postuser(userdata) {
   return this.http.post(this.rootURL + '/users/admin_register',userdata)

  }

  putuser(userdata) {
    return this.http.put(this.rootURL + '/users/edituser/'+userdata.user_id,userdata)

   }
   resetPassword(userdata) {
    return this.http.put(this.rootURL + '/users/resetpassword/'+userdata.user_id,userdata)

   }
   newresetPassword(userdata) {
    return this.http.put(this.rootURL + '/users/resetpasswordusers/'+userdata.user_id,userdata)

   }

 updateFlag(userdata){
  return this.http.put(this.rootURL + '/users/edituserflag/'+userdata.user_id,userdata)
 }

 //.............................currencies.............................................//

 getcurrencies(){
   this.http.get(this.rootURL+'/currencies/getallcurrencies').toPromise().then(res=>{
     this.currencylist = res as Currencies[]
   })
 }

 postcurrencies(currencydata){
return this.http.post(this.rootURL+'/currencies',currencydata)
 }
 putcurrencies(currencydata){
  return this.http.put(this.rootURL+'/currencies/'+currencydata.currency_id,currencydata)
   }


   ///////////////////////////////////////////languages////////////////////////

   getlanguage(){
    this.http.get(this.rootURL+'/languages/getalllanguages').toPromise().then(res=>{
      this.languagelist = res as Languages[]
    })
  }

  postlanguage(languagedata){
 return this.http.post(this.rootURL+'/languages',languagedata)
  }
  putlanguage(languagedata){
   return this.http.put(this.rootURL+'/languages/'+languagedata.language_id,languagedata)
    }

    //////////////////multi select language///////////////////////////////

    getmultiselectlanguage(){
      this.http.get(this.rootURL+'/languages/getlanguages').toPromise().then(res=>{
        console.log(res,"multilanguage")
      this.multiselectlanguageList = res
      })
    }

    ///////////////////country/////////////////////////////////////

    getcountry(){
      this.http.get(this.rootURL+'/countries/getallcountries').toPromise().then(res=>{
        console.log(res,"countrylist")
        this.countrylist=res as Countries[]
      })
    }
    postcountry(countrydata){
     return this.http.post(this.rootURL+'/countries',countrydata)
    }
    putcountry(countrydata){

      return this.http.put(this.rootURL+'/countries/'+countrydata.country_id,countrydata)
     }

     //////////////////////////////////////state////////////////////////////////////

     getstate(){
      this.http.get(this.rootURL+'/states/getallstates').toPromise().then(res=>{
        console.log(res,"statelist")
        this.statelist=res as States[]
      })
     }

     poststate(statedata){
      return this.http.post(this.rootURL+'/states',statedata)
     }
     putstate(statedata){
       return this.http.put(this.rootURL+'/states/'+statedata.state_id,statedata)
      }


      //////////////////////////////cities///////////////////////////////////


      getcities(){
        this.http.get(this.rootURL+'/cities/getallcities').toPromise().then(res=>{
          console.log(res,"citieslist")
          this.citieslist=res as Cities[]
        })
       }

       postcities(citiesdata){
        return this.http.post(this.rootURL+'/cities',citiesdata)
       }
       putcities(citiesdata){
         return this.http.put(this.rootURL+'/cities/'+citiesdata.city_id,citiesdata)
        }

        ////////////////////////////////////////area///////////////////////////////////////
        getarea(){
          this.http.get(this.rootURL+'/areas/getallareas').toPromise().then(res=>{
            console.log(res,"areas")
            this.arealist=res as Areas[]
          })
        }
        getautocompletearea(){
         return this.http.get(this.rootURL+'/areas')
        }

        postarea(areadata:Areas){
          return this.http.post(this.rootURL+'/areas',areadata)
        }
        putarea(areadata:Areas){
          return this.http.put(this.rootURL+'/areas/'+areadata.area_id,areadata)
        }

        //////////////////////////////////////////categories/////////////////////
  getparticularcategories(id){
    this.http.get(this.rootURL+'/categories/getallcategories/'+id).toPromise().then(res=>{
      console.log(res,"areas")
      this.particularcategorieslist=res as Categories[]
    })
  }

        getcategories(){
          this.http.get(this.rootURL+'/categories/getallcategories').toPromise().then(res=>{
            console.log(res,"areas")
            this.categorieslist=res as Categories[]
          })
        }

        postcategories(categoriesdata:Categories){
          return this.http.post(this.rootURL+'/categories',categoriesdata)
        }
        putcategories(categoriesdata:Categories){
          return this.http.put(this.rootURL+'/categories/'+categoriesdata.category_id,categoriesdata)
        }

/////////////////////////////////////////agent///////////////////////////////////////////////////

getagent(){
  this.http.get(this.rootURL+'/agents/getallagents').toPromise().then(res=>{
    console.log(res,"agent")
    this.agentlist=res as Agent[]
  })
}

postagent(agentdata:Agent){
return this.http.post(this.rootURL+'/agents',agentdata)
}

putagent(agentdata:Agent){
  return this.http.put(this.rootURL+'/agents/'+agentdata.agent_id,agentdata)
  }

  ////////////////////////dropdownList////////////////////////

  getcurrencydropdown(){
    this.http.get(this.rootURL+'/currencies').toPromise().then(res=>{
      console.log(res,"currency")
      this.dropdowncurrencyList = res
    })
  }
  getlanguagedropdown(){
    this.http.get(this.rootURL+'/languages').toPromise().then(res=>{
      this.dropdownlanguageList = res
    })
  }
  getcountrydropdown(){
    this.http.get(this.rootURL+'/countries').toPromise().then(res=>{
      this.dropdowncountryList = res
    })
  }
  getstatedropdown(){
    this.http.get(this.rootURL+'/states').toPromise().then(res=>{
      this.dropdownstateList = res
    })
  }
  getcitydropdown(){
    this.http.get(this.rootURL+'/cities').toPromise().then(res=>{
      this.dropdowncityList = res
    })
  }
/////////////////////////////group master/////////////////////////////////////////////////

getgroup(){
  this.http.get(this.rootURL+'/groups/getallgroups').toPromise().then(res=>this.groupList=res as Group[])
}
postgroup(groupdata:Group){
  return this.http.post(this.rootURL+'/groups',groupdata)
}
putgroup(groupdata:Group){
  return this.http.put(this.rootURL+'/groups/'+groupdata.group_id,groupdata)
}
//////////////////////////////unit///////////////////////////////////////////////////////////
getunit(){
  this.http.get(this.rootURL+'/units/getallunits').toPromise().then(res=>{
    this.unitList=res as Unit[]
  })
}
postunit(unitdata:Unit){
  return this.http.post(this.rootURL+'/units',unitdata)
}
putunit(unitdata:Unit){
  return this.http.put(this.rootURL+'/units/'+unitdata.unit_id,unitdata)
}

//////////////////////////////////////subcategory master/////////////////////////////////////
getonlysubcategory(){
  return this.http.get(this.rootURL+'/subcategories')
}
getsubcategory(id){
  this.http.get(this.rootURL+'/subcategories/getsubcategoriesbycategory/'+id).toPromise().then(res=>{
    console.log(res,"subc")

    this.subcategoryList=res as Subcategory[]

  })
}
getsubcategory2(id){
  return this.http.get(this.rootURL+'/subcategories/getsubcategoriesbycategory/'+id)
}
postsubcategory(subcategoryData:Subcategory){
  return this.http.post(this.rootURL+'/subcategories',subcategoryData)
}
putsubcategory(subcategoryData:Subcategory){
  return this.http.put(this.rootURL+'/subcategories/'+subcategoryData.subcategory_id,subcategoryData)
}


//////////////////////////////////////company master///////////////////////////////////
getcompanylist(){
  this.http.get(this.rootURL+'/companies/getallcompanies').toPromise().then(res=>{
    console.log(res,"company")
    this.companyList = res as Company[]
  })
}
getcompany(){
  this.http.get(this.rootURL+'/companies/getallcompanies').toPromise().then(res=>{
    console.log(res,"company")
    this.companyList = res as Company[]
  })
}
postcompany(companydata:Company){
  return this.http.post(this.rootURL+'/companies',companydata)
}
putcompany(companydata:Company){
  return this.http.put(this.rootURL+'/companies/'+companydata.company_id,companydata)
}


////////////////////////////////////////product/////////////////////////////
getproduct(){
  this.http.get(this.rootURL+'/products/getallproducts').toPromise().then(
    res=>{
      console.log(res,"product")
      this.productlist=res as Product[]
    }
  )
}
postproduct(productdata){
  return this.http.post(this.rootURL+'/products',productdata)
}
putproduct(productdata){
  return this.http.put(this.rootURL+'/products/'+productdata.product_id,productdata)
}

///////////////////////////delete temp /////////////////////////////////////////
deleteTemp(id){
  this.http.delete(this.rootURL+'/imagefileuploads/deletetemporyunwatedfiles/'+id).subscribe(res=>{
    console.log("delete")
  })
}

/////////////////////////////////////img upload/////////////////////////////////

postimage(imgdata){
return this.http.post(this.rootURL+'/imagefileuploads',imgdata)
}

getimage(id){
  this.http.get(this.rootURL+'/products/getdetailviewthumbnailimage/'+id+'/0').toPromise().then(res=>{
    console.log(res,"imagelistedit")
    this.imglist=res
  })
}

getadimage(id){
  this.http.get(this.rootURL+'/advertisements/getdetailviewthumbnailimage/'+id+'/0').toPromise().then(res=>{
    console.log(res,"imagelistedit")
    this.adimglist=res
  })
}

getmainimage(id){
  this.http.get(this.rootURL+'/products/getdetailviewthumbnailimage/'+id+'/1').toPromise().then(res=>{
    console.log(res,"imagelistedit")
    this.mainimglist=res
  })
}

getadmainimage(id){
  this.http.get(this.rootURL+'/advertisements/getdetailviewthumbnailimage/'+id+'/1').toPromise().then(res=>{
    console.log(res,"imagelistedit")
    this.admainimglist=res
  })
}
deleteimage(id){
  return this.http.delete(this.rootURL+'/imagefileuploads/'+id)
}


////////////////////////get dwelling_type/////////////////////////////////////////////////////////

getdwellingtype(){
  this.http.get(this.rootURL+'/dwellingtypes').toPromise().then(res=>{
    this.dwellingtypelist = res
  })
}

///////////////////////////////////////////home search//////////////////////////////////////////////
posthomesearch(productdata,area){
  this.homesearchdata=productdata
  console.log(productdata,"pdata")

    this.home=true
    var locationid = encodeURIComponent(btoa(productdata.location_id))

    var catid = encodeURIComponent(btoa(productdata.category_id))
    var subcatid = encodeURIComponent(btoa(productdata.sub_category_id))
    var areaname = encodeURIComponent(btoa(area))
   this.router.navigate(['/propertylist/',locationid,catid,subcatid,areaname])


}
posthomesearchonly(productdata){
  this.homesearchdata=productdata
 return this.http.post(this.rootURL+'/products/homepagesearch/10',productdata)
}
////////////////////////////////////////////////get room details/////////////////////////////////////////
getroomdetails(id){


    this.router.navigate(['/roomdetail',encodeURIComponent(btoa(id))])

}
getroomimg(id){

  this.http.get(this.rootURL+'/products/samplecheck/'+id).toPromise().then(res=>{
    console.log(res,"roomimglist")
    this.roomimglist=res

  })
}

/////////////////////////////////////homepagecategoriesview////////////////////////
gethomepagecategoriesview(){
  this.http.get(this.rootURL+'/subcategories/homepagecategoriesview').toPromise().then(res=>{
    this.homepagecategoriesviewlist=res
    console.log(this.homepagecategoriesviewlist,"hcv")
  })
}

///////////////////////////////////////////register(signup)////////////////////////////////////////////
postregister(data){
  return this.http.post(this.rootURL+'/users/register',data)
}

/////////////////////////////////////////////signin////////////////////////////////////////////////////
postsignin(data){
  return this.http.post(this.rootURL+'/users/login',data)
}
getautocomplete(){
  return this.http.get('')
}

///////////////////////////////////////////////agentcompany//////////////////////////////////////////////////

postagentcompany(data){
  return this.http.post(this.rootURL+'/users/register',data)
}
/////////////////////////////searchby subcategory ////getlist//////////////////
getsearchlistbysubid(id,catid){
  localStorage.setItem('category_id',catid)
  localStorage.setItem('subcategory', id)
  var locationid=null
  var location = null

   this.router.navigate(['/propertylist/',encodeURIComponent(btoa(locationid)), encodeURIComponent(btoa(catid)), encodeURIComponent(btoa(id)),encodeURIComponent(btoa(location))])

  // })
}


//////////////////////////////////////post free ad//////////////////////////////////////////

getfreead(){
  this.http.get(this.rootURL+'/advertisements/getalladvertisement').toPromise().then(res=>{
    console.log(res,"freead")
    this.freeadlistrecommented = res
  })
}
postfreead(postaddata){
  return this.http.post(this.rootURL+'/advertisements',postaddata)
}
putfreead(postaddata){
  return this.http.put(this.rootURL+'/advertisements/'+postaddata.advertisement_id,postaddata)
}
//////////////////////////////////////////GetRecommended Advirtisement///////////////////////////
getrecommentedad(catid,subid){

  this.http.get(this.rootURL+'/advertisements/getrecommended/'+catid+'/'+subid).toPromise().then(res=>{
    console.log(res,"freeadrecommented")
    this.freeadlist = res
  })
}
/////////////////////////////////myprofile get /////////////////////////////////////////////////////
getmyprofile(id){
  if(localStorage.getItem('role_name')==="agent"){
    return  this.http.get(this.rootURL+'/agents/myprofiledetails/'+id)
  }
  else if(localStorage.getItem('role_name')==="owner"){
    return  this.http.get(this.rootURL+'/companies/myprofiledetails/'+id)
  }
}
////////////////////////get dwelling_type/////////////////////////////////////////////////////////

adapproval(data,id){
  return this.http.put(this.rootURL+'/advertisements/apporveadvertisements/'+id,data)
}
agentapproval(data,id){
 return this.http.put(this.rootURL+'/agents/apporveagent/'+id,data)
}
companyapproval(data,id){
 return this.http.put(this.rootURL+'/companies/apporvecompany/'+id,data)
}
getsubcategorybasedcategory(id){
  this.http.get(this.rootURL+'/subcategories/getsubcategoriesbycategory/'+id).toPromise().then(res=>{
    console.log(res,"subc")
    this.subcategorybasedcategoryList=res
  })

}
propertyclickevent(data){
  console.log(data,"data")
  return this.http.post(this.rootURL+'/propertyclickcounts',data)
}



//////////////////////////Delete Single file/////////////////////////////////////

deletesingle(id){
  console.log("delete call")
return this.http.delete(this.rootURL+'/imagefileuploads/'+id)
}
getuploadimage(id){
  return this.http.get(this.rootURL+'/products/gettemporythumbnailimage/'+id+'/0')
}
getuploadmainimg(id){
  return this.http.get(this.rootURL+'/products/gettemporythumbnailimage/'+id+'/1')
}
deletesinglemainimage(id){
  return this.http.delete(this.rootURL+'/imagefileuploads/'+id)
}

///////////////////////////post enquiry form//////////////////////////////////////////////
postenquiry(data){
return this .http.post(this.rootURL+'/productenquiries',data)
}

/////////////////////////////////upadateprocessstatus///////////////////////////////////////

updateprocessstatus(data,updated_by){
  return this.http.put(this.rootURL+'/products/upadateprocessstatus/'+data.product_id,updated_by)
}

/////////////////////////////////////////////////otp////////////////////////

postotp(data){
  console.log(data,"otp")
  return this.http.post(this.rootURL+'/users/forgotpasswordotpsend',data)
}

//////////////////////////////////////forgetpassword/////////////////////////////////////////////

postforget(data){
  return this.http.post(this.rootURL+'/users/verifyotp',data)
}

///////////////////////////////////get role////////////////////////////////////////////////////////

getrole(){
  this.http.get(this.rootURL+'/aspnetroles/sigupformroles').toPromise().then(res=>{
    console.log(res,"rolelist")
    this.rolelist=res
  })
}
public loadScript3() {
  console.log("preparing to load...");
  let node = document.createElement("script");
  node.src = this.url3;
  node.type = "text/javascript";
  node.async = true;
  node.charset = "utf-8";
  document.getElementsByTagName("head")[0].appendChild(node);
}
///////////////////////////////////////////////MY PROFILE EDIT//////////////////////////////


}
