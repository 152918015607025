import { NgForm } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { PagesParentComponent } from '../../../pages-parent/pages-parent.component';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';
declare let $ :any;


@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class GroupComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;
  
  arr: any[]
  dropdownval:boolean=false
  dropdownval2:boolean=true
  property:boolean=true
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent,
    private router: Router,private global: GlobalComponent) { }

  ngOnInit() {
    this.groupresetForm()
    this.service.getgroup()
  }
  groupresetForm(form?:NgForm){
    if (form != null)
    form.resetForm()
    this.service.groupdata = {
      group_id :null,
      group_code :null,
      group_name :null,
      group_description: null,
      created_by:null,
      created_date:null,
      updated_by:null,
      updated_date :null,
      flag :null,
    }
  }
  groupcancel(groupform){
    this.groupedit=false
    this.groupresetForm(groupform)
  }
  groupsubmit(groupform){
    console.log(groupform.value,"gform")
   
    this.loading9=true
    if(groupform.value.group_id===null){
      groupform.value.flag=true
      groupform.value.created_by=this.global.userid
      this.service.postgroup(groupform.value).subscribe(res=>{
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
         // this.pagesparent.successModal('Insert successfully');
         this.successmessage = message;
         //  $(this.modal1.nativeElement).modal('show');
           $(this.modal1.nativeElement).modal('show'); 
          this.service.getgroup()
          this.groupresetForm(groupform)
          this.groupedit = false
          this.loading9 = false;
        }
        else {
         // this.pagesparent.errorModal(message);
         this.errormessage = message;
         //  $(this.modal1.nativeElement).modal('show');
           $(this.modal2.nativeElement).modal('show'); 
          this.loading9 = false;
  
        }
      })
    }
   else{
    groupform.value.updated_by=this.global.userid
    if(groupform.value.flag==true){
      groupform.value.flag=true
    }
    else if(groupform.value.flag==false){
      groupform.value.flag=false
    }
    this.service.putgroup(groupform.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
       // this.pagesparent.successModal('Update successfully');
       this.successmessage = message;
       //  $(this.modal1.nativeElement).modal('show');
         $(this.modal1.nativeElement).modal('show'); 
          this.service.getgroup()
          this.groupresetForm(groupform)
        this.groupedit = false
        this.loading9 = false;
      }
      else {
       // this.pagesparent.errorModal(message);
       this.errormessage = message;
       //  $(this.modal1.nativeElement).modal('show');
         $(this.modal2.nativeElement).modal('show'); 
        this.loading9 = false;

      }
    })
   }
  }
  changegroupflag(det){
    det.updated_by=this.global.userid
    this.service.putgroup(det).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
       // this.pagesparent.successModal('Update successfully');
       this.successmessage = message;
       //  $(this.modal1.nativeElement).modal('show');
         $(this.modal1.nativeElement).modal('show'); 
        this.service.getgroup()
        this.groupedit = false
        this.loading9 = false;
      }
      else {
       // this.pagesparent.errorModal(message);
       this.errormessage = message;
       //  $(this.modal1.nativeElement).modal('show');
         $(this.modal2.nativeElement).modal('show'); 
        this.loading9 = false;

      }
    })
  }
  groupPopulated(det){
    this.groupedit=true
    this.service.groupdata = Object.assign({},det)
  }
  dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}

public logout(): void {
  // alert('ddd');
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}



}
