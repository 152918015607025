import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NewentryService } from 'src/app/shared/newentry.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../global/global.component';
declare let $:any

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers:[GlobalComponent]

})
export class UserManagementComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  loading:boolean=false
  edit:boolean=false
  update:boolean=true
  show:boolean=true
  resetPwd:boolean=false
  cnfrmPwd:boolean=true
  key:boolean=false
  term2:string;
  Page:number=1
  dropdownval:boolean=false
  dropdownval2:boolean=true
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service:NewentryService,private toastr:ToastrService,private router: Router
    ,private global: GlobalComponent) { }

  ngOnInit() {
    //get users list
    this.service.getuser()
    this.resetform()
  }
  //reset func
  resetform(form?:NgForm){
    if(form !=null )
    form.resetForm()
    this.service.userdata={
      user_id:null,
      user_name:null,
      email:null,
      role_id:null,
      role_name:null,
      created_by:null,
      updated_by:null,
      created_date:null,
      updated_date:null,
      password:null,
confirmpassword:null,
      flag: null
  }
}
//update flag value in main list by using on/off switc in the table
  changeflag(det){
    console.log(det)
    det.updated_by=this.global.userid
    this.service.updateFlag(det).subscribe(res=>{
      var status=res['error_status']
      var message=res['error_message']
      if(status==1){
        this.toastr.success('Flag update successfully!')
      }
     else{
      this.toastr.error('Flag', 'Update failed.');
     }
    })
  }
  //insert and update func for users
  onsubmit(form){
    this.loading=true

    form.value.created_by=this.global.userid
    //insert func
    if(form.value.user_id==null){
      form.value.created_by=this.global.userid
      form.value.flag=true
      this.service.postuser(form.value).subscribe(res=>{
        var status=res['error_status']
        var message=res['error_message']
        if(status==1){
          this.successmessage = message;
            $(this.modal1.nativeElement).modal('show');
          this.loading=false
          this.edit=false
         this.update=true
          this.show=true
          this.resetPwd=false
          this.cnfrmPwd=true
          this.key=false
          this.resetform(form)
          this.service.getuser()
        }
       else{
        this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
        this.loading = false;
       }
      })
    }
    //update func
    else{
      form.value.updated_by=this.global.userid
      if(form.value.flag==true){
        form.value.flag=true
      }
      else if(form.value.flag==false){
        form.value.flag=false
      }
      this.service.putuser(form.value).subscribe(res=>{
        var status=res['error_status']
        var message=res['error_message']
        if(status==1){
          this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.loading=false
      this.edit=false
     this.update=true
      this.show=true
      this.resetPwd=false
      this.cnfrmPwd=true
      this.key=false
          this.resetform(form)
          this.service.getuser()
        }
       else{
        this.errormessage = message;
          $(this.modal2.nativeElement).modal('show');
        this.loading = false;
       }
      })
    }
    }
        //when click edit icon on the table at that time this func trigger
  //in this func we populated the value of state form fields from the table list
    populated(det){
      console.log(det)
      this.edit=true
      this.update=false
     this.key=false
      this.show=true
      this.service.userdata=Object.assign({},det)
    }
    //when click key icon on the table at that time this func trigger
  //in this func we populated the value of  form fields from the table list and password field is visible
    onresetkey(det){
      this.resetPwd=true
      this.show=false
      this.update=false
      this.key=true
      this.edit=false
      this.service.userdata=Object.assign({},det)
    }
    //reset password func
    ResetPassword(form){
      this.loading=true
      this.service.resetPassword(form.value).subscribe(res=>{
        var status=res['error_status']
        var message=res['error_message']
        if(status==1){
          this.successmessage = message;
           $(this.modal1.nativeElement).modal('show');
          this.loading=false
          this.edit=false
         this.update=true
          this.show=true
          this.resetPwd=false
          this.cnfrmPwd=true
          this.key=false
          this.resetform(form)
        }
       else{
        this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
        this.loading=false
       }
      })
    }
    //cancel and reset func
    oncancel(form){
      this.loading=false
      this.edit=false
     this.update=true
      this.show=true
      this.resetPwd=false
      this.cnfrmPwd=true
      this.key=false
      this.resetform(form)
    }
 //masters menu list hide and show
    dropdown1(){
      this.property=false
      this.propertyval2=true
      this.dropdownval=true
  this.dropdownval2=false
  }
  dropdown2(){
    this.dropdownval=false
    this.dropdownval2=true
  }
   //property menu list hide and show
  property1(){
    console.log("property")
    this.dropdownval=false
    this.property=true
    this.dropdownval2=true
    this.propertyval2=false
  }
  property2(){
    console.log("property")
    this.dropdownval=false
    this.property=false
    this.propertyval2=true
  }
     //logout click func
//remove values from local storage and redirect to login page
public logout(): void {
  // alert('ddd');
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
