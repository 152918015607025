import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { FooterComponent } from './footer/footer.component';
import { ForgetComponent } from './forget/forget.component';
import { HeaderComponent } from './header/header.component';
import { ListyourplaceComponent } from './listyourplace/listyourplace.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { NewpropertyComponent } from './newproperty/newproperty.component';
import { PagesComponent } from './pages/pages.component';
import { PostaddComponent } from './postadd/postadd.component';
import { PostadvertisementComponent } from './postadvertisement/postadvertisement.component';
import { PostpropertyComponent } from './postproperty/postproperty.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { PropertyComponent } from './super-admin/property/property/property.component';
import { RoomDetailComponent } from './room-detail/room-detail.component';
import { UnitComponent } from './super-admin/property/unit/unit.component';
import { UserManagementComponent } from './super-admin/user-management/user-management.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

const routes: Routes = [
  {path: '', redirectTo: '/home' , pathMatch: 'full'},
  {path: 'propertylist/:id/:id1/:id2/:id3' , component: PropertyListComponent},
  {path: 'propertylist' , component: PropertyListComponent},
  {path: 'roomdetail/:id' , component: RoomDetailComponent},
  {path: 'roomdetail' , component: RoomDetailComponent},
  {path: 'home' , component: PagesComponent},
  {path: 'pages' , component: PagesComponent},
  {path: 'forget' , component: ForgetComponent},
  {path: 'newproperty' , component: NewpropertyComponent},
  {path: 'postadd' , component: PostaddComponent},
  {path: 'propertypost' , component: PostpropertyComponent},
  {path: 'postadvertisement', component: PostadvertisementComponent},
  {path: 'myprofile' , component: MyprofileComponent},
 {path: 'unit' , component: UnitComponent, canActivate: [AuthGuard]},
 {path: 'property' , component: PropertyComponent, canActivate: [AuthGuard]},
 {path: 'users' , component: UserManagementComponent, canActivate: [AuthGuard]},
 {path: 'changepassword' , component: ChangepasswordComponent , canActivate: [AuthGuard]},
 {path: 'usermenu' , component: UserMenuComponent},
 {path: 'header' , component: HeaderComponent},
 {path: 'footer' , component: FooterComponent},
 {path: 'listyourplace' , component: ListyourplaceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
   useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
