import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalComponent } from '../../../global/global.component';
import { NewentryService } from '../../../shared/newentry.service';
declare let $:any

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css'],
  providers:[ GlobalComponent]
})
export class AreaComponent implements OnInit {

  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;
  dropdownval:boolean=true
  dropdownval2:boolean=true
  arr: any[]
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service: NewentryService, private router: Router,private global: GlobalComponent) { }

  ngOnInit() {

    this.arearesetForm()


    //get city list for dropdown
    this.service.getcities()
    //get area list for dropdown
    this.service.getarea()

    //get list for city dropdown
    this.service.getcitydropdown()
  }
  //reset func for area form
  arearesetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.areadata = {
      area_id: null,
      city_id: null,
      area_code: null,
      area_name: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,
      flag: null,
    }
  }
  //cancel and reset func
  onareacancel(areaform) {
    this.arearesetForm(areaform)
    this.loading6 = false
    this.areaedit = false
  }
  //when click edit icon on the table at that time this func trigger
  //values were populated to forms field fromthe table
  areaPopulated(det) {
    this.areaedit = true
    this.service.areadata = Object.assign({}, det)
    this.loading6 = false;
  }
  //insert and update func
  areasubmit(areaform) {
    this.loading6 = true;
    console.log(areaform.value, "area")
   //insert func
    if (areaform.value.area_id == null) {
      areaform.value.flag = true;
      areaform.value.created_by=this.global.userid
      this.service.postarea(areaform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          this.successmessage = message;
          //  $(this.modal1.nativeElement).modal('show');
            $(this.modal1.nativeElement).modal('show');
          this.arearesetForm(areaform)
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.service.getarea()
          this.areaedit = false
          this.loading6 = false;
        }
        else {
          this.errormessage = message;
     //  $(this.modal1.nativeElement).modal('show');
       $(this.modal2.nativeElement).modal('show');
          this.loading6 = false;
        }
      })

    }
    //update func
    else {
      areaform.value.updated_by=this.global.userid
      if(  areaform.value.flag==true){
        areaform.value.flag=true
      }
      else if(  areaform.value.flag==false){
        areaform.value.flag=false
      }
      this.service.putarea(areaform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          this.successmessage = message;
     //  $(this.modal1.nativeElement).modal('show');
       $(this.modal1.nativeElement).modal('show');
          this.arearesetForm(areaform)
          this.service.getarea()
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.areaedit = false
          this.loading6 = false;
        }
        else {
          this.errormessage = message;
     //  $(this.modal1.nativeElement).modal('show');
       $(this.modal2.nativeElement).modal('show');
          this.loading6 = false;
        }
      })
    }
  }
  //update flag value in the table
  changeareaflag(det) {
    det.updated_by=this.global.userid
    this.service.putarea(det).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        this.successmessage = message;
     //  $(this.modal1.nativeElement).modal('show');
       $(this.modal1.nativeElement).modal('show');
        this.service.getarea()
        this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()

      }
      else {
        this.errormessage = message;
     //  $(this.modal1.nativeElement).modal('show');
       $(this.modal2.nativeElement).modal('show');

      }
    })
  }
//master menu list hide and show
dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}
///property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}
//logout click func
public logout(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');
///redircrt to login screen
  this.router.navigateByUrl('/admin');
}

}
