import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CompleterService } from 'ng2-completer';
import { NewentryService } from '../shared/newentry.service';
import { ToastrService } from 'ngx-toastr';
import 'src/assets/css/style.css'
import { GlobalComponent } from '../global/global.component';
import { HeaderComponent } from '../header/header.component';
import { HttpClient } from '@angular/common/http';
declare let $: any

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css'],
  providers: [GlobalComponent,HeaderComponent]
})
export class PagesComponent implements OnInit {
  @ViewChild('signupPopupForm', { static: false }) modal: ElementRef
  showsubc: boolean = false
  str: any
  category_id: any
  signup: any
  signin: any
  username: any
  agent: boolean = false
  single: boolean =null
  agentsignup: any
  hidelogin: boolean = true
  propertypostbtnshow: boolean = false
  showlogout: boolean = false
  List: any[]
  dataService
  loginbtn: boolean
  locationvalue
  registershow: boolean = true
  cssurl = "/assets/css/carousel.css"
  url = "/assets/js/carousel.js"
  url3 = "/assets/js/select-menu.js"
  url2 = "/assets/js/testimonialcarousel.js"
  url4 = "assets/js/animated-headline.js"
  url5 = "assets/js/client-logo.js"
  category: boolean = false
  categorysale: boolean = false
  otpshow: boolean = false
  forgetloader:boolean=false
  loginloader:boolean=false
  regloader:boolean=false
  resetloader:boolean=false
  reset
  showforeget:boolean=false
  pwdid
  forgetpassword
  homeloader:boolean=false
  subscribeTimer
  timeLeft:number=180
  interval
  timer:boolean=false
  resentotp:boolean=false
  company:boolean = false
  user:boolean = false
  location
  subcategoryListForRent:any[]
  subcategoryListForSale:any[]
  rent:boolean=false
  sale:boolean=false
  intialSelect:boolean=true
  constructor(public service: NewentryService, private router: Router, private toastr: ToastrService,
    private header:HeaderComponent, private http:HttpClient,
    private completerService: CompleterService, private renderer2: Renderer2, private global: GlobalComponent) {
    this.loadScript()
    this.loadScript2()
    this.loadScript3()
    this.loadScript4()
    this.loadScript5()
    this.service.getautocompletearea().toPromise().then(res => {
      this.List = res as any[]
      console.log(this.List,"areas")
      this.dataService = completerService.local(this.List, 'area_name', 'area_name');
      localStorage.setItem('dataService', this.dataService)
    })
    this.loadCSS()
//     let names = ['Alice', 'Bob', 'Tiff', 'Bruce', 'Alice']

// let countedNames = names.reduce(function (allNames, name) { 
//   console.log(name,"name")
//   if (name in allNames) {
   
//     allNames[name]++
//   }
//   else {
//     console.log(name in allNames,"allNames[name]")
//     allNames[name] = 1
//   }
//   return allNames
// },[])
// console.log(countedNames,"countedNames")

   }

  ngOnInit() {
 
    this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+1).toPromise().then(res=>{
      console.log(res,"subc")
      this.subcategoryListForRent=res as any[]
    })
    this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+2).toPromise().then(res=>{
      console.log(res,"subc")
      this.subcategoryListForSale=res as any[]
    })
 this.header.listplace=true
    window.scrollTo(0, 0);
    this.loadScript()
    this.service.getrole()
    this.productresetForm()
  
 
    this.username = this.global.username
    this.resetform()
    this.agentcompanyresetform()
    // this.service.getcategories()
    this.service.getcompany()
    this.service.gethomepagecategoriesview()
    this.signupresetform()
    this.passwordresetform()
    this. forgetresetform()
    this.signin = {
      email: null,
      password: null
    }
    this.agentsignup = {
      user_name: null,
      email: null,
      phone:null,
      password: null,
      confirmpassword: null,
      role_name: null,
      login_users: null,
      user_count: null,
      agent_company_id: null
    }
    this.service.getarea()
    if (this.global.username) {
      this.showlogout = true
      this.loginbtn = false
    }
    else {
      this.showlogout = false
      this.loginbtn = true
    }

  }
  signinresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signin = {
      email: null,
      password: null

    }
  }
  signupresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

    }
  }
  productresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.productdata = {
      product_id: null,
      product_code: null,
      product_title: null,
      availablity_status:null,
      process_status_id: null,
      location_id: null,
      approval_flag: null,
      product_description: null,
      short_description: null,
      category_id: null,
      sub_category_id: null,
      subcategory_id: null,
      address_1: null,
      address_2: null,
      address_3: null,
      area_id: null,
      city_id: null,
      size_of_property: null,
      unit_id: null,
      price: null,
      company_id: null,
      phone: null,
      product_by: null,
      agent_id: null,
      nearby_location: null,
      facilities: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,
      flag: null,
      is_deleted: null,
      deleted_by: null,
      deleted_date: null,
      area_name: null,
      city_name: null,
      category_name: null,
      subcategory_name: null,
      unit_name: null,
      agent_name: null,
      company_name: null,
      bedroom_count: null,
      bathroom_count: null,
      property_facing: null,
      remarks: null,
      dwelling_type_id: null,
      pet_friendly: null,
      avilable_date: null,
    }

  }
  resetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

      created_by: null
    }
  }
  agentcompanyresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.agentsignup = {
      user_name: null,
      email: null,
      phone:null,
      password: null,
      confirmpassword: null,
      role_name: null,
      login_users: null,
      user_count: null,
      agent_company_id: null
    }
  }
  forgetresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.forgetpassword = {
      email: null,
      otp: null
    }
  }
  passwordresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.reset = {
      confirmPassword: null,
      newPassword: null
    }
  }
  category1() {
    this.loadScript3()
    this.category_id = "1"
    this.category = true
    this.categorysale = false
    this.sale=false
    this.rent=true
    this.intialSelect=false
    this.service.getsubcategory(this.category_id)
  
  }
  category2() {
    this.loadScript3()
    this.category_id = "2"
    this.categorysale = true
    this.category = false
    this.sale=true
    this.rent=false
    this.intialSelect=false
    this.service.getsubcategory(this.category_id)
  
  }
  onsubmit(form: NgForm) {
   
this.homeloader=true
    // form.value.location_id="1DAD86AD-0E31-4DC1-9726-540C5A988E14"
    // form.value.min_price = parseInt(form.value.min_price)
    // form.value.max_price = parseInt(form.value.max_price)
    // if(localStorage.getItem('category_id')  != null && localStorage.getItem('location') != null){
    //   localStorage.removeItem('category_id') 
    //   localStorage.removeItem('location') 
    //   localStorage.removeItem('subcategory')
    // }
    form.value.category_id = this.category_id
    form.value.location_id = this.locationvalue

    localStorage.setItem('category_id', this.category_id)
    localStorage.setItem('subcategory', form.value.sub_category_id)
    if (form.value.category_id === undefined) {
      form.value.category_id = null
      localStorage.removeItem('category_id')
    }
    if (form.value.location_id === undefined) {
      console.log('locaction call')
      form.value.location_id = null
      localStorage.removeItem('locationid')
      localStorage.removeItem('location')
    }
    if (form.value.sub_category_id === null) {
      form.value.sub_category_id = null
      localStorage.removeItem('subcategory')
    }
   if(this.location === undefined){
     this.location=null
   }
    console.log(localStorage.getItem('category_id'), "cid")
    console.log(form.value, "homeform")
    this.service.posthomesearch(form.value,this.location)
this.homeloader=false
  }

  onregistersubmit(registerform) {

    registerform.value.role_name = "user"
    console.log(registerform.value, "reg")
    this.service.postregister(registerform.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        //this.pagesparent.successModal('Insert successfully');
        this.toastr.success('Register Successful', 'Signup')
        // alert("Register Successful")
        this.resetform(registerform)

        document.getElementById("signupPopupForm00").click()
      }
      else {
        //this.pagesparent.errorModal('message');
        alert(message)
      }


    })
  }
  onsigninsubmit(signin) {
    this.loginloader=true
    this.service.postsignin(signin.value).subscribe((res: any) => {
      console.log(res, "signindetails")
      var status = res['error_status'];
      var message = res['error_message'];

      if (status == 1) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('userid', res.user_id)
        localStorage.setItem('username', res.user_name);
        localStorage.setItem('role_name', res.role_name);
        localStorage.setItem('role_id', res.role_id)
        localStorage.setItem('user_email', res.user_email)
        localStorage.setItem('phone', res.phone_number)
        this.username = this.global.username
        this.signinresetform(signin)
       
        this.showlogout = true
        this.loginbtn = false
        $('#loginPopupForm11').modal('hide')
       // this.router.navigateByUrl('/propertypost')
        this.router.navigate(['/propertypost'],{
          queryParams:{
            postadshow:true,
            listplace:true,
            profile:false
          }
        })
        //document.getElementById("close").click();
        this.loginloader=false
      }
      else {
        alert('Incorrect username or password.');
        this.loginloader=false

      }
    })
  }
  postfreead() {
    if (this.username != null) {
      this.registershow = false
      this.router.navigateByUrl('/postadvertisement')
    }
    else {
      this.registershow = true
    }
  }


  loginuser(e) {
    if (e == "single") {
      this.single = true
    }
    else {
      this.single = false
    }
  }
  bothformsubmit(agentcompanyform) {
    this.regloader=true
    if (this.agent == true) {
      agentcompanyform.value.role_name = "agent"
    }
    else if(this.company == true){
      agentcompanyform.value.role_name = "company"
    }
    else if(this.user == true){
      agentcompanyform.value.role_name = "User"
    }
    else {
      agentcompanyform.value.role_name = "owner"
    }
    if(this.single == true){
      agentcompanyform.value.login_users="single"
    }
    else if(this.single == false){
      agentcompanyform.value.login_users="multiple"
    }
    this.service.postagentcompany(agentcompanyform.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
        //this.pagesparent.successModal('Insert successfully');
        if(this.agent ==true || this.company == true){
          alert("Our Client Representative contact will you soon !")
        }
        else{
          alert("Register Successfully !")
        }
        this.agentcompanyresetform()
        document.getElementById("postpropertyPopupForm11").click();
        document.getElementById("loginPopupForm11").click();
        this.regloader=false
      }
      else {
        //this.pagesparent.errorModal('message');postpropertyPopupForm
        alert(message)
        this.regloader=false
      }

    })
  }
  postpropertyagent() {
    console.log(this.username, "username")
    if (localStorage.getItem('role_name') === "agent") {
      this.hidelogin = false
      this.router.navigate(['/propertypost'],{
        queryParams:{
          postadshow:true,
          listplace:true,
          profile:false
        }
      })
    }
    this.agent = true
    this.propertypostbtnshow = true
  }
  postpropertyowner() {
    if (localStorage.getItem('role_name') === "company") {
      this.hidelogin = false
      this.router.navigate(['/propertypost'],{
        queryParams:{
          postadshow:true,
          listplace:true,
          profile:false
        }
      })
    }
    this.agent = false
    this.propertypostbtnshow = true
  }
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    localStorage.removeItem('role_id');
    localStorage.removeItem('role_name');
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    localStorage.removeItem('phone')
    this.username = this.global.username
    this.router.navigateByUrl('/home');
    this.showlogout = false
    this.loginbtn = true
  }
  onlogin() {
    this.propertypostbtnshow = false
  }
  createpropertypost() {
  $("#loginPopupForm11").modal('hide')
  }

  searchlistbysubid1() {
    console.log("1")
    let id = "1"
    let catid =  "1"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)

  }
  searchlistbysubid2() {
    let id = "2"
    let catid =  "1"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)
  }
  searchlistbysubid3() {
    let id = "3"
    let catid =  "1"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)
  }
  searchlistbysubid4() {
    let id = "7"
    let catid =  "1"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)
  }
  searchbysale1() {
    let id = "4"
    let catid =  "2"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)
  }
  searchbysale2() {
    let id = "5"
    let catid =  "2"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)
  }
  searchbysale3() {
    let id = "6"
    let catid =  "2"
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    this.service.getsearchlistbysubid(id,catid)
  }
  locationchange(e) {
    localStorage.setItem('location', e)
    for (var i = 0; i <= this.List.length; i++) {
      if (this.List[i] != null) {
        if (this.List[i].area_name == e) {
          var areaid = this.List[i].area_id
          this.locationvalue = areaid
          this.location=this.List[i].area_name
          localStorage.setItem('locationid', this.locationvalue)
          console.log(e, "locationchange")
          console.log(this.locationvalue, "locationchange")
        }
      }

    }
  }
  myprofile() {
    this.router.navigateByUrl('/myprofile')
  }
  loadCSS() {
    // Create link
    let link = document.createElement('link');
    link.href = this.cssurl;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    let style = head.getElementsByTagName('style')[0];

    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (var i = 0; i < links.length; i++) {
      var node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.insertBefore(link, style);
  }
  public loadScript() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  public loadScript2() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url2;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  public loadScript3() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url3;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
  public loadScript4() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url4;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
  public loadScript5() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url5;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
  agentselect() {
    this.agent = true
  }
  companyselect() {
    this.agent = false
  }
  close() {
    $('#loginPopupForm11').modal('hide');
    this.agentcompanyresetform()
  }
  clickforget() {
    $('#loginPopupForm11').modal('hide')
    $('#forget').modal('open')
    this.forgetresetform()
    this.otpshow = false
    this.forgetloader=false
  }
  resetclick(){
    this.passwordresetform()
    $('#forget').modal('hide')

  }
  forgetsubmit(form) {
    this.forgetloader=true
    if (this.otpshow == false) {
      this.service.postotp(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
       
        if (status == 1) {
         
          this.otpshow = true
          this.timer=true
          this.timeLeft=180
          this.forgetloader=false
          this.showforeget=true
          this.forgetresetform()
          this.startTimer()
        }
        else {
          alert(message)
          this.forgetloader=false
        }
      })
    }
    else {
      this.service.postforget(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        this.pwdid=res['user_id']
        if (status == 1) {
          console.log(res,"otp")
          $('#forget').modal('hide')
          $('#resetpassword').modal('show')
          this.otpshow=false
          this.forgetresetform()
          this.forgetloader=false
        }
        else {
          alert(message)
          this.forgetloader=false
        }
      })
    }
  }

  resetsubmit(form){
    this.resetloader=true
    form.value.user_id=this.pwdid
    this.service. newresetPassword(form.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
      alert(message)
      this.resetloader=false
      $('#resetpassword').modal('hide')
      this.passwordresetform(form)
      }
      else{
        alert(message)
        this.resetloader=false
      }
    })
  }
  loginclose(){
    this.loginloader=false
  }
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
       this.timeLeft = 0;
       this.timer=false
       this.otpshow=false
        this.resentotp=true
      }
    },1000)
  }
  startTimer2() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
       this.timeLeft = 0;
       this.timer=false
       this.otpshow=false
        this.resentotp=true
      }
    },1000)
  }
  resentotpsubmit(form){
   
    this.service.postotp(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
    
      if (status == 1) {
        this.otpshow = true
        this.timer=true
        this.timeLeft=180
        this.forgetloader=false
        this.showforeget=true
        this.resentotp=false
        this.startTimer2()

      }
      else {
        alert(message)
        this.forgetloader=false
      }
    })
  }
  rolechange(e){
    if(e==="AGEN"){
       this.agent=true
       this.company=false
       this.user=false
       this.single=null
    }
    else if(e==="COMP"){
      this.agent=false
      this.company=true
      this.user=false
    }
    else if(e==="USR"){
      this.agent=false
      this.company=false
      this.user=true
      this.single=null
    }
    else if(e==="OWN"){
      this.agent=false
      this.company=false
      this.user=false
      this.single=null
    }
    else{
      this.agent=false
      this.company=false
      this.user=false
      this.single=null
    }
  }
  changeusercount(e){
    if(e == "1"){
      this.single=true
    }
    else{
      this.single=false
    }
  }
 
}
