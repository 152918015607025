import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'redstar'
})
export class RedstarPipe implements PipeTransform {

  transform(value: any ): any {
    console.log(value,"redstar")
    var str = value.replace(value,"******")
    return str;
  }

}
