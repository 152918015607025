import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strprice'
})
export class StrpricePipe implements PipeTransform {

  transform(value: any): any {
    
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
 
  
 
  }

}
