import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AgentComponent } from './masters/agent/agent.component';
import { AreaComponent } from './masters/area/area.component';
import { CityComponent } from './masters/city/city.component';
import { CompanyComponent } from './masters/company/company.component';
import { CountryComponent } from './masters/country/country.component';
import { CurrencyComponent } from './masters/currency/currency.component';
import { LanguageComponent } from './masters/language/language.component';
import { StateComponent } from './masters/state/state.component';
import { AdvertisementMasterComponent } from './property/advertisement-master/advertisement-master.component';
import { CategoryComponent } from './property/category/category.component';
import { GroupComponent } from './property/group/group.component';
import { PropertyComponent } from './property/property/property.component';
import { SubcategoryComponent } from './property/subcategory/subcategory.component';
import { UnitComponent } from './property/unit/unit.component';
import { SuperAdminComponent } from './super-admin.component';
import { UserManagementComponent } from './user-management/user-management.component';


const routes: Routes = [
  {path: 'admin_page', component: SuperAdminComponent, children: [
    {path: 'currency', component: CurrencyComponent, canActivate:[AuthGuard]},
    {path: 'language', component: LanguageComponent, canActivate:[AuthGuard]},
    {path: 'country', component: CountryComponent, canActivate:[AuthGuard]},
    {path: 'state', component: StateComponent, canActivate:[AuthGuard]},
    {path: 'city', component: CityComponent, canActivate:[AuthGuard]},
    {path: 'area', component: AreaComponent, canActivate:[AuthGuard]},
    {path: 'company', component: CompanyComponent, canActivate:[AuthGuard]},
    {path: 'agent', component: AgentComponent, canActivate:[AuthGuard]},
    {path: 'group', component: GroupComponent, canActivate:[AuthGuard]},
    {path: 'category', component: CategoryComponent, canActivate:[AuthGuard]},
    {path: 'sub_category', component: SubcategoryComponent, canActivate:[AuthGuard]},
    {path: 'unit', component: UnitComponent, canActivate:[AuthGuard]},
    {path: 'property', component: PropertyComponent, canActivate:[AuthGuard]},
    {path: 'advertisement', component: AdvertisementMasterComponent, canActivate:[AuthGuard]},
    {path: 'users', component: UserManagementComponent, canActivate:[AuthGuard]},
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }
