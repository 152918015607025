import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NewentryService } from '../shared/newentry.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, } from 'ngx-gallery';
//import { ThumbnailComponent} from'ngx-gallery/ngx-gallery-thumbnails.component'
import { NgxGalleryComponent,NgxGalleryThumbnailsComponent } from "ngx-gallery";
import { CompleterService } from 'ng2-completer';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

declare let $:any

@Component({
  selector: 'app-room-detail',
  templateUrl: './room-detail.component.html',
  styleUrls: ['./room-detail.component.css'],
  providers:[NgxSpinnerService]

})
export class RoomDetailComponent implements OnInit {
  @ViewChild(NgxGalleryComponent,{static:false}) ngxGalleryComponent;
  @ViewChild(NgxGalleryThumbnailsComponent,{static:false}) thumbnailcomp;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  username:any
  signup:any
  signin:any
  enquiry:any
  registershow:boolean
  upload_image_name
  upload_image_name2
  agent:boolean
  single:boolean=true
  agentsignup:any
  hidelogin:boolean=true
  propertypostbtnshow:boolean=false
  showlogout:boolean=false
  loginbtn:boolean
  tempimg:boolean=true
  data
  url="/assets/js/carousel.js";
  url2 = "/assets/js/select-menu.js";
  url3 = "/assets/js/cardimgcarousel.js";
  url4 = "assets/js/custom-maps.js";
  cssurl="/assets/css/carousel.css";
  phone:boolean=false;
  List
  dataService
  category_id
  category
  locationvalue
  location=localStorage.getItem('location')
  input:boolean=true
  productdata
  id
  categorysale:boolean
  public errormessage;
  public successmessage;
  public warningmessage;
  by
  length
  subcategoryListForRent
  subcategoryListForSale
  rent:boolean
  sale:boolean
  intialSelect:boolean=false
  homeloader:boolean

  constructor(public service: NewentryService,private router:Router,private http:HttpClient,
    private completerService: CompleterService, private route: ActivatedRoute,private spinner: NgxSpinnerService ) {
      this.loadScript2()
      this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+1).toPromise().then(res=>{
        console.log(res,"subc")
        this.subcategoryListForRent=res as any[]
        this.loadScript2()
      })
      this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+2).toPromise().then(res=>{
        console.log(res,"subc")
        this.subcategoryListForSale=res as any[]
        this.loadScript2()
      })
    this.loadScript3()
    this.service.getautocompletearea().toPromise().then(res => {
      this.List = res as any[]
     console.log(this.List)
      this.dataService = completerService.local(this.List, 'area_name', 'area_name');
    })
    console.log(localStorage.getItem('subcategory'),"subid")
    this.service.getsubcategory(localStorage.getItem('category_id'))
   this.productdata={
     location_id:localStorage.getItem('location'),
     category_id: null,
     sub_category_id: localStorage.getItem('subcategory'),
     subcategory_id:  localStorage.getItem('subcategory'),

      }
      this.locationvalue=localStorage.getItem('locationid')
      this.productdata.location_id=localStorage.getItem('location')
      $('.card-img-carousel').owlCarousel({
        loop: true,
        items: 1,
        nav: true,
        dots: true,
        smartSpeed: 700,
        autoplay: false,
        active: true,
        margin: 30,
        navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>']
    });
   }

  ngOnInit() {
    this.loadScript2()
    /**get subcategorylist based on category */
    this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+1).toPromise().then(res=>{
      console.log(res,"subc")
      this.subcategoryListForRent=res as any[]
      this.loadScript2()
    })
    this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+2).toPromise().then(res=>{
      console.log(res,"subc")
      this.subcategoryListForSale=res as any[]
      this.loadScript2()
    })

    this.dataService=localStorage.getItem('dataService')
    this.category_id=localStorage.getItem('category_id')

    if(localStorage.getItem('category_id')==="1"){
      this.loadScript2()
      this.category=true
      this.rent=true
      this.sale=false
      this.intialSelect=false
      this.categorysale=false
      this.loadScript2()
    }

    ////value from router params
    this.route.params.subscribe(params => {
      this.spinner.show();
      this.id = atob(decodeURIComponent(params['id']));
      //get roomimg
     this.service.getroomimg(this.id)
     //get room details list
      this.http.get(this.service.rootURL+'/products/getdetailviewbyid/'+this.id).toPromise().then(res=>{
        this.service.roomdetailslist=res
         console.log(res,"roomdetails")
         this.spinner.hide();
         if( this.service.roomdetailslist != null){
         this.length=this.service.roomdetailslist.phone.length
          if(this.service.roomdetailslist.category_id == "1"){
             this.by="Rent"
          }
         else{
          this.by="Sale"
          }
          //get recommented list
          this.service.getrecommentedad(this.service.roomdetailslist.category_id,this.service.roomdetailslist.subcategory_id)
         }

       })
    });
    //scroll to top
    window.scrollTo(0,0);
    this.loadScript2()
    this.loadScript3()
    this.loadScript4()
    this.loadScript()
    this. loadCSS()

    this.productresetForm()
    this.username=localStorage.getItem('username')
    this.service.getfreead()
    this.service.getonlysubcategory()
    this.enquiry = {
      product_id: null,
      user_name: null,
      contact_type: null,
      phone_number:null,
      email: null,
      product_by:null,
      company_id:null,
      agent_id:null,
      message: null
    }
    this.signin={
      email:null,
      password:null
     }
     this.agentsignup={
      user_name:null,
  email:null,
  password:null,
  confirmpassword:null,
  role_name:null,
  login_users:null,
  user_count:null,
  agent_company_id:null
    }
    this.enquiryformresetform()


    if(localStorage.getItem('username')){
      this.showlogout=true
      this.loginbtn=false
    }
    else{
      this.showlogout=false
      this.loginbtn=true
    }



   if(this.service.roomimglist != null){
     //image slider list
    this.galleryImages = this.service.roomimglist
    //image slider gallery setting option
    this.galleryOptions = [

{ imageAutoPlay: true, imageAutoPlayPauseOnHover: true, previewAutoPlay: false, previewAutoPlayPauseOnHover: true,
  previewFullscreen: true, previewKeyboardNavigation: true,previewCloseOnClick: true, previewCloseOnEsc: true ,
  imageArrowsAutoHide: true, thumbnailsArrowsAutoHide: true,preview: true},
{imagePercent: 80, thumbnailsPercent: 20, "breakpoint": 500, "width": "300px", height: "300px", thumbnailsColumns: 3 },
{imagePercent: 80, thumbnailsPercent: 20, "breakpoint": 300, "width": "100%", "height": "200px", thumbnailsColumns: 2 }
    ];

   }

    this.data={
      product_id: null,
      is_property_view: null,
      is_property_phone_number_view: null,
      user_id: null,
      ip_address: null
    }
    if(localStorage.getItem('category_id')==="2"){
      this.loadScript2()
     this.category=false
     this.categorysale=true
     this.rent=false
     this.intialSelect=false
     this.sale=true
     this.loadScript2()
    }
    if(localStorage.getItem('category_id') === null){
      this.loadScript2()
     this.category=false
     this.categorysale=false
     this.intialSelect=true
       this.sale=false
       this.rent=false
       this.loadScript2()
    }
  }
//reset product data
  productresetForm(form?:NgForm){
    if(form != null)
    form.resetForm()
      this.service.productdata={
        product_id: null,
        product_code: null,
        product_title: null,
        availablity_status:null,
        process_status_id:null,
        location_id:null,
        approval_flag:null,
        product_description: null,
        short_description: null,
        category_id: null,
        sub_category_id: localStorage.getItem('subcategory'),
        subcategory_id: localStorage.getItem('subcategory'),
        address_1:null,
        address_2: null,
        address_3: null,
        area_id: null,
        city_id: null,
        size_of_property: null,
        unit_id: null,
        price:null,
        company_id: null,
        phone: null,
        product_by: null,
        agent_id: null,
        nearby_location: null,
        facilities: null,
        created_by: null,
        created_date: null,
        updated_by:null,
        updated_date:null,
        flag:null,
        is_deleted:null,
        deleted_by:null,
        deleted_date:null,
        area_name: null,
        city_name: null,
        category_name: null,
        subcategory_name: null,
        unit_name:null,
        agent_name: null,
        company_name: null,
        bedroom_count: null,
        bathroom_count: null,
        property_facing:null,
        remarks:null,
        dwelling_type_id: null,
        pet_friendly:null,
        avilable_date: null,
      }

}
//reset enquiry func
enquiryformresetform(form?:NgForm){
  if(form != null)
  form.resetForm()
  this.enquiry = {
    product_id: null,
    user_name: null,
    contact_type: null,
    phone_number:null,
    email: null,
    product_by:null,
    company_id:null,
    agent_id:null,
    message: null
  }
}

//when click on mask phone number at that time this func trigger
  onphone(product_id){
    this.data.product_id=product_id
   this.data.is_property_view=false
   this.data.is_property_phone_number_view=true
   this.data.user_id=localStorage.getItem('userid')
   this.data.ip_address=this.service.ipAddress
   //find how many persons interest on in it
   this.service.propertyclickevent(this.data).subscribe(res=>{
     console.log(res)
     this.phone=true
   })
  }
/**custom js file preloading func */
  public loadScript() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
}
public loadScript2() {
  console.log("preparing to load...");
  let node = document.createElement("script");
  node.src = this.url2;
  node.type = "text/javascript";
  node.async = true;
  node.charset = "utf-8";
  document.getElementsByTagName("head")[0].appendChild(node);
}
public loadScript3() {
  console.log("preparing to load...");
  let node = document.createElement("script");
  node.src = this.url3;
  node.type = "text/javascript";
  node.async = true;
  node.charset = "utf-8";
  document.getElementsByTagName("head")[0].appendChild(node);
}
public loadScript4() {
  console.log("preparing to load...");
  let node = document.createElement("script");
  node.src = this.url4;
  node.type = "text/javascript";
  node.async = true;
  node.charset = "utf-8";
  document.getElementsByTagName("head")[0].appendChild(node);
}
setThumbnailsPosition(){
  this.thumbnailcomp.setThumbnailsPosition()
}

 //fetch the details to the data global variable by click on the property image and name
propertylist(det){
  this.service.getroomdetails(det.product_id)
  this.service.getroomimg(det.product_id)
  this.service.getrecommentedad(det.category_id,det.subcategory_id)
  this.data.product_id=det.product_id
  this.data.is_property_view=true
  this.data.is_property_phone_number_view=false
  this.data.user_id=localStorage.getItem('userid')

  this.service.propertyclickevent(this.data).subscribe(res=>{
    console.log(res)
  })

 }

   //for rent radio btn click at that time this func trigger
  category1(){
    this.loadScript2()
    console.log(this.category_id,'category')
    this.category_id="1"
    this.category=true
    this.categorysale=false
    this.sale=false
    this.rent=true
    this.intialSelect=false
    this.service.getsubcategory(this.category_id)
    this.loadScript2()
    }
     //for sale radio btn click at that time this func trigger
    category2(){
      this.loadScript2()
  this.category_id="2"
  this.category=false
    this.categorysale=true
    this.sale=true
    this.rent=false
    this.intialSelect=false
  this.service.getsubcategory(this.category_id)
  this.loadScript2()
    }
     //search the home list based on category and location and subcategory
  onsubmit(form:NgForm){
 this.homeloader=true

  form.value.category_id=this.category_id
  form.value.location_id=this.locationvalue
  form.value.page_size=10
  console.log(form.value)
  if(form.value.category_id===undefined){
    form.value.category_id=null
  }
  if(form.value.location_id===undefined){
    form.value.location_id=null
  }
  this.service.posthomesearch(form.value,this.location)
 this.homeloader=false
  }
    //when location autocomplete value change at thattime this func  trigger
  //in this func we get area name and area id
  locationchange(e){

    for(var i=0;i<=this.List.length;i++){
     if(this.List[i] != null){
      if(this.List[i].area_name==e){
        var areaid=this.List[i].area_id
        this.locationvalue=areaid
        this.location = this.List[i].area_name
        console.log(e)
    }
     }

   }
   }

  //custom css file preloading func
  loadCSS() {
    // Create link
    let link = document.createElement('link');
    link.href = this.cssurl;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    let style = head.getElementsByTagName('style')[0];

    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (var i = 0; i < links.length; i++) {
      var node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.insertBefore(link, style);
  }

  //enquiry submit func
  enquirysubmit(form){

    var numbers = /^[^a-z]+$/;
    //checking input field phone or mail
      if(form.value.email.match(numbers))
    {
      form.value.contact_type="phone"
      form.value.phone_number=form.value.email
      form.value.email=null
    }
    else{
      form.value.contact_type="email"
      form.value.email=form.value.email
      form.value.phone_number=null
    }
    form.value.product_id=this.service.roomdetailslist.product_id
    form.value.product_by=this.service.roomdetailslist.product_by
    form.value.company_id=this.service.roomdetailslist.company_id
    form.value.agent_id=this.service.roomdetailslist.agent_id

    this.service.postenquiry(form.value).subscribe(res=>{
      var status = res['error_status'];
      var message = res['error_message'];
      if(status == 1){
        alert("Post successful")
        // this.successmessage=message
        // $('#successmodal').modal('show')
        this.enquiryformresetform(form)
      }
     else{
       alert(message)
      //  this.errormessage=message
      //  $('#errormodal').modal('show')

     }
    })
  }
}
