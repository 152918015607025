import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-listyourplace',
  templateUrl: './listyourplace.component.html',
  styleUrls: ['./listyourplace.component.scss'],
  providers:[ HeaderComponent]
})
export class ListyourplaceComponent implements OnInit {
  listplace:boolean=false
  constructor(private header:HeaderComponent) { 
    
  }

  ngOnInit() {
    this.header.listplace=false
  }

}
