import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  
})
export class UserMenuComponent implements OnInit {

  constructor(public router: Router) { }
  public username=localStorage.getItem('username');
  public userId=localStorage.getItem('userId');
  ngOnInit() {
  }
  public logoutclick(): void { 
    // alert('ddd');
     localStorage.removeItem('token');
     localStorage.removeItem('username');
     localStorage.removeItem('userId');
     localStorage.removeItem('userid')
     localStorage.removeItem('role_id');
     localStorage.removeItem('role_name');
    
     this.router.navigateByUrl('/admin');
   }

   changepwd(){
     this.router.navigateByUrl('/changepassword')
   }
}
