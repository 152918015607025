import { Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

declare let $: any;


@Component({
  selector: 'app-pages-parent',
  templateUrl: './pages-parent.component.html',
  styleUrls: ['./pages-parent.component.css'],
  
})
export class PagesParentComponent implements OnInit{
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  @ViewChild('modal', { static: false }) modal:ElementRef;

  public errormessage;
  public successmessage;
  public warningmessage;

  public showMenu: boolean = false;
  public showSetting: boolean = false;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;

  constructor() { }

  ngOnInit() {
  }
 
  public successModal(val) {
    console.log(val)
    this.successmessage = val;
  //  $(this.modal1.nativeElement).modal('show');
    $('#successmodal').modal('show'); 
}
public errorModal(val) {
    this.errormessage = val;
    $("#errormodal").modal('show');
}
public warningModel(val) {
    this.warningmessage = val;
    $("#warningmodal").modal('show');
}
}
