
import { NgForm } from '@angular/forms';


import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { NewentryService } from 'src/app/shared/newentry.service';

import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/global/global.component';
import { PagesParentComponent } from 'src/app/pages-parent/pages-parent.component';

declare let $ :any;

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css'],
  providers:[PagesParentComponent,GlobalComponent]
})
export class CityComponent implements OnInit {
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  term2: string
  Areaterm2: string;
  Countryterm2: string;
  Stateterm2: string;
  Cityterm2: string;
  Currencyterm2: string;
  Languageterm2: string;
  Categoryterm2:string;
  agentterm2:string;
  groupterm2:string;
  subcategoryterm2:string;
  unitterm2 : string;
  groupPage:number = 1
  languagePage: number = 1
  p: number = 1
  currencyPage: number = 1
  statePage: number = 1
  countryPage: number = 1
  statepage: number = 1;
  cityPage: number = 1;
  areaPage: number = 1;
  categoryPage:number=1;
  agentPage:number=1;
  subcategoryPage:number = 1;
  unitPage :number = 1;
  showedit: boolean = false
  languageedit: boolean = false
  countryedit: boolean = false
  stateedit: boolean = false
  cityedit: boolean = false
  areaedit: boolean = false
  categoryedit: boolean = false
  agentedit:boolean = false;
  groupedit:boolean = false;
  subcategoryedit:boolean=false;
  unitedit:boolean = false;
  loading1: boolean = false
  loading2: boolean = false
  loading3: boolean = false
  loading4: boolean = false
  loading5: boolean = false
  loading6: boolean = false
  loading7: boolean = false
  loading8: boolean = false;
  loading9: boolean = false;
  loading10: boolean = false;
  loading11: boolean = false;
  dropdownval2:boolean=true
  dropdownval:boolean=true
  arr: any[]
  property:boolean=false
  propertyval2:boolean=true
  constructor(public service: NewentryService, private pagesparent: PagesParentComponent, private router: Router
    ,private global: GlobalComponent) { }

  ngOnInit() {

    this.citiesresetForm()
    //get country list
    this.service.getcountry()
    //get state list
    this.service.getstate()
    //get city list
    this.service.getcities()
   //get country dropdown list
    this.service.getcountrydropdown()
    //get state dropdown list
    this.service.getstatedropdown()
    //get city dropdown list
    this.service.getcitydropdown()
  }
  //reset city form
  citiesresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.citiesdata = {
      city_id: null,
      state_id: null,
      country_id: null,
      city_code: null,
      city_name: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,
      flag: null,
    }
  }
  //cancel and reset city form
  oncitycancel(cityform) {
    this.citiesresetForm(cityform)
    this.loading5 = false
    this.cityedit = false
  }
  //update flag value in city table
  //when click on/off switch in the table at that time this func trigger
  changeCityflag(det) {
    det.updated_by=this.global.userid
    this.service.putcities(det).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

       this.successmessage = message;
         $(this.modal1.nativeElement).modal('show');
        this.citiesresetForm()
        this.service.getcities()
        this.service.getcitydropdown()
        this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
      }
      else {
       this.errormessage = message;
         $(this.modal2.nativeElement).modal('show');
      }
    })
  }
     //when click edit icon on the table at that time this func trigger
  //values were populated to forms field from the table
  cityPopulated(det) {
    this.cityedit = true
    this.service.citiesdata = Object.assign({}, det)
    this.loading5 = false
  }
  //insert and update func
  onsubmitcity(cityform) {
    this.loading5 = true

    console.log(cityform.value, "city")
    //insert func
    if (cityform.value.city_id == null) {
      cityform.value.flag=true
      cityform.value.created_by=this.global.userid
      this.service.postcities(cityform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {

          this.successmessage = message;
            $(this.modal1.nativeElement).modal('show');
          this.citiesresetForm(cityform)
          this.service.getcities()
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.cityedit = false
          this.loading5 = false;
        }
        else {
         this.errormessage = message;
           $(this.modal2.nativeElement).modal('show');
          this.loading5 = false;
        }
      })
    }
    //update func
    else {
      cityform.value.updated_by=this.global.userid
      if(  cityform.value.flag==true){
        cityform.value.flag=true
      }
      else if( cityform.value.flag==false){
        cityform.value.flag=false
      }
      this.service.putcities(cityform.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          //this.pagesparent.successModal('Update successfully');
          this.successmessage = message;
          //  $(this.modal1.nativeElement).modal('show');
            $(this.modal1.nativeElement).modal('show');
          this.citiesresetForm(cityform)
          this.service.getcities()
          this.service.getcurrencydropdown()
    this.service.getcountrydropdown()
    this.service.getlanguagedropdown()
    this.service.getstatedropdown()
    this.service.getcitydropdown()
          this.cityedit = false
          this.loading5 = false;
        }
        else {
         // this.pagesparent.errorModal(message);
         this.errormessage = message;
         //  $(this.modal1.nativeElement).modal('show');
           $(this.modal2.nativeElement).modal('show');
          this.loading5 = false;
        }
      })
    }
  }

//master menu list hide and show
  dropdown1(){
    this.property=false
    this.propertyval2=true
    this.dropdownval=true
this.dropdownval2=false
}
dropdown2(){
  this.dropdownval=false
  this.dropdownval2=true
}
///property menu list hide and show
property1(){
  console.log("property")
  this.dropdownval=false
  this.property=true
  this.dropdownval2=true
  this.propertyval2=false
}
property2(){
  console.log("property")
  this.dropdownval=false
  this.property=false
  this.propertyval2=true
}

//logout click func
//remove values from local storage and redirect to login page
public logout(): void {

  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userid');
  localStorage.removeItem('role_id');
  localStorage.removeItem('role_name');

  this.router.navigateByUrl('/admin');
}

}
