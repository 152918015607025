import { Component, ElementRef, OnInit, Renderer2, ViewChild ,Input} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleterService } from 'ng2-completer';
import { NewentryService } from '../shared/newentry.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, timer } from 'rxjs';
import { range } from 'rxjs';

import { PostadvertisementComponent } from '../postadvertisement/postadvertisement.component';
declare let $: any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],


})
export class HeaderComponent implements OnInit {
  @Input() value: boolean
  @ViewChild('signupPopupForm', { static: false }) modal: ElementRef

  showsubc: boolean = false
  postadshow:boolean = true
  str: any
  category_id: any
  signup: any
  signin: any
  username: any
  usernameprofile:boolean=false
  agent: boolean = false
  single: boolean = null
  agentsignup: any
  hidelogin: boolean = true
  propertypostbtnshow: boolean = false
  showlogout: boolean = false
  List: any[]
  dataService
  loginbtn: boolean
  locationvalue
  registershow: boolean = true
  postad: boolean
  postfreeadbtnshow: boolean = false
  createacc: boolean = true
  company: boolean = false
  forgetpassword
  otpshow: boolean = false
  forgetloader:boolean=false
  loginloader:boolean=false
  regloader:boolean=false
  resetloader:boolean=false
  reset
  showforeget:boolean=false
  pwdid
  subscribeTimer
  timeLeft:number
  timeremaining:number
  interval
  timer:boolean=false
  resentotp:boolean=false
  resentloader:boolean=false
  timeLeftshow:boolean=false
  listplace:boolean=true
  listplacestr
  user:boolean=false
  constructor(public service: NewentryService, private router: Router, private toastr: ToastrService,
    private completerService: CompleterService, private renderer2: Renderer2, private route: ActivatedRoute,
   ) {
    this.service.getautocompletearea().toPromise().then(res => {
      this.List = res as any[]
      console.log(this.List)
      this.dataService = completerService.local(this.List, 'area_name', 'area_name');

    })
  }

  ngOnInit() {

  //////////show role based btn on header menu list
    if (localStorage.getItem('username') && localStorage.getItem('role_id') != "SADM") {

      this.showlogout = true
      this.createacc = false
      this.loginbtn = false
     this.usernameprofile=true

    }
    else {
      this.showlogout = false
      this.loginbtn = true
      this.createacc = true
      this.usernameprofile=false
    }
    let property = this.route.snapshot.queryParams;
    this.listplace = property.listplace
    this.postadshow = property.postadshow


    //user profile icon and list place menu and post free ad btn
    //these btn show or hide below conditions based
    if(property.profile === "false"){
      console.log('profile',property.profile)
      this.usernameprofile=true
    }
    else if(property.profile === "true"){
      this.usernameprofile=false
      console.log('profile', this.usernameprofile)
    }
   if(property.listplace == "false"){
    this.listplace=false

   }
   else{
    this.listplace=true
    console.log('hi',property.listplace)
   }
   if(property.postadshow == "false"){
    this.postadshow=false
   }
   else{
    this.postadshow=true
    console.log('hi',property.postadshow)
   }
    this.postad = true
    this.productresetForm()
    //set user name
    if (localStorage.getItem('role_id') != "SADM") {
      this.username = localStorage.getItem('username')
    }
    this.forgetpassword = {
      email: null,
      otp: null
    }
this.reset={
  newPassword:null,
  confirmPassword:null
}
    this.resetform()
    this.agentcompanyresetform()
    //get company list
    this.service.getcompany()
    //get home page categories list
    this.service.gethomepagecategoriesview()
    this.signupresetform()
    this.passwordresetform()
    this. forgetresetform()
    this.signin = {
      email: null,
      password: null
    }
    this.agentsignup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,
      role_name: null,
      login_users: null,
      user_count: null,
      agent_company_id: null
    }
    this.service.getarea()
    if(this.value==true){
      //view profile btn show or hide condition
      if(localStorage.getItem('username')){
        this.usernameprofile=false

      }
    }

  }
  //reset func for signin form
  signinresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signin = {
      email: null,
      password: null

    }
  }
    //reset func for forget form
  forgetresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.forgetpassword = {
      email: null,
      otp: null
    }
  }
    //reset func for password reset form
  passwordresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.reset = {
      confirmPassword: null,
      newPassword: null
    }
  }
    //reset func for signup form
  signupresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

    }
  }
    //reset func for product form
  productresetForm(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.service.productdata = {
      product_id: null,
      product_code: null,
      product_title: null,
      availablity_status:null,
      process_status_id: null,
      location_id: null,
      approval_flag: null,
      product_description: null,
      short_description: null,
      category_id: null,
      sub_category_id: null,
      subcategory_id: null,
      address_1: null,
      address_2: null,
      address_3: null,
      area_id: null,
      city_id: null,
      size_of_property: null,
      unit_id: null,
      price: null,
      company_id: null,
      phone: null,
      product_by: null,
      agent_id: null,
      nearby_location: null,
      facilities: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null,
      flag: null,
      is_deleted: null,
      deleted_by: null,
      deleted_date: null,
      area_name: null,
      city_name: null,
      category_name: null,
      subcategory_name: null,
      unit_name: null,
      agent_name: null,
      company_name: null,
      bedroom_count: null,
      bathroom_count: null,
      property_facing: null,
      remarks: null,
      dwelling_type_id: null,
      pet_friendly: null,
      avilable_date: null,
    }

  }

  resetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.signup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,

      created_by: null
    }
  }
    //reset func for agent company form
  agentcompanyresetform(form?: NgForm) {
    if (form != null)
      form.resetForm()
    this.agentsignup = {
      user_name: null,
      email: null,
      password: null,
      confirmpassword: null,
      role_name: null,
      login_users: null,
      user_count: null,
      agent_company_id: null
    }
  }

  //register form submit func
  onregistersubmit(registerform) {

  this.regloader=true
  //////////////////////////////////assign role name //////////////////////
    if (this.agent == true) {
      registerform.value.role_name = "agent"
    }
    else if (this.company == true) {
      registerform.value.role_name = "owner"
    }
    else {
      registerform.value.role_name = "user"
    }
    console.log(registerform.value, "reg")
    //submit func
    this.service.postregister(registerform.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        this.resetform(registerform)
        this.regloader=false
        document.getElementById("signupPopupForm00").click()
      }
      else {
        //this.pagesparent.errorModal('message');
        this.regloader=false
        alert(message)
      }


    })
  }
  //submit func for sigin form
  onsigninsubmit(signin) {
    this.loginloader=true
    this.service.postsignin(signin.value).subscribe((res: any) => {
      console.log(res, "signindetails")
      var status = res['error_status'];
      var message = res['error_message'];

      if (status == 1) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('userid', res.user_id)
        localStorage.setItem('username', res.user_name);
        localStorage.setItem('role_name', res.role_name);
        localStorage.setItem('role_id', res.role_id)
        localStorage.setItem('user_email', res.user_email)
        localStorage.setItem('phone', res.phone_number)
        this.username = localStorage.getItem('username')
        this.signinresetform(signin)
        this.router.navigate(['/home'],{queryParams:{
          listplace:true,
          postadshow:true,
          profile:false
        }})
        this.showlogout = true
        this.loginbtn = false
        this.createacc = false
        this.usernameprofile = true
        document.getElementById("close").click();
        this.loginloader=false
      }
      else {
        alert('Incorrect username or password.');
        this.loginloader=false

      }
    })
  }
  //redirectto post advertistment page
  postfreead() {
    if (this.username != null) {
      this.registershow = false
      console.log('hi')
     $("#loginPopupForm0").modal('hide')
      this.router.navigate(['/postadvertisement'],{
       queryParams:{
        postadshow:false,
        listplace:true,
        profile:false
       }

      })
      this.postad = false
      this.postadshow=false

    }
    else {
      console.log('hi2')
      this.postfreeadbtnshow = true
      $("#loginPopupForm0").modal('show')
      //this.registershow=true
    }
  }
  //if user is signin then redirect to list your place page otherwise open the login popup form
  listyourplace(){
    if (localStorage.getItem('role_name') === "agent" || localStorage.getItem('role_name') === "company") {
      this.registershow = false
      this.listplace=false
      console.log('hi',this.listplace)
     document.getElementById("loginPopupForm0").click()
      $("#loginPopupForm0").modal('hide')
      this.router.navigate(['/listyourplace'],{
        queryParams:{
          postadshow:true,
          listplace:false,
          profile:false
        }
      })
      this.postad = false

    }
    else {
      console.log('hi2')
      $("#loginPopupForm0").modal('show')

    }
  }
  //redirect to home page
  home() {
    this.postad = true
    this.listplace=true
    this.router.navigate(['/home'],{queryParams:{
      listplace:true,
      postadshow:true

    }})
    this.listplace=true
  }

  //agent company form submit func
  bothformsubmit(agentcompanyform) {
    this.regloader=true
    /////////////////////////////////assign role name ////////////////
    if (this.agent == true) {
      agentcompanyform.value.role_name = "agent"
    }
    else if(this.company == true){
      agentcompanyform.value.role_name = "company"
    }
    else if(this.user == true){
      agentcompanyform.value.role_name = "User"
    }
    else {
      agentcompanyform.value.role_name = "owner"
    }
    ///////////////////////////////////////////assign how many users have login authority///////////////
    if(this.single == true){
      agentcompanyform.value.login_users="single"
    }
    else if(this.single == false){
      agentcompanyform.value.login_users="multiple"
    }
    //submit func
    this.service.postagentcompany(agentcompanyform.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        this.regloader=false
        if(this.agent ==true || this.company == true){
          alert("Our Client Representative contact will you soon !")
        }
        else{
          alert("Register Successfully !")
        }

        this.agentcompanyresetform()
        document.getElementById("postpropertyPopupForm").click();
        document.getElementById("loginPopupForm0").click();
      }
      else {

        alert(message)
        this.regloader=false
      }

    })
  }
  //redirect to post property page
  postpropertyagent() {
    console.log(this.username, "username")
    if (localStorage.getItem('role_name') === "agent") {
      this.hidelogin = false
      this.router.navigateByUrl('/propertypost')
    }
    this.agent = true
    this.propertypostbtnshow = true
  }
    //redirect to post property page
  postpropertyowner() {
    if (localStorage.getItem('role_name') === "owner") {
      this.hidelogin = false
      this.router.navigateByUrl('/propertypost')
    }
    this.agent = false
    this.propertypostbtnshow = true
  }
  //logout func
  //redirect to login page
  //remove values from local storage
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    localStorage.removeItem('role_id');
    localStorage.removeItem('role_name');
    localStorage.removeItem('category_id')
    localStorage.removeItem('locationid')
    localStorage.removeItem('location')
    localStorage.removeItem('subcategory')
    localStorage.removeItem('max_value')
    localStorage.removeItem('phone')
    this.username = localStorage.getItem('username')
    this.router.navigate(['/home'],{queryParams:{
      listplace:true,
      postadshow:true,
      profile:true
    }});
    this.showlogout = false
    this.loginbtn = true
    this.createacc = true
    this.listplace=true
    this.usernameprofile=false
    this.value=false
  }
  onlogin() {
    this.propertypostbtnshow = false
  }
  createpropertypost() {
$('#loginPopupForm0').modal('hide')
  }

///redirect to my profile page
  myprofile() {
    this.router.navigate(['/myprofile'],{
      queryParams:{
        profile:true,
        listplace:true,
        postadshow:true,
      }
    })
  }
  createpostfreead() {
    document.getElementById('loginPopupForm0').click()
  }
//signup popup form show
  createacconly() {
    $('#signupPopupForm00').modal('show')

  }
  //close login popup form
  close() {
    $('#loginPopupForm0').modal('hide')
    this.agentcompanyresetform()
  }
  //close loginpopup form and open forget popup form
  clickforget() {
    $('#loginPopupForm0').modal('hide')
    this.forgetresetform()
    this.otpshow = false
    this.forgetloader=false

  }
    //submit func for forget form
  forgetsubmit(form) {
    this.forgetloader=true
     //genrate otp
    //otp timer show
    if (this.otpshow == false) {
      this.service.postotp(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']


        if (status == 1) {

          this.otpshow = true
          this.timer=true
          this.timeLeft=180
          this.forgetloader=false
          this.showforeget=true
         this.timeLeftshow=true

        }
        else {
          alert(message)
          this.forgetloader=false
        }
      })
    }
      // submit func for forget password only
    else {
      this.service.postforget(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        this.pwdid=res['user_id']
        console.log(res['user_id'],"userid")
        if (status == 1) {
          console.log(res,"otp")
          ///close forget from popup
          $('#forget').modal('hide')
          this.timer=false
          $('#resetpassword').modal('show')
          this.otpshow=false
          this.forgetresetform()
          this.forgetloader=false
        }
        else {
          alert(message)
          this.forgetloader=false
        }
      })
    }
  }
 //submit func for reset form
  resetsubmit(form){
    this.resetloader=true
    form.value.user_id=this.pwdid
    this.service. newresetPassword(form.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {
      alert(message)
      this.resetloader=false
      $('#resetpassword').modal('hide')
      this.passwordresetform(form)
      }
      else{
        alert(message)
        this.resetloader=false
      }
    })
  }




  //submit func resent OTP form
  resentotpsubmit(form){
   this.resentloader=true
    this.service.postotp(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']


      if (status == 1) {

        this.otpshow = true
        this.timer=true
        this.timeLeft=180
        this.forgetloader=false
        this.showforeget=true
        this.resentotp=false
        this.resentloader=false
        this.timeLeftshow=true
      }
      else {
        alert(message)
        this.forgetloader=false
        this.resentloader=false
      }
    })



  }
  //forget password popup form close
  forgetpwdclose(){
    console.log("call otp cancel")
    this.resentotp=false
    this.timer=false
    this.timeLeft=0
    this.timeremaining=0
    this.timeLeftshow=false
    this.otpshow=false
    this.forgetresetform()
    $('#forget').modal('hide')
  }
  // when timer completed  at taht time this func trigger
  timerCompleted(e){
    console.log(e)
    this.timeLeft=null
    this.otpshow=false
    this.resentotp=true
    this.timeLeftshow=false
  }
    //in register form when role dropdown value changed at that time this func trigger
  rolechange(e){
    if(e==="AGEN"){
       this.agent=true
       this.company=false
       this.user=false
       this.single=null
    }
    else if(e==="COMP"){
      this.agent=false
      this.company=true
      this.user=false
    }
    else if(e==="USR"){
      this.agent=false
      this.company=false
      this.user=true
      this.single=null
    }
    else if(e==="OWN"){
      this.agent=false
      this.company=false
      this.user=false
      this.single=null
    }
    else{
      this.agent=false
      this.company=false
      this.user=false
      this.single=null
    }
  }
  //close sigin form and reset sigin form
  siginclose(){
    this.signinresetform()
  }
  //in register form when user dropdown value changed at that time this func trigger
  changeusercount(e){
    if(e == "1"){
      this.single=true
    }
    else{
      this.single=false
    }
  }
}
