import { Component, OnInit, ɵConsole, NgZone, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NewentryService } from './shared/newentry.service';
import { HttpClient } from '@angular/common/http';
import { CompleterService } from 'ng2-completer';
import { NgForm } from '@angular/forms';
import 'src/assets/js/main.js';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
 
})
export class AppComponent implements OnInit{
  title = 'property';
  user:boolean=true
  admin:boolean=false
  subcategoryListForRent:any[]
  subcategoryListForSale:any[]
  constructor(public service: NewentryService, private router: Router, private http: HttpClient,
    private zone: NgZone, private completerService: CompleterService, private route: ActivatedRoute) {}
ngOnInit(){
  this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+1).toPromise().then(res=>{
    console.log(res,"subc")
    this.service.subcategoryListForRent=res as any[]
   
  })
  this.http.get(this.service.rootURL+'/subcategories/getsubcategoriesbycategory/'+2).toPromise().then(res=>{
    console.log(res,"subc")
    this.service.subcategoryListForSale=res as any[]
   
  })
 
  if(localStorage.getItem('role_name')==='admin'){
   this.admin=true
   this.user=false
  }
  else{
    this.admin=false
   this.user=true
  }
}
}
