import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NewentryService } from '../shared/newentry.service';

@Component({
  selector: 'app-postadd',
  templateUrl: './postadd.component.html',
  styleUrls: ['./postadd.component.css']
})
export class PostaddComponent implements OnInit {
  textbold:boolean=false;
  boldshow:boolean=true;
  username:any
  signup:any
  signin:any
  constructor(public service: NewentryService,private router:Router) { }
  ngOnInit() {
    this.signin={
      email:null,
      password:null
     }
     this.resetform()
  }
  resetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.signup={
      user_name:null,
      email:null,
      password:null,
      confirmpassword:null,

      created_by:null
    }
  }
  ///submit func for register form
  onregistersubmit(registerform){
    this.service.postregister(registerform.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        alert("Register Successful")
      this.resetform(registerform)
      }
      else {

        alert(message)
      }


    })
  }
  ///submit func for sigin form
  onsigninsubmit(signin){
    this.service.postsignin(signin.value).subscribe((res:any)=>{
      console.log(res,"signindetails")
      var status = res['error_status'];
      var message = res['error_message'];

      if(status == 1){
        localStorage.setItem('token',res.token)
        localStorage.setItem('userid',res.user_id)
        localStorage.setItem('username', res.user_name);
        localStorage.setItem('role_name', res.role_name);
        localStorage.setItem('role_id',res.role_id)
        localStorage.setItem('user_email',res.user_email)
      this.username=localStorage.getItem('username')


      }
      else{
        alert('Incorrect username or password.');

      }
    })
  }
bold(){
this.textbold=true;
this.boldshow=false
}
boldnormal(){
  this.textbold=false;
  this.boldshow=true
}
//if user sigin then redirect to post advertistment page otherwise give alert msg for signin to continue process
postfreead(){
  if(this.username != null ){
    this.router.navigateByUrl('/postadd')
  }
  else{
    alert("Please signin !")
  }
}

}
