import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { SuperAdminComponent } from './super-admin.component';
import { MaterialModule } from '../material';
import { CurrencyComponent } from './masters/currency/currency.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LanguageComponent } from './masters/language/language.component';
import { CountryComponent } from './masters/country/country.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { StateComponent } from './masters/state/state.component';
import { CityComponent } from './masters/city/city.component';
import { AreaComponent } from './masters/area/area.component';
import { CompanyComponent } from './masters/company/company.component';
import { AgentComponent } from './masters/agent/agent.component';
import { GroupComponent } from './property/group/group.component';
import { CategoryComponent } from './property/category/category.component';
import { SubcategoryComponent } from './property/subcategory/subcategory.component';
import { UnitComponent } from './property/unit/unit.component';
import { PropertyComponent } from './property/property/property.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvertisementMasterComponent } from './property/advertisement-master/advertisement-master.component';
import { UserManagementComponent } from './user-management/user-management.component';


@NgModule({
  declarations: [SuperAdminComponent, 
    CurrencyComponent, 
    CountryComponent,
    LanguageComponent, StateComponent, CityComponent, AreaComponent,
     CompanyComponent , AgentComponent, GroupComponent, CategoryComponent,
     SubcategoryComponent, UnitComponent, PropertyComponent, AdvertisementMasterComponent, UserManagementComponent
  ],
  imports: [
    CommonModule,
    SuperAdminRoutingModule,
    FormsModule,
    Ng2SearchPipeModule,
    NgbModule,
    MultiselectDropdownModule,
    NgxPaginationModule,
    UiSwitchModule.forRoot({
      size: 'medium',
      color: '#287dfa',
      //switchColor: '#80FFA2',
      defaultBgColor: '#dc3545',
      //defaultBoColor : 'red',
      checkedLabel: 'On',
      uncheckedLabel: 'Off'
    }),
    MaterialModule
  ]
})
export class SuperAdminModule { }
