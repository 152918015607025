import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NewentryService } from 'src/app/shared/newentry.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  public loading: boolean;
  formdata: any;
  constructor( private service: NewentryService, private router: Router, private toastr: ToastrService, private http: HttpClient) { }

  ngOnInit() {
    this.formdata = {
      email: null,
      password: null
    };
    // if (localStorage.getItem('token') != null) {
    //   this.router.navigateByUrl('/admin_page/currency');
    // }
  }
 // login func
 // when click sigin btn at that time this func trigger
 // it give response and we store the response in local storage
  onSubmit(form: NgForm) {
    this.loading = true;

this.http.post(this.service.rootURL + '/users/login', form.value).subscribe((res: any) => {
  console.log(res, 'res');
  const status = res['error_status'];
  const message = res['error_message'];

  if (status === '1') {
    localStorage.setItem('token', res.token);
    localStorage.setItem('userid', res.user_id);
    localStorage.setItem('username', res.user_name);
    localStorage.setItem('role_name', res.role_name);
    localStorage.setItem('role_id', res.role_id);
    if (res.role_id === 'SADM') {
      this.router.navigateByUrl('/admin_page/currency');
    } else {
    this.toastr.error('Authentication Failed');
    this.loading = false;
  }

  } else {
    this.toastr.error('Incorrect username or password.', 'Authentication failed.');
    this.loading = false;
  }
});

   // if (form.value.remember_login == '') {
     // form.value.remember_login = true;
   // }
    /* this.service.login(form.value).subscribe(
      (res: any) => {
        console.log(res);
        var status = res['error_status'];
        var message = res['error_message'];

        if (status == 1) {

          localStorage.setItem('username', res.user_name);
          localStorage.setItem('userId', res.user_id);
          localStorage.setItem('role_id', res.role_id);
          localStorage.setItem('role_name', res.role_name);
          localStorage.setItem('token', res.token);

          this.loading = false;
          if (res.role_id == "SEC" || res.role_id == "LAW" || res.role_id == "DIR" ) {
            this.router.navigateByUrl('/file');
          } else if (res.role_id == 'SADM') {
            this.router.navigateByUrl('/masters');
          }
        }
        else {
          this.loading = false;
          this.toastr.error(message, 'Error Message');
        }
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Incorrect username or password.', 'Authentication failed.');
        else
          console.log(err);
      }
    ); */
  }
  public logoutclick(): void {

    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    localStorage.removeItem('role_id');
    localStorage.removeItem('role_name');

    this.router.navigateByUrl('/admin');
  }

}
