import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CompleterService } from 'ng2-completer';
import { HeaderComponent } from '../header/header.component';
import { PagesComponent } from '../pages/pages.component';
import { NewentryService } from '../shared/newentry.service';
 declare let $:any

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers:[HeaderComponent]
})
export class FooterComponent implements OnInit {
  @ViewChild('postpropertyPopupForm00' , {static:false}) modal:ElementRef
  @ViewChild('successmodal', { static: false }) modal1: ElementRef;
  @ViewChild('errormodal', { static: false }) modal2: ElementRef;
  @ViewChild('warningmodal', { static: false }) modal3: ElementRef;
  public errormessage;
  public successmessage;
  public warningmessage;
  showsubc:boolean=false
  str:any
  footerad:boolean
  category_id:any
  signup:any
  signin:any
  username:any
  agent:boolean=false
  single:boolean=null
  agentsignup:any
  hidelogin:boolean=true
  propertypostbtnshow:boolean=false
  showlogout:boolean=false
  List:any[]
  dataService
  loginbtn:boolean
  locationvalue
  registershow:boolean=true
  postfreeadbtnshow:boolean
  postpostfreeadbtnshow:boolean
  postad:boolean
  radioagcom=1
  forgetpassword
  otpshow:boolean=false
  forgetloader:boolean=false
  loginloader:boolean=false
  regloader:boolean=false
  resetloader:boolean=false
  reset
  showforeget:boolean=false
  pwdid
  subscribeTimer
  timeLeft:number=180
  interval
  timer:boolean=false
  resentotp:boolean=false
  company:boolean=false
  user:boolean=false
  constructor(public service: NewentryService,private router:Router,private home:HeaderComponent,
    private completerService: CompleterService,private renderer2:Renderer2) {
    this.service.getautocompletearea().toPromise().then(res => {
      this.List = res as any[]
     console.log(this.List)
      this.dataService = completerService.local(this.List, 'area_name', 'area_name');

    })
   }

  ngOnInit() {
    this.productresetForm()
    this.username=localStorage.getItem('username')
    this.resetform()
   this.agentcompanyresetform()
   // get company list
    this.service.getcompany()
    //get categories list
   this.service.gethomepagecategoriesview()
   this.signupresetform()
   this.forgetresetform()
   this.passwordresetform()
   this.signin={
    email:null,
    password:null
   }
   this.agentsignup={
    user_name:null,
email:null,
password:null,
confirmpassword:null,
role_name:null,
login_users:null,
user_count:null,
agent_company_id:null
  }
    this.service.getarea()
    if(localStorage.getItem('username')){
      this.showlogout=true
      this.loginbtn=false
    }
    else{
      this.showlogout=false
      this.loginbtn=true
    }

  }
  //reset func for signin form
  signinresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.signin={
      email:null,
      password:null

    }
  }
   //reset func for signup form
  signupresetform(form?:NgForm){
    if(form != null)
    form.resetForm()
    this.signup={
      user_name:null,
      email:null,
      password:null,
      confirmpassword:null,

    }
  }
   //reset func for product form
  productresetForm(form?:NgForm){
    if(form != null)
    form.resetForm()
      this.service.productdata={
        product_id: null,
        product_code: null,
        product_title: null,
        process_status_id:null,
        availablity_status:null,
        location_id:null,
        approval_flag:null,
        product_description: null,
        short_description: null,
        category_id: null,
        sub_category_id: null,
        subcategory_id:null,
        address_1:null,
        address_2: null,
        address_3: null,
        area_id: null,
        city_id: null,
        size_of_property: null,
        unit_id: null,
        price:null,
        company_id: null,
        phone: null,
        product_by: null,
        agent_id: null,
        nearby_location: null,
        facilities: null,
        created_by: null,
        created_date: null,
        updated_by:null,
        updated_date:null,
        flag:null,
        is_deleted:null,
        deleted_by:null,
        deleted_date:null,
        area_name: null,
        city_name: null,
        category_name: null,
        subcategory_name: null,
        unit_name:null,
        agent_name: null,
        company_name: null,
        bedroom_count: null,
        bathroom_count: null,
        property_facing:null,
        remarks:null,
        dwelling_type_id: null,
        pet_friendly:null,
        avilable_date: null,
      }

}
resetform(form?:NgForm){
  if(form != null)
  form.resetForm()
  this.signup={
    user_name:null,
    email:null,
    password:null,
    confirmpassword:null,

    created_by:null
  }
}
 //reset func for agent company form
agentcompanyresetform(form?:NgForm){
  if(form != null)
  form.resetForm()
  this.agentsignup={
    user_name:null,
email:null,
password:null,
confirmpassword:null,
role_name:null,
login_users:null,
user_count:null,
agent_company_id:null
  }
}
 //reset func for forget form
forgetresetform(form?:NgForm){
  if(form != null)
  form.resetForm()
  this.forgetpassword = {
    email: null,
    otp: null
  }
}
 //reset func for password reset form
passwordresetform(form?:NgForm){
  if(form != null)
  form.resetForm()
  this.reset = {
    confirmPassword: null,
    newPassword: null
  }
}

 ///register form submit fuc
  onregistersubmit(registerform){
   this.regloader=true
    registerform.value.role_name="user"
    console.log(registerform.value,"reg")
    this.service.postregister(registerform.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

        this.regloader=false
      this.resetform(registerform)

    document.getElementById("signupfooter").click()
      }
      else {

        this.regloader=false
      alert(message)

      }


    })
  }
  //sigin form submit func
  //after the successful login redirect to post property page
  onsigninsubmit(signin){
    this.loginloader=true
    this.service.postsignin(signin.value).subscribe((res:any)=>{
      console.log(res,"signindetails")
      var status = res['error_status'];
      var message = res['error_message'];

      if(status == 1){
        localStorage.setItem('token',res.token)
        localStorage.setItem('userid',res.user_id)
        localStorage.setItem('username', res.user_name);
        localStorage.setItem('role_name', res.role_name);
        localStorage.setItem('role_id',res.role_id)
        localStorage.setItem('user_email',res.user_email)
        localStorage.setItem('phone', res.phone_number)
      this.username=localStorage.getItem('username')

        this.showlogout=true
        this.home.showlogout=true
        console.log(this.home.showlogout,"showlogout")
        this.loginbtn=false
        this.home.loginbtn=false
        this.home.createacc=false
        this.showlogout=true
        this.loginbtn=false
        this.loginloader=false

       $('#loginPopupForm11').modal('hide')

        this.router.navigate(['/propertypost'],{
          queryParams:{
            postadshow:true,
            listplace:true,
            profile:false
          }
        })

        $('#loginPopupForm').modal('hide')
      }
      else{
     alert(message);
     this.loginloader=false

      }
    })
  }
  //redirect to post advertisement page
  postfreead(){
    if(this.username != null){
      this.registershow=false

      $('#loginPopupForm').modal('hide')
       this.router.navigate(['/postadvertisement'],{
         queryParams:{
           postadshow:false,
           listplace:true,
           profile:false
         }
       })
      this.postad=false
    }
    else{
      $('#loginPopupForm').modal('show')
      this.postfreeadbtnshow=true
    }
  }
   //agent company form submit func
  bothformsubmit(agentcompanyform){
    this.regloader=true
    ///assign role name
    if (this.agent == true) {
      agentcompanyform.value.role_name = "agent"
    }
    else if(this.company == true){
      agentcompanyform.value.role_name = "company"
    }
    else if(this.user == true){
      agentcompanyform.value.role_name = "User"
    }
    else {
      agentcompanyform.value.role_name = "owner"
    }
    if(this.single == true){
      agentcompanyform.value.login_users="single"
    }
    else if(this.single == false){
      agentcompanyform.value.login_users="multiple"
    }

    this.service.postagentcompany(agentcompanyform.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']
      if (status == 1) {

     if(this.agent ==true || this.company == true){
          alert("Our Client Representative contact will you soon !")
        }
        else{
          alert("Register Successfully !")
        }
      this.regloader=false
        this.agentcompanyresetform()
        document.getElementById("postpropertyPopupForm00").click();
        document.getElementById("loginPopupForm").click();
      }
      else {

        this.regloader=false
      alert(message)

      }

    })
  }
  //if user is login then redirectto thepost property page otherwise login popup form show
  postpropertyagent(){
    console.log(this.username,"username")
    if(localStorage.getItem('role_name') === "agent"){
    this.hidelogin=false
    $('#loginPopupForm').modal('hide')
    this.router.navigate(['/propertypost'],{
      queryParams:{
        postadshow:true,
        listplace:true,
        profile:false
      }
    })
    }
    else{

      this.radioagcom=1
      this.agent=true
      this.hidelogin=true
       this.propertypostbtnshow=true
       this.postfreeadbtnshow=false
       $('#loginPopupForm').modal('show')
    }


  }
   //if user is login then redirectto thepost property page otherwise login popup form show
  postfreeadfooter(){
    console.log(this.username,"username")
    if(localStorage.getItem('role_name') != null){
    this.hidelogin=false
    $('#loginPopupForm').modal('hide')
    this.router.navigate(['/propertypost'],{
      queryParams:{
        postadshow:false,
        listplace:true,
        profile:false
      }
    })
    }

    this.postfreeadbtnshow=true
    document.getElementById("loginPopupForm").click();
    this.propertypostbtnshow=false
  }
   //if user is login then redirectto thepost property page otherwise login popup form show
  postpropertyowner(){
    if(localStorage.getItem('role_name')==="company"){
      this.hidelogin=false
      $('#loginPopupForm').modal('hide')
      this.router.navigate(['/propertypost'],{
        queryParams:{
          postadshow:true,
          listplace:true,
          profile:false
        }
      })
    }
    else{
      this.radioagcom=0
      this.agent=false
       this.propertypostbtnshow=true
       this.postfreeadbtnshow=false
       $('#loginPopupForm').modal('show')
    }

  }

///login popup form hide
  createpropertypost(){
   $('#loginPopupForm').modal('hide')
  }


 ///login popup form hide
  close(){
    $('#loginPopupForm').modal('hide');
    this.agentcompanyresetform()
  }


  //forget popup form show
  //when forget btn click at that time this func trigger
  //hide login popup form
  clickforget() {
    $('#loginPopupForm').modal('hide')
    this.forgetresetform()
    this.otpshow = false
    this.forgetloader=false
  }
  //submit func for forget form
  forgetsubmit(form) {
    this.forgetloader=true
    //genrate otp
    //otp timer show
    if (this.otpshow == false) {

      this.service.postotp(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        if (status == 1) {
          this.otpshow = true
          this.timer=true
          this.timeLeft=180

          this.forgetloader=false
          this.showforeget=true
          this.otpshow = true
          this.forgetloader=false
          this.startTimer()

        }
        else {
          alert(message)
          this.forgetloader=false
        }
      })
    }
   // submit func for forget password only
    else {
      this.service.postforget(form.value).subscribe(res => {
        var status = res['error_status']
        var message = res['error_message']
        this.pwdid=res['user_id']
        if (status == 1) {
          $('#forget').modal('close')
          this.forgetloader=false
          this.forgetresetform()
          this.otpshow=false
          $('#resetpassword').modal('show')
        }
        else {
          alert(message)
          this.forgetloader=false
        }
      })
    }
  }
//submit func for password reset form
  resetsubmit(form){
    this.resetloader=true
    form.value.user_id=this.pwdid
    this.service. newresetPassword(form.value).subscribe(res=>{
      var status = res['error_status']
      var message = res['error_message']

      if (status == 1) {

      alert(message)
      this.resetloader=false
      $('#resetpassword').modal('hide')
      this.passwordresetform(form)
      }
      else{
        alert(message)
        this.resetloader=false
      }
    })
  }
  ///otp timer
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
       this.timeLeft = 0;
       this.timer=false
       this.otpshow=false
        this.resentotp=true
      }
    },1000)
  }
  startTimer2() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
       this.timeLeft = 0;
       this.timer=false
       this.otpshow=false
        this.resentotp=true
      }
    },1000)
  }
///resent OTP func
  resentotpsubmit(form){

    this.service.postotp(form.value).subscribe(res => {
      var status = res['error_status']
      var message = res['error_message']

      if (status == 1) {
        this.otpshow = true
        this.timer=true
        this.timeLeft=180
        this.forgetloader=false
        this.showforeget=true
        this.resentotp=false
        this.startTimer2()
      }
      else {
        alert(message)
        this.forgetloader=false
      }
    })



  }
//forget form popup close func
  forgetpwdclose(){
    console.log("call otp cancel")
    this.resentotp=false
    this.timer=false
    this.timeLeft=0
    $('#forget').modal('hide')
  }
  //in register form when role dropdown value changed at that time this func trigger
  rolechange(e){
    if(e==="AGEN"){
       this.agent=true
       this.company=false
       this.user=false
       this.single=null
    }
    else if(e==="COMP"){
      this.agent=false
      this.company=true
      this.user=false
    }
    else if(e==="USR"){
      this.agent=false
      this.company=false
      this.user=true
      this.single=null
    }
    else if(e==="OWN"){
      this.agent=false
      this.company=false
      this.user=false
      this.single=null
    }
    else{
      this.agent=false
      this.company=false
      this.user=false
      this.single=null
    }

  }
   //in register form when user dropdown value changed at that time this func trigger
  changeusercount(e){
    if(e == "1"){
      this.single=true
    }
    else{
      this.single=false
    }
  }
}
